import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ambiente-sistema-radio',
  templateUrl: './ambiente-sistema-radio.component.html',
  styleUrls: ['./ambiente-sistema-radio.component.css']
})
export class AmbienteSistemaRadioComponent implements OnInit {

  constructor() { }

  @Input() ambienteInput;
  @Output() ambienteOutput = new EventEmitter<string>();

  ngOnInit() {
    this.ambienteOutput.emit('T');
  }

  getClassAmbiente(amb) {
    let res = 'btn-';
    if (this.ambienteInput === amb || this.ambienteInput === 'T') {
      res += 'primary active';
    } else {
      res += 'default';
    }
    return res;
  }

  doClickAmbiente(amb) {
    if ( (this.ambienteInput === 'I' && amb === 'E') || (this.ambienteInput === 'E' && amb === 'I') ) {
      // se ambiente I ou E estiver marcado e clicou no I ou E que estava desmarcado
      this.ambienteInput = 'T';
      this.ambienteOutput.emit('T');
    } else if (this.ambienteInput === 'T') {
      if (amb === 'I') {
        // se ambos ambientes estiverem marcados e clicou no I para desmarcar
        this.ambienteInput = 'E';
        this.ambienteOutput.emit('E');
      } else {
        // se ambos ambientes estiverem marcados e clicou no E para desmarcar
        this.ambienteInput = 'I';
        this.ambienteOutput.emit('I');
      }
    }
  }
}
