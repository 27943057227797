import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { LogService } from './services/log/log.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { CentralCaixaService } from './services/central/central-caixa.service';
import { ApplicationService } from './services/application/application.service';
import { WebServiceService } from './services/web-service/web-service.service';
import { Config } from '../environments/config';
import { CurrentView } from '../environments/currentview';
import { NativeInterfaceService } from './services/native-interface/native-interface.service';
import { DOCUMENT } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  currentview = CurrentView;
  // project = Project;
  // theme = this.project.theme;
  platforms = '';
  ambienteLogado = !window.location.href.endsWith('pub');

  constructor(@Inject(DOCUMENT) private document: any, private log: LogService,  private analytics: AnalyticsService,
      private central: CentralCaixaService, private appService: ApplicationService, private webservice: WebServiceService,
      private nativeInterface: NativeInterfaceService) {
    //
  }

  ngOnInit() {
    // this.log.info('AppComponent.init()');
    // this.document.getElementById('theme').setAttribute('href', './assets/css/' + this.theme.value );
    this.document.getElementById('theme').setAttribute('href', './assets/css/theme-intranet.css');
  }
}
