import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Config } from '../../../../environments/config';
import { CurrentView } from '../../../../environments/currentview';
import { AlertService } from '../../../services/alert/alert.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../services/application/application.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { LogService } from '../../../services/log/log.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { FavoritoService } from './../../../services/favorito/favorito.service';
import { NativeInterfaceService } from './../../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-perfilpasta',
  templateUrl: './perfilpasta.component.html',
  styleUrls: ['./perfilpasta.component.css']
})
export class PerfilpastaComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService,
    private favoritoService: FavoritoService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl = 10;
  numberPagetbl = [];
  numberPagetblSelected = 0;
  perfilContent = [];
  perfilSelecionado = null;
  perfilSelecionadoID = 'null';
  perfilUsuario = null;
  diretorioSelecionado = '/sicnl';
  diretorioAnterior = '/sicnl';
  diretorios = [];
  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;
  p: number;

  ngOnInit() {
    const vm = this;
    this.log.info('PerfilPastaComponent.init()');
    this.analytics.trackEvent('controller', 'PerfilPastaController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      perfil: [null]
    });
    this.p = 1;
    this.idUsuario = this.appService.getIdUsuario();
    this.perfilUsuario = this.appService.getPerfilUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.acessoInclusao = this.appService.temAutorizacao('perfilpasta', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('perfilpasta', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('perfilpasta', 'E');
    this.listarPerfis();
    this.diretorios.push(this.diretorioSelecionado);
    this.favoritoService.initFavorite(this.caminho, this.idUsuario)
      .subscribe((res: any) => {
        vm.isFavorito = res.idFavorito != null;
        vm.idFavorito = res.idFavorito;
        vm.idItemFavorito = res.idItemFavorito;
      }, (err: HttpErrorResponse) => {
        console.log('Error response.');
        console.log(err);
      });
  }

  listarPerfis() {
    const vm = this;
    this.analytics.trackEvent('PerfilPastaController', 'listarPerfis()');

    function success(res) {
      vm.analytics.trackEvent('PerfilPastaController', 'listarPerfis():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.perfilContent = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.perfilContent.push({
            'sigla': e.sigla.trim(),
            'descricao': e.descricao.trim()
          });
        });
        vm.perfilContent.sort((a, b) => a.descricao.localeCompare(b.descricao) );
        if ( vm.perfilContent.length >= 1 ) {
          vm.myForm.get('perfil').patchValue( vm.perfilContent[0].sigla );
          setTimeout(function() { vm.trocarPerfil(); }, 250);
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilPastaController', 'listarPerfis():success');
      let message = 'Falha ao listar os registros.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    vm.webservice.read('ws/perfis', null, success, fail);
  }

  trocarPerfil() {
    const vm = this;
    this.analytics.trackEvent('PerfilPastaController', 'trocarPerfil()');
    vm.perfilSelecionadoID = this.appService.getSelectedIndexValue('perfil');
    vm.perfilSelecionado = this.appService.getSelectedIndexText('perfil');
    if ( vm.perfilSelecionadoID === 'null' ) {
      vm.limpar();
      return;
    }
    vm.buscarConteudoPasta();
  }

  selecionarPasta(pastaParam) {
    const vm = this;
    vm.diretorioAnterior = vm.diretorioSelecionado;
    vm.diretorioSelecionado = pastaParam.link;
    this.diretorios.push(pastaParam.link);
    this.buscarConteudoPasta();
  }

  voltarPasta() {
    const vm = this;
    if (vm.diretorios.length > 1) {
      this.diretorioSelecionado = vm.diretorios[vm.diretorios.length - 2];
      vm.diretorios.pop();
      this.buscarConteudoPasta();
    }
  }

  buscarConteudoPasta() {
    const vm = this;
    function success(res) {
      vm.analytics.trackEvent('PerfilPastaController', 'buscarConteudoPasta():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.p = 1;
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.tblData.push({
            'coPerfilUsuario': vm.perfilSelecionadoID,
            'idPasta': e.idPasta,
            'pasta': e.nomePasta !== null ? e.nomePasta : 'ERRO AO BUSCAR PASTA',
            'link': e.link,
            'consultar': ( e.id !== null ) ? 'checked' : '',
            'reservada': ( e.icPastaReservada !== null && e.icPastaReservada !== 'N') ? 'checked' : '',
            'incluir': ( e.icPerfilInclusao !== null && e.icPerfilInclusao !== 'N') ? 'checked' : '',
            'alterar': ( e.icPerfilAlteracao !== null && e.icPerfilAlteracao !== 'N') ? 'checked' : '',
            'excluir': ( e.icPerfilExclusao !== null && e.icPerfilExclusao !== 'N') ? 'checked' : '',
            'baixar': ( e.icPerfilBaixa !== null && e.icPerfilBaixa !== 'N') ? 'checked' : '',
            'renomear': ( e.icPerfilNovoNome !== null && e.icPerfilNovoNome !== 'N') ? 'checked' : '',
            'movimentar': ( e.icPerfilMovimentacao !== null && e.icPerfilMovimentacao !== 'N') ? 'checked' : '',
            'dirPai' : vm.diretorioAnterior
          });
        });
      }

      vm.numberPagetbl = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl));
      vm.numberPagetblSelected = 0;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilPastaController', 'trocarPerfil():success');
      let message = 'Falha ao listar as Pastas associadas ao Perfil selecionado.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }
    const params = new HttpParams().append('siglaPerfil', vm.perfilSelecionadoID).append('diretorio', vm.diretorioSelecionado);
    params.append('diretorio', vm.diretorioSelecionado);
    vm.webservice.read('ws/perfilpastas', params, success, fail);
  }

  manterAssociacao(mTipo: string, event: Event, row: any) {
    const vm = this;
    vm.analytics.trackEvent('PerfilPastaController', 'manterAssociacao(\'' + mTipo + '\')');
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    const mStatusConsulta = row.consultar === 'checked';
    const mIdPasta = row.idPasta;
    const mPastaNome = row.pasta;
    const mLink = row.link;
    const mValor = element.checked;
    let msg: any = '';
    if ( mTipo !== 'C' && !mStatusConsulta ) {
      msg = 'É preciso permitir ' + this.appService.getVerboCRUD('c', true) + ' o registro antes de habilitar a opção de ' + this.appService.getVerboCRUD(mTipo, true) + '.';
      vm.log.info(msg);
      vm.alertService.showMessage('Alerta', msg);
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PerfilPastaController', 'manterAssociacao(\'' + mTipo + '\'):success');
      if (res.temErro) {
        msg = 'Ocorreu um erro ao manter a associação da Perfil X Pasta - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.tblData.forEach((e, i) => {
          if (e.idPasta === row.idPasta) {
            const BtC = vm.appService.booleanToChecked(mValor);
            switch ( mTipo ) {
              case 'C' :
                if ( mValor ) {
                  vm.tblData[i].consultar = BtC;
                } else {
                  const BtCFalse = vm.appService.booleanToChecked(false);
                  vm.tblData[i].consultar = BtCFalse;
                  vm.tblData[i].incluir = BtCFalse;
                  vm.tblData[i].alterar = BtCFalse;
                  vm.tblData[i].excluir = BtCFalse;
                  vm.tblData[i].baixar = BtCFalse;
                  vm.tblData[i].renomear = BtCFalse;
                  vm.tblData[i].movimentar = BtCFalse;
                }
                break;
              case 'I' : vm.tblData[i].incluir = BtC; break;
              case 'A' : vm.tblData[i].alterar = BtC; break;
              case 'E' : vm.tblData[i].excluir = BtC; break;
              case 'B' : vm.tblData[i].baixar = BtC; break;
              case 'R' : vm.tblData[i].renomear = BtC; break;
              case 'M' : vm.tblData[i].movimentar = BtC; break;
            }
          }
        });
        msg = 'A ' +  (!mValor ? 'des' : '') + 'associação da opção "' + vm.appService.getVerboCRUD(mTipo, true) + '" da pasta "' + row.pasta + '" com o perfil "' + vm.perfilSelecionado + '" foi realizada com sucesso.';
        vm.log.info(msg);
        vm.alertService.showMessage('Sucesso', msg);
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilPastaController', 'manterAssociacao(\'' + mTipo + '\'):fail');
      let message = 'Falha ao manter a associação da Perfil X Pasta';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
    }

    const params = {
        'idPerfilUsuario': vm.perfilSelecionadoID,
        'perfilUsuarioNome': vm.perfilSelecionado,
        'idPasta': mIdPasta,
        'pastaNome': mPastaNome,
        'link' : mLink,
        'tipo': mTipo,
        'valor': mValor};

    vm.webservice.create('ws/perfilpastas', JSON.stringify( params ), success, fail);
  }

  limpar() {
    const vm = this;
    vm.myForm.patchValue({
      perfil: null
    });
    vm.tblData = [];
    vm.p = 1;
  }

  getPlatforms(platforms) {
    return '';
  }

  datatblReset() {
    this.numberPagetblSelected = 0;
    this.numberPagetbl = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl));
  }

  doFavorite() {
    const vm = this;
    const params = { 'idFavorito': vm.idFavorito, 'caminho': vm.caminho, 'idUsuario': vm.idUsuario };
    if (params !== null) {
      if (params.idFavorito == null && params.caminho !== null && params.idUsuario !== null) {
        vm.webservice.create('ws/favoritos', {'idItemFavorito': params.caminho, 'idUsuario': params.idUsuario}, success, fail);
      } else {
        vm.webservice.delete('ws/favoritos/' + params.idFavorito + '/F', null, success, fail);
      }
    }

    function success(res) {
      const op = res.operacao;
      vm.isFavorito = op === 'I';
      vm.idFavorito = res.idFavorito;
    }

    function fail(xhr, status, erro) {
      let message = 'Falha ao atualizar Perfil x Pasta como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (erro) {
          message = erro;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, erro);
      if (xhr.status === 502 || erro === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }
}
