import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatSortModule, MatTableModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TreeDraggedElement, TreeModule } from 'angular-tree-component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TextMaskModule } from 'angular2-text-mask';
import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgxPaginationModule } from 'ngx-pagination';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AlterarSenhaComponent } from './components/alterarsenha/alterarsenha.component';
import { AlterarSenhaDesafioComponent } from './components/alterarsenhadesafio/alterarsenhadesafio.component';
import { ArquivosComponent } from './components/arquivos/arquivos.component';
import { CadastrarArquivoComponent } from './components/arquivos/cadastrararquivo.component';
import { AuditoriaComponent } from './components/auditoria/auditoria.component';
import { AuthGuard } from './components/auth/auth.guard';
import { FaleconoscoComponent } from './components/faleconosco/faleconosco.component';
import { ArrecadacaoContribuicaoSocialComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaocontribuicaosocial/arrecadacaocontribuicaosocial.component';
import { ArrecadacaoLiquidaAnualComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaoliquidaanual/arrecadacaoliquidaanual.component';
import { ArrecadacaoLiquidaMensalComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaoliquidamensal/arrecadacaoliquidamensal.component';
import { ArrecadacaoPrincipaisSetoresComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaoprincipaissetores/arrecadacaoprincipaissetores.component';
import { QtdeContasComRecolhimentoComponent } from './components/informacoesconsolidadas/arrecadar/qtdecontascomrecolhimento/qtdecontascomrecolhimento.component';
import { RecolhimentoEmpregadoDomesticoComponent } from './components/informacoesconsolidadas/arrecadar/recolhimentoempregadodomestico/recolhimentoempregadodomestico.component';
import { CadastroContasVinculadasComponent } from './components/informacoesconsolidadas/cadastro/cadastrocontasvinculadas/cadastrocontasvinculadas.component';
import { DistribuicaoSaldosContasAtivasComponent } from './components/informacoesconsolidadas/cadastro/distribuicaosaldoscontasativas/distribuicaosaldoscontasativas.component';
import { DistribuicaoSaldosContasInativasComponent } from './components/informacoesconsolidadas/cadastro/distribuicaosaldoscontasinativas/distribuicaosaldoscontasinativas.component';
import { SaquesConectividadeSocialComponent } from './components/informacoesconsolidadas/pagar/saquesconectividadesocial/saquesconectividadesocial.component';
import { SaquesMensaisComponent } from './components/informacoesconsolidadas/pagar/saquesmensais/saquesmensais.component';
import { SaquesPorDuracaoVinculoComponent } from './components/informacoesconsolidadas/pagar/saquesporduracaovinculo/saquesporduracaovinculo.component';
import { SaquesPorModalidadeComponent } from './components/informacoesconsolidadas/pagar/saquespormodalidade/saquespormodalidade.component';
import { RecuperacaoCreditoComponent } from './components/informacoesconsolidadas/recuperarcredito/recuperacaocredito/recuperacaocredito.component';
import { InstitucionalAtribuicoesComponent } from './components/institucional/atribuicoes/institucional-atribuicoes.component';
import { InstitucionalEstruturaComponent } from './components/institucional/estrutura/institucional-estrutura.component';
import { InstitucionalHistoricoComponent } from './components/institucional/historico/institucional-historico.component';
import { InstitucionalIndicadorEstrategicoComponent } from './components/institucional/indicadorestrategico/institucional-indicadorestrategico.component';
import { MensagemGestorComponent } from './components/mensagemGestor/mensagemGestor.component';
import { MensagemGestorCadastroComponent } from './components/mensagemGestor/mensagemGestorCadastro.component';
import { PalavraChaveComponent } from './components/palavrachave/palavrachave.component';
import { PalavraChaveCadastroComponent } from './components/palavrachave/palavrachavecadastro.component';
import { PalavraChaveFuncionalidadeComponent } from './components/palavrachave/palavrachavefuncionalidade.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { ArrecadacaoConsolidadaComponent } from './components/relatorios/arrecadacaoconsolidada/arrecadacaoconsolidada.component';
import { ArrecadacaoLiquidaComponent } from './components/relatorios/arrecadacaoliquida/arrecadacaoliquida.component';
import { AtivoFgtsComponent } from './components/relatorios/ativofgts/ativofgts.component';
import { ContratacoesComponent } from './components/relatorios/contratacoes/contratacoes.component';
import { DescontosComponent } from './components/relatorios/descontos/descontos.component';
import { DesembolsoComponent } from './components/relatorios/desembolso/desembolso.component';
import { ExecucaoorcamentariaComponent } from './components/relatorios/execucaoorcamentaria/execucaoorcamentaria.component';
import { PassivoFgtsComponent } from './components/relatorios/passivofgts/passivofgts.component';
import { SaquesConsolidadosComponent } from './components/relatorios/saquesconsolidados/saquesconsolidados.component';
import { TutorialComponent } from './components/relatorios/tutorial/tutorial.component';
import { SplashComponent } from './components/splash/splash.component';
import { TelefonePipe } from './components/TelefonePipe';
import { CadastrarusuarioComponent } from './components/usuario/cadastrarusuario.component';
import { PesquisarusuarioComponent } from './components/usuario/pesquisarusuario.component';
import { InitializeDirective } from './directives/initialize/initialize.directive';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { MyInterceptor } from './myinterceptor';
import { PipeModule } from './pipe.module';
import { LabelErrorComponent } from './resources/label-error/label-error.component';
import { ModalAlertTextoComponent } from './resources/modal-alert-texto/modal-alert-texto.component';
import { ModalAlertComponent } from './resources/modal-alert/modal-alert.component';
import { ModalConfirmComponent } from './resources/modal-confirm/modal-confirm.component';
import { ModalContainerComponent } from './resources/modal-container/modal-container.component';
import { ModalErrorComponent } from './resources/modal-error/modal-error.component';
import { ModalInputMoveComponent } from './resources/modal-input-move/modal-input-move.component';
import { ModalInputComponent } from './resources/modal-input/modal-input.component';
import { ModalWarningComponent } from './resources/modal-warning/modal-warning.component';
import { getSaver, SAVER } from './saver.provider';
import { AlertService } from './services/alert/alert.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { ApplicationService } from './services/application/application.service';
import { CentralCaixaService } from './services/central/central-caixa.service';
import { EndpointService } from './services/endpoint/endpoint.service';
import { GeneralErrorHandler } from './services/error/error.handler';
import { ErrorService } from './services/error/error.service';
import { FavoritoService } from './services/favorito/favorito.service';
import { FeedbackService } from './services/feedback/feedback.service';
import { GeolocationService } from './services/geolocation/geolocation.service';
import { LoaderService } from './services/loader/loader.service';
import { LogService } from './services/log/log.service';
import { NativeInterfaceService } from './services/native-interface/native-interface.service';
import { UtilsService } from './services/utils/utils.service';
import { ValidadorCaixaService } from './services/validador/validador-caixa.service';
import { WebResourceService } from './services/web-resource/web-resource.service';
import { WebServiceService } from './services/web-service/web-service.service';
import { SharedModule } from './shared.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent, SplashComponent,
    //
    AlterarSenhaDesafioComponent, AlterarSenhaComponent, PrincipalComponent,
    PesquisarusuarioComponent, CadastrarusuarioComponent, AuditoriaComponent,
    //
    ModalAlertComponent, ModalConfirmComponent, ModalInputComponent, ModalInputMoveComponent,
    ModalWarningComponent, ModalErrorComponent, ModalContainerComponent,
    LabelErrorComponent, InitializeDirective, TelefonePipe,
    //
    InstitucionalHistoricoComponent, InstitucionalIndicadorEstrategicoComponent, InstitucionalEstruturaComponent, InstitucionalAtribuicoesComponent,
    TutorialComponent, AtivoFgtsComponent, PassivoFgtsComponent, FaleconoscoComponent,
    //
    ArquivosComponent, CadastrarArquivoComponent,
    //
    ContratacoesComponent, DescontosComponent, DesembolsoComponent, ExecucaoorcamentariaComponent,
    ArrecadacaoLiquidaComponent, ArrecadacaoConsolidadaComponent, SaquesConsolidadosComponent,
    //
    ArrecadacaoContribuicaoSocialComponent, ArrecadacaoLiquidaAnualComponent, ArrecadacaoLiquidaMensalComponent,
    ArrecadacaoPrincipaisSetoresComponent, QtdeContasComRecolhimentoComponent, RecolhimentoEmpregadoDomesticoComponent,
    CadastroContasVinculadasComponent, DistribuicaoSaldosContasAtivasComponent, DistribuicaoSaldosContasInativasComponent,
    SaquesConectividadeSocialComponent, SaquesMensaisComponent, SaquesPorDuracaoVinculoComponent,
    SaquesPorModalidadeComponent, RecuperacaoCreditoComponent,
    //
    MensagemGestorComponent, MensagemGestorCadastroComponent,
    PalavraChaveComponent, PalavraChaveCadastroComponent, PalavraChaveFuncionalidadeComponent, ModalAlertTextoComponent
  ],
  imports: [
    BrowserAnimationsModule, NgbModule, routing, PipeModule.forRoot(), SharedModule,
    FormsModule, HttpClientModule, ReactiveFormsModule, InputMaskModule,
    TreeModule, CalendarModule, TextMaskModule,
    BrowserModule, ChartsModule, FormsModule.withConfig({ warnOnDeprecatedNgFormSelector: 'never' }),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MalihuScrollbarModule.forRoot(),
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),
    AngularMultiSelectModule, NgxPaginationModule, PdfViewerModule,
    MatInputModule, MatPaginatorModule, MatTableModule, MatSortModule, MatDatepickerModule,
    MatNativeDateModule, MatMomentDateModule, MatProgressSpinnerModule, MatButtonModule, MatProgressBarModule, MatCardModule
  ],

  entryComponents: [ModalAlertComponent, ModalConfirmComponent, ModalInputComponent, ModalInputMoveComponent, ModalWarningComponent,
                    ModalErrorComponent, LabelErrorComponent, ModalContainerComponent, ModalAlertTextoComponent],

  providers: [AlertService, AnalyticsService, ApplicationService, EndpointService, ErrorService, FeedbackService,
    GeolocationService, LogService, NativeInterfaceService, UtilsService, WebResourceService, WebServiceService,
    ValidadorCaixaService, CentralCaixaService, AuthGuard, { provide: ErrorHandler, useClass: GeneralErrorHandler },
    {provide: SAVER, useFactory: getSaver},
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    TreeDraggedElement, FavoritoService
  ],

  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
