import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../../services/log/log.service';
import { Config } from '../../../../../environments/config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../../environments/currentview';
import { ApplicationService } from '../../../../services/application/application.service';
import { WebServiceService } from '../../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from '../../../../services/native-interface/native-interface.service';
import { UtilsService } from '../../../../services/utils/utils.service';
import * as numeral from 'numeral';
import { MatSort, Sort } from '@angular/material';

@Component({
  selector: 'app-arrecadacaoprincipaissetores',
  templateUrl: './arrecadacaoprincipaissetores.component.html',
  styleUrls: ['./arrecadacaoprincipaissetores.component.css']
})
export class ArrecadacaoPrincipaisSetoresComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService, private utilservice: UtilsService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tbl = ['Tipo', 'Nome', 'Número', 'Data', 'Assunto', 'Observação'];
  tblData = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  periodos = [];
  periodoInic = null;
  periodoFim = null;
  periodoImp = null;
  cab = null;
  dataAtual = new Date();
  exibirTabela = false;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';

  filtrotbl = null;
  chartData = [];
  chartAxis1 = [];
  chartAxis2 = [];
  chartAxis3 = [];
  chartAxis4 = [];
  chartAxis5 = [];
  chartLabels = [];
  chartType = 'bar';
  chartOptions = {
    responsive: true,
    scaleShowVerticalLines: true,
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
        }
      }
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Período / Setor'
        },
        ticks: {
          fontFamily: 'Calibri,Candara,Segoe,\'Segoe UI\',Optima,Arial,sans-serif',
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }],
      yAxes: [{
          id: 'yAxis1',
          position: 'left',
          display: true,
          beginAtZero: true,
          scaleLabel: {
            display: true,
            labelString: 'Valor',
          },
          ticks: {
            callback: function (value, index, values) {
              return 'R$ ' + numeral(value).format('0,0');
            }
          },
          gridLines: {
            color: 'rgba(51,177,174,0.3)',
          }
        },
        {
          id: 'yAxis2',
          position: 'right',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Percentual',
          },
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return numeral(value).format('0,0');
            }
          },
          gridLines: {
            color: 'rgba(255,255,255,0)',
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  ordemPeriodoResultado = 'asc';
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  p: number;

  ngOnInit() {
    this.log.info('ArrecadacaoPrincipaisSetoresComponent.init()');
    this.analytics.trackEvent('controller', 'ArrecadacaoPrincipaisSetoresController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      ordemPeriodoResultado: 'D'
    });
    this.p = 1;
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.popularCampos();
    this.ordemPeriodoResultado = 'asc';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito ArrecadacaoPrincipaisSetoresController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
        // vm.alertService.showMessage('Atenção', msg)
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  exibir(obj) {
    if (obj !== this.periodoImp) {
      this.periodoImp = obj;
      return true;
    }
  }

  exibetot(obj) {
    return (obj === 'TOTAL');
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'listar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('ordemPeriodoResultado', vm.myForm.get('ordemPeriodoResultado').value);

    this.webservice.read('ws/informacoesconsolidadas/arrecadacaoprincipaissetores', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        let count = 0;
        vm.chartLabels = [];
        vm.chartAxis1 = [];
        vm.chartAxis2 = [];
        vm.chartAxis3 = [];
        vm.chartAxis4 = [];
        vm.chartAxis5 = [];
        res.data.forEach((element) => {
          vm.tblData.push({
            'periodo': element.periodo,
            'listArrecadPrincipaisSetores': element.listArrecadPrincipaisSetores
          });
          if (++count <= 6) {
            element.listArrecadPrincipaisSetores.forEach((el) => {
              if (el.setor !== 'TOTAL') {
                const periodoSetor = element.periodo.substring(4, 6) + '/' + element.periodo.substring(0, 4)
                        + '-' + el.setor.split(' ')[0].split(',')[0].split('-')[0];
                vm.chartLabels.push(periodoSetor);
                vm.chartAxis1.push(el.valorArrecadacaoBruta / 1000);
                vm.chartAxis2.push(el.valorArrecadacaoLiquida / 1000);
                vm.chartAxis3.push(el.percentualArrecadacao);
              }
            });
          }
        });
      }
      vm.dataAtual = new Date();
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.exibirTabela = true;

      vm.chartData = [
        { data: vm.chartAxis1, yAxisID: 'yAxis1', type: 'line', fill: false, label: 'Arrecadação' },
        { data: vm.chartAxis2, yAxisID: 'yAxis1', type: 'line', fill: false, label: 'Arrecadação Líquida' },
        { data: vm.chartAxis3, yAxisID: 'yAxis2', label: '% Arrecadação' }
      ];
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'listar():error');

      let message = 'Falha ao listar ArrecadacaoPrincipaisSetoresController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      periodoInicial: '',
      periodoFinal: '',
      ordemPeriodoResultado: 'D'
    });
    this.periodoImp = null;
    this.popularCampos();
    this.tblData = [];
    this.exibirTabela = false;
    this.ordemPeriodoResultado = 'asc';
    this.p = 1;
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'popularCampos()');

    this.webservice.read('ws/informacoesconsolidadas/arrecadacaoprincipaissetores/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((dataPer) => {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
          });
        });
      }
      const periodoInic = res.data[0].minPeriodo;
      const periodoFim = res.data[0].maxPeriodo;
      vm.myForm.get('periodoInicial').setValue(periodoInic);
      vm.myForm.get('periodoFinal').setValue(periodoFim);
      vm.listar();
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'popularCampos():error');
      let message = 'Falha ao popular campos ArrecadacaoPrincipaisSetoresController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  public imprimir(tipo)  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'imprimir()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    const canvas = document.getElementById('grafico') as HTMLCanvasElement;
    const base64Canvas = canvas.toDataURL();
    const body = {
      'periodoInicial': vm.myForm.get('periodoInicial').value,
      'periodoFinal': vm.myForm.get('periodoFinal').value,
      'grafico': base64Canvas,
      'ordem': vm.myForm.get('ordemPeriodoResultado').value
    };

    vm.webservice.create('ws/informacoesconsolidadas/imprimirarrecadacaoprincipaissetores?tipo=' + tipo, body, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'imprimir():success');
      let filename = 'ArrecadacaoPrincipaisSetores';
      let contenttype = 'application/';
      if (tipo === Config.REPORT_PDF) {
        filename += '.pdf';
        contenttype += 'pdf';
      } else {
        filename += '.xls';
        contenttype += 'xls';
      }
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contenttype});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'imprimir():error');
      let message = 'Falha ao imprimir ArrecadacaoPrincipaisSetoresController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  public exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoPrincipaisSetoresController', 'imprimir()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    const body = {
      'periodoInicial': vm.myForm.get('periodoInicial').value,
      'periodoFinal': vm.myForm.get('periodoFinal').value,
      'ordem': vm.myForm.get('ordemPeriodoResultado').value
    };

    vm.webservice.postDownload('ws/informacoesconsolidadas/exportararrecadacaoprincipaissetorescsv', body, 'text/plain', true, 'arrecadacaoprincipaissetores.csv', null);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
