import { Observable } from 'rxjs';
import { Component, OnInit, Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { Config } from '../../../environments/config';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { ApplicationService } from '../../services/application/application.service';
import { NativeInterfaceService } from '../../services/native-interface/native-interface.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CurrentView } from '../../../environments/currentview';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  currentview = CurrentView;

  constructor(private log: LogService,  private webservice: WebServiceService, private appService: ApplicationService,
    private nativeInterface: NativeInterfaceService, private formBuilder: FormBuilder) {
    this.currentview.menu = false;
    this.currentview.searchbox = false;
  }

  myForm: FormGroup;

  session = {
    authCode: '',
    result: null
  };

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      usuario: [ null , [ Validators.required ]],
      senha: [ null , [ Validators.required ]]
    });
    this.nativeInterface.clearLocalStorage();
    this.currentview.menu = false;
    this.currentview.searchbox = false;
  }

  getPlatforms(platforms) {
    return '';
  }

  doLogin($event) {
    const vm = this;
    vm.session.result = null;
    if (!this.myForm.get('usuario').value) {
      vm.session.result = 'Aplicação restrita. Informe o CPF do usuário.';
      return;
    }
    vm.nativeInterface.clearLocalStorage();
    vm.webservice.create('ws/auth/usuarios', vm.myForm.value, success, fail);

    function success(res) {
      vm.appService.openPage('/principal');
      if (res) {
        vm.nativeInterface.setPreference(Config.storageKeys.token, res.tokenAcesso);
        vm.nativeInterface.setPreference(Config.storageKeys.tokenAccess, res.perfilFuncionalidades);
        vm.nativeInterface.setPreference(Config.storageKeys.tokenAccessPastas, res.perfilPastas);
      }
      vm.currentview.menu = true;
      vm.currentview.searchbox = true;
    }

    function fail(xhr, status, err) {
      if (status === 400) {
        console.log(xhr);
        vm.session.result = xhr;
        return;
      }
      let message = 'Falha na autenticação.';
      if (err) {
        message = err;
      }
      vm.log.error(message);
      vm.session.result = message;
    }
  }

  isAuthenticated(): Observable<boolean> {
    const vm = this;
    const token = vm.nativeInterface.getPreference(Config.storageKeys.token, null);
    return new Observable<boolean> (observer => {
      if (!token) {
        observer.next(false);
        return;
      }
      const hash = window.location.hash;
      const isNext = this.checkUser(this.appService.getPerfilUsuario(), hash);
      observer.next(isNext);
      if (isNext) {
        observer.complete();
      }
    });
  }

  checkUser(perfil, hash) {
    let res = false;
    if (perfil && perfil !== null && hash && hash !== null) {
      const link = hash.replace('#/', '').split(';')[0];
      res = link === 'login';
      if (!res) {
        res = this.appService.temAutorizacao(link);
      }
      if (link === 'cadastrarUsuario' || link === 'perfilcadastro' || link === 'mensagemgestorcadastro'
          || link === 'pesquisarObservacaoFuncionalidade/cadastrarObservacaoFuncionalidade'
          || link === 'palavrachavecadastro' || link === 'cadastrarArquivo') {
        res = true;
      }
    }
    return res;
  }
}
