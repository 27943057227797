import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PublicoRoutingModule } from './publico-routing.module';
import { SaquesConsolidadosPubComponent } from './components/publico/saquesconsolidadospub/saquesconsolidadospub.component';
import { DescontosPubComponent } from './components/publico/descontospub/descontospub.component';
import { ExecucaoorcamentariaPubComponent } from './components/publico/execucaoorcamentariapub/execucaoorcamentariapub.component';
import { ProponentePubComponent } from './components/publico/proponentepub/proponentepub.component';
import { ContratacoesPubComponent } from './components/publico/contratacoespub/contratacoespub.component';
import { ArrecadacaoConsolidadaPubComponent } from './components/publico/arrecadacaoconsolidadapub/arrecadacaoconsolidadapub.component';
import { LegislacaoPubComponent } from './components/publico/legislacaopub/legislacaopub.component';
import { InteligenciaPubComponent } from './components/publico/inteligenciapub/inteligenciapub.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { ComumModule } from './comum.module';
import { PipeModule } from './pipe.module';
import { MatDatepickerModule, MatInputModule, MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  imports: [
    CommonModule, RouterModule, FormsModule, TranslateModule, ReactiveFormsModule, PublicoRoutingModule,
    ComumModule, AngularMultiSelectModule, NgxPaginationModule, PipeModule, InputMaskModule,
    // Material Modules:
    MatInputModule, MatPaginatorModule, MatTableModule, MatSortModule, MatDatepickerModule
  ],
  declarations: [
    ArrecadacaoConsolidadaPubComponent, ContratacoesPubComponent, DescontosPubComponent,
    ExecucaoorcamentariaPubComponent, InteligenciaPubComponent, LegislacaoPubComponent, ProponentePubComponent,
    SaquesConsolidadosPubComponent
  ]
})
export class PublicoModule { }
