import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuPrincipalComponent } from './components/menu/menu-principal/menu-principal.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { ComumModule } from './comum.module';
import { SiteFooterComponent } from './_layout/site-footer/site-footer.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';

@NgModule({
  imports: [
    CommonModule, RouterModule, FormsModule, TranslateModule, ReactiveFormsModule,
    ComumModule, MatProgressSpinnerModule
  ],
  declarations: [
    SiteLayoutComponent, SiteHeaderComponent, SiteFooterComponent, MenuPrincipalComponent
  ],
  exports: [
    LoaderComponent, MenuPrincipalComponent
  ]
})

export class SharedModule { }
