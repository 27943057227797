import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { TranslateModule } from '@ngx-translate/core';
import { TrilhacapacitacaoRoutingModule } from './trilhacapacitacao-routing.module';
import { PesquisaTrilhaCapacitacaoComponent } from './pesquisa/pesquisatrilhacapacitacao.component';
import { ConsultaVideoComponent } from './consulta/consultavideo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PesquisaTrilhaCapacitacaoComponent, ConsultaVideoComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, TrilhacapacitacaoRoutingModule,
    VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule
  ]
})
export class TrilhacapacitacaoModule { }
