import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';

declare const $: any;

@Component({
  selector: 'app-termoconfidencialidade',
  templateUrl: './termoconfidencialidade.component.html',
  styleUrls: ['./termoconfidencialidade.component.css']
})
export class TermoConfidencialidadeComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService,
      private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
      private alertService: AlertService, private nativeInterface: NativeInterfaceService) { }

  usuario = null;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  currentview = CurrentView;
  hasChanged = false;

  palavraChave = null;
  palavraChaveContent = [];
  hasCentral = Config.CENTRAL_ID !== '[null]';

  timer = null;
  idTermo = null;
  cboPalavraChave = '';
  txtValidadeTermo = 0;
  txtValidadeTermoOld = 0;
  txTermoConfidencialidade = '';
  txTermoConfidencialidadeOld = '';

  // -------------------------------------------------------------------------------------------
  // Para evitar o erro: ERROR in node_modules/ng2-ckeditor/ckeditor.component.d.ts(36,9):
  //                     error TS1086: An accessor cannot be declared in an ambient context.
  // Incluir atributo em tsconfig.json: compilerOptions.skipLibCheck: true
  // -------------------------------------------------------------------------------------------
  public config = CKEDITOR.config;
  public editor = CKEDITOR.editor;
  @ViewChild('ckeTermoConfidencialidade', {static: false}) ckeditor: any;

  ngOnInit() {
    const vm = this;
    vm.log.info('TermoConfidencialidadeComponent.init()');
    vm.analytics.trackEvent('controller', 'TermoConfidencialidadeController');

    vm.currentview.locked = false;
    vm.currentview.menu = true;
    vm.idUsuario = this.appService.getIdUsuario();
    vm.caminho = vm.router.url.replace('/', '');
    vm.initFavorite();
    vm.popularCampos();
    vm.config = {
      language: 'pt-br',
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbar: [
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
        { name: 'others', items: [ '-' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source' ] },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
        '/',
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt', '-', 'Blockquote', 'CreateDiv',  ] },
      ]
    };

    $(document).ready(function() {
      // $.getScript('../../../assets/js/ckeditor/ckeditor.js', function() { console.log('Carregou script Ckeditor'); })
      $( '#validadeTermo' ).change(function() {
          const max = Number($(this).attr('max'));
          const min = Number($(this).attr('min'));
          if ($(this).val() > max) {
              $(this).val(max);
          } else if ($(this).val() < min) {
              $(this).val(min);
          }
      });
    });

  }

  initFavorite() {
    const vm = this;
    vm.analytics.trackEvent('TermoConfidencialidadeController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito TermoConfidencialidadeController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': vm.caminho, 'idUsuario': vm.idUsuario};
    vm.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': vm.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'favoritos():error');
      let message = 'Falha ao atualizar o Termo de Confidencialidade como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  onChange($event: any): void {
    //
  }

  onPaste($event: any): void {
    //
  }

  limpar() {
    const vm = this;
    vm.idTermo = null;
    vm.txTermoConfidencialidade = '';
    vm.cboPalavraChave = '';
    vm.txtValidadeTermo = 0;
    $('#btnRevogar').attr('disabled', true);
  }

  isAlterado() {
    const vm = this;
    return ((vm.txtValidadeTermo !== vm.txtValidadeTermoOld) || (vm.txTermoConfidencialidade !== vm.txTermoConfidencialidadeOld));
  }

  salvar() {
    const vm = this;
    vm.analytics.trackEvent('TermoConfidencialidadeController', 'salvar()');

    if (!vm.isAlterado()) {
      vm.alertService.showMessage('Atenção', 'Nenhuma alteração identificada.');
      return;
    }

    if (vm.txtValidadeTermo < 1) {
      vm.alertService.showMessage('Atenção', 'Favor informar o prazo de validade da assinatura.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'salvar():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'O Termo de Confidencialidade foi salvo com sucesso.');
        vm.limpar();
        vm.popularCampos();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'fail()');
      let message = 'Falha ao salvar o Termo de Confidencialidade.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }

    function confirm() {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'salvar:confirm()');
      vm.log.info('Inclusão de novo termo confirmada. Executando inclusão no backend.');

      const params = {
        'idUsuario': vm.idUsuario,
        'idTermo': vm.idTermo,
        'validade': vm.txtValidadeTermo,
        'conteudoHTML':  vm.txTermoConfidencialidade
      };
      vm.webservice.post('ws/termoConfidencialidade/salvar', JSON.stringify(params), success, fail);
    }

    function deny() {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'salvar:deny()');
      vm.log.info('inclusão cancelada pelo usuário.');
    }

    vm.alertService.showConfirm('Atenção', 'Ao salvar este Termo de Confidencialidade será gerada nova versão e todos os usuários do sistema deverão assinar de forma digital novamente. Essa ação não poderá ser revertida, confirma essa ação?', confirm, deny);

  }

  revogar() {
    const vm = this;
    vm.analytics.trackEvent('TermoConfidencialidadeController', 'revogar()');

    if ((vm.idTermo === null) || (vm.idTermo === '') ) {
      vm.alertService.showMessage('Atenção', 'O identificador do Termo de Confidencialidade atual não foi localizado.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'revogar():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'O Termo de Confidencialidade foi revogado com sucesso. Não há nenhum disponível no momento.');
        vm.limpar();
        vm.popularCampos();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'revogar():fail()');
      let message = 'Falha ao revogar o Termo de Confidencialidade.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }

    function confirm() {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'revogar:confirm()');
      vm.log.info('Revogação do termo atual confirmada. Executando revogação no backend.');

      const params = {
        'idUsuario': vm.idUsuario,
        'idTermo': vm.idTermo
      };
      vm.webservice.post('ws/termoConfidencialidade/revogar', JSON.stringify(params), success, fail);
    }

    function deny() {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'revogar:deny()');
      vm.log.info('inclusão cancelada pelo usuário.');
    }

    vm.alertService.showConfirm('Atenção', 'Ao revogar este Termo de Confidencialidade não haverá nenhum disponível para ser assinado digitalmente pelos usuários do sistema. Essa ação não poderá ser revertida, confirma essa ação?', confirm, deny);

  }

  getPlatforms(platforms) {
    return '';
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('TermoConfidencialidadeController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (!res.data.termo[0]) {
        const messageRet = 'Nenhum Termo de Confidencialidade atualmente válido foi localizado';
        vm.alertService.showMessage('Atenção', messageRet);
      } else {
        if (res.data.termo[0].id) {
          vm.idTermo = res.data.termo[0].id;
          $('#btnRevogar').removeAttr('disabled');
        }
        if (res.data.termo[0].validade) {
          vm.txtValidadeTermo = res.data.termo[0].validade;
          vm.txtValidadeTermoOld = vm.txtValidadeTermo;
        }
        if (res.data.termo[0].conteudoHTML) {
          vm.txTermoConfidencialidade = res.data.termo[0].conteudoHTML;
          vm.txTermoConfidencialidadeOld = vm.txTermoConfidencialidade;
        }
      }
      vm.palavraChaveContent = [];
      if (res.data.atributosDB && res.data.atributosDB !== 0) {
        vm.palavraChaveContent.push({
          'atributo': '',
          'atributoAlias': 'Escolha o atributo'
        });
        res.data.atributosDB.forEach((e) => {
          vm.palavraChaveContent.push({
            'atributo': e.atributo,
            'atributoAlias': e.atributoAlias
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('TermoConfidencialidadeController', 'popularCampos():error');
      let message = 'Falha ao popular campos TermoConfidencialidadeController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    this.webservice.read('ws/termoConfidencialidade/filtrar', null, success, fail);

  }

  inserirCampo_onChange(val) {
    if (val !== '') {
        this.ckeditor.instance.insertText(val);
        setTimeout(() => this.cboPalavraChave = '');
    }
  }

  validade_onKeyUpEvent(event: any) {
    if (Number(event.target.value) > 60) {
      this.txtValidadeTermo = 60;
    } else if (Number(event.target.value) < 1) {
      this.txtValidadeTermo = 1;
    } else {
      this.txtValidadeTermo = event.target.value;
    }
  }

}
