import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CurrentView } from '../../../environments/currentview';
import { ApplicationService } from '../../services/application/application.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FavoritoService } from './../../services/favorito/favorito.service';

@Component({
  selector: 'app-faleconosco',
  templateUrl: './faleconosco.component.html',
  styleUrls: ['./faleconosco.component.css'],

})
export class FaleconoscoComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService,
    private favoritoService: FavoritoService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  timer = null;
  native: NativeInterfaceService;
  usuario = null;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  observacao = null;

  ngOnInit() {
    const vm = this;
    this.log.info('FaleconoscoComponent.init()');
    this.analytics.trackEvent('controller', 'FaleconoscoController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      mensagem: null
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.usuario = this.appService.getNomeUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.favoritoService.initFavorite(this.caminho, this.idUsuario)
      .subscribe((res: any) => {
        vm.isFavorito = res.idFavorito != null;
        vm.idFavorito = res.idFavorito;
        vm.idItemFavorito = res.idItemFavorito;
      }, (err: HttpErrorResponse) => {
        console.log('Error response.');
        console.log(err);
      });
    this.observacao = null;
    this.listar();
  }

  enviar() {
    const vm = this;
    this.analytics.trackEvent('FaleconoscoController', 'listar()');
    const params = {'mensagem': vm.myForm.get('mensagem').value};
    this.webservice.create('ws/faleconosco', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('FaleconoscoController', 'listar():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'Mensagem enviada com sucesso.');
        vm.limpar();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('FaleconoscoController', 'listar():error');

      let message = 'Falha ao enviar mensagem do Fale conosco.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }

      if (status === 403) {
        if (err) {
          message = err;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }

      vm.alertService.showMessage('Erro', message);
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      mensagem: null
    });
  }

  doFavorite() {
    const vm = this;
    const params = { 'idFavorito': vm.idFavorito, 'caminho': vm.caminho, 'idUsuario': vm.idUsuario };
    if (params !== null) {
      if (params.idFavorito == null && params.caminho !== null && params.idUsuario !== null) {
        vm.webservice.create('ws/favoritos', {'idItemFavorito': params.caminho, 'idUsuario': params.idUsuario}, success, fail);
      } else {
        vm.webservice.delete('ws/favoritos/' + params.idFavorito + '/F', null, success, fail);
      }
    }

    function success(res) {
      const op = res.operacao;
      vm.isFavorito = op === 'I';
      vm.idFavorito = res.idFavorito;
    }

    function fail(xhr, status, erro) {
      let message = 'Falha ao atualizar o Fale Conosco como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (erro) {
          message = erro;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, erro);
      if (xhr.status === 502 || erro === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listar() {
    const vm = this;
    this.webservice.read('ws/faleconosco/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('FaleconoscoController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (res && res.observacao) {
        vm.observacao = res.observacao;
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('FaleconoscoController', 'listar():error');
      let message = 'Falha ao listar FaleconoscoController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }
}
