import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITreeOptions, TreeComponent, TreeModel } from 'angular-tree-component';

@Component({
  selector: 'app-modal-input-move',
  templateUrl: './modal-input-move.component.html',
  styleUrls: ['./modal-input-move.component.css']
})
export class ModalInputMoveComponent implements OnInit {

  required = false;
  title: string = null;
  message: string = null;
  lblInput: string = null;
  lista = [];
  inputValue: string = null;
  myForm: FormGroup;
  options: ITreeOptions = {
    nodeHeight: 22
  };
  tipo: string;
  @ViewChild('tree', {static: false}) treeComponent: TreeComponent;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    //
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      filterPasta: [null, null]
    });
  }

  onClickNode(tree: TreeModel) {
    const vm = this;
    if (tree.activeNodes && tree.activeNodes.length > 0) {
      vm.inputValue = tree.activeNodes[0].data.link;
    }
  }
}
