import { Component, OnInit, Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { ApplicationService } from '../../services/application/application.service';
import { NativeInterfaceService } from '../../services/native-interface/native-interface.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import { AlertService } from '../../services/alert/alert.service';
import { ActivatedRoute } from '@angular/router';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-alterarsenha',
  templateUrl: './alterarsenha.component.html',
  styleUrls: ['./alterarsenha.component.css']
})
export class AlterarSenhaComponent implements OnInit {

  constructor(private log: LogService,  private webservice: WebServiceService, private appService: ApplicationService,
    private alertService: AlertService, private nativeInterface: NativeInterfaceService, private formBuilder: FormBuilder,
    private actroute: ActivatedRoute) {
      //
  }

  myForm: FormGroup;
  p = null;
  bValidar = false;

  ngOnInit() {
    this.nativeInterface.clearLocalStorage();
    this.log.info('AlterarSenhaController()');
    this.myForm = this.formBuilder.group({
      p:     null,
      senha: null
    });
    const p = this.actroute.snapshot.queryParamMap.get('p');
    if (p !== '') {
      this.myForm.controls['p'].setValue(p);
      this.doConfirm();
    } else {
      this.appService.openPage('/login');
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  doConfirm() {
    const vm = this;
    if (vm.bValidar) {
      const senha = $('input[name=senha]').val();
      const senhaConfirma = $('input[name=senhaConfirma]').val();
      if (senha === '' || senhaConfirma === '') {
        vm.alertService.showMessage('Atenção', 'A senha e a confirmação são de preenchimento obrigatório.');
        return;
      }
      if (senha !== senhaConfirma) {
        vm.alertService.showMessage('Atenção', 'A senha e a confirmação não coincidem.');
        return;
      }
    }
    function success(res) {
      if (res.msgsErro.length > 0) {
        vm.alertService.showMessage('Atenção', res.msgsErro[0]);
        if (res.sucesso) {
          vm.appService.openPage('/login');
        }
      } else {
        vm.bValidar = res.exigirSenha;
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao obter informações.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }

      if (status === 400) {
        message = xhr;
      }

      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }

      vm.alertService.showMessage('Erro', message);
      vm.appService.openPage('/login');
    }

    vm.webservice.create('ws/auth/as', vm.myForm.value, success, fail);
  }

}
