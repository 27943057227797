import { Injectable } from '@angular/core';
import Logger from 'log4ts/build/Logger';
import LoggerConfig from 'log4ts/build/LoggerConfig';
import BasicLayout from 'log4ts/build/layouts/BasicLayout';
import ConsoleAppender from 'log4ts/build/appenders/ConsoleAppender';
import { IAppender } from 'log4ts/build/IAppender';
import { ILayout } from 'log4ts/build/ILayout';
import { LogLevel } from 'log4ts/build/LogLevel';

const VERSION = '2.0.0';

@Injectable()
export class LogService {

  private log: Logger;
  private appender: IAppender;
  private layout: ILayout;
  private config: LoggerConfig;

  constructor() {
    this.layout = new BasicLayout();
    this.appender = new ConsoleAppender();
    this.appender.setLayout(this.layout);
    this.config = new LoggerConfig(this.appender);
    this.config.setLevel(LogLevel.ALL);
    this.log = new Logger();
    Logger.setConfig(this.config);
  }

  version() {
    return VERSION;
  }

  setFilter(filter) {}

  getFilter() {
      return null;
  }

  info(message) {
    this.log.info(message);
  }

  debug(message) {
    this.log.debug(message);
  }

  warning(message) {
    this.log.warn(message);
  }

  error(error: any, message: string = null) {
    this.log.error(error);
    if (console && console.group && console.error) {
      if (message) {
        console.group(message);
      } else {
        console.group('Error Log');
      }
      if (error !== undefined) {
        console.error(error);
        console.error(error.message);
        console.error(error.stack);
      }
      console.groupEnd();
    }
  }

  get logBind() {
    return console.log.bind(console);
  }

  get() {}

  rest() {}

  public static get logLevel(): LogLevel {
    return LogLevel.ALL;
  }

}
