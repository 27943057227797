import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-palavrachave',
  templateUrl: './palavrachave.component.html',
  styleUrls: ['./palavrachave.component.css'],

})
export class PalavraChaveComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  perfilContent = [];
  tblData = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  filtrotbl = null;
  fulltbl2Data = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  exibirTabela = false;
  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;
  public p: number;

  ngOnInit() {
    this.log.info('PalavraChaveComponent.init()');
    this.analytics.trackEvent('controller', 'PalavraChaveController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({});
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.acessoInclusao = this.appService.temAutorizacao('palavrachave', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('palavrachave', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('palavrachave', 'E');
    this.initFavorite();
    this.filtrar();
    this.p = 1;
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  getPlatforms(platforms) {
    return '';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito PalavraChaveController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveController', 'favoritos():error');
      let message = 'Falha ao atualizar o pesquisa usuario como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  filtrar() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveController', 'filtrar()');
    vm.p = 1;
    vm.webservice.post('ws/palavrachave/filtrar', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveController', 'filtrar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data.palavraChave && res.data.palavraChave.length > 0) {
        res.data.palavraChave.forEach((e) => {
          vm.tblData.push({
            'id': e.id,
            'palavraChave': e.palavraChave
          });
        });
      }
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveController', 'filtrar():success');
      let message = 'Falha ao filtrar os registros.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }
  }

  incluir() {
    this.analytics.trackEvent('PalavraChaveController', 'incluir()');
    this.appService.openPage('palavrachavecadastro');
  }

  alterar(row) {
    this.analytics.trackEvent('PalavraChaveController', 'alterar()');
    this.appService.openPage('palavrachavecadastro', row);
  }

  excluir(row) {
    const vm = this;
    vm.analytics.trackEvent('PalavraChaveController', 'excluir()');

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveController', 'excluir():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao excluir a Palavra Chave - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'Palavra Chave excluída com sucesso.');
        vm.filtrar();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveController', 'excluir():fail');
      let message = 'Falha ao excluir a Palavra Chave';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
    }

    function confirm() {
      vm.analytics.trackEvent('PalavraChaveController', 'confirm()');
      vm.log.info('Exclusão confirmada. Executando exclusao no backend.');

      vm.webservice.post('ws/palavrachave/excluir', JSON.stringify( row ), success, fail);
    }

    function deny() {
      vm.analytics.trackEvent('PalavraChaveController', 'deny()');
      vm.log.info('Exclusão cancelada pelo usuário.');
    }

    vm.alertService.showConfirm('Atenção', 'A exclusão desta Palavra Chave implica na remoção de todas as associações existentes. Essa ação não poderá ser revertida, confirma essa ação?', confirm, deny);
  }
}
