import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArrecadacaoConsolidadaPubComponent } from './components/publico/arrecadacaoconsolidadapub/arrecadacaoconsolidadapub.component';
import { ContratacoesPubComponent } from './components/publico/contratacoespub/contratacoespub.component';
import { DescontosPubComponent } from './components/publico/descontospub/descontospub.component';
import { ExecucaoorcamentariaPubComponent } from './components/publico/execucaoorcamentariapub/execucaoorcamentariapub.component';
import { InteligenciaPubComponent } from './components/publico/inteligenciapub/inteligenciapub.component';
import { LegislacaoPubComponent } from './components/publico/legislacaopub/legislacaopub.component';
import { ProponentePubComponent } from './components/publico/proponentepub/proponentepub.component';
import { SaquesConsolidadosPubComponent } from './components/publico/saquesconsolidadospub/saquesconsolidadospub.component';

const routes: Routes = [
  { path: 'contratacoespub', component: ContratacoesPubComponent },
  { path: 'arrecadacaoconsolidadapub', component: ArrecadacaoConsolidadaPubComponent },
  { path: 'descontospub', component: DescontosPubComponent },
  { path: 'execucaoorcamentariapub', component: ExecucaoorcamentariaPubComponent },
  { path: 'inteligenciapub', component: InteligenciaPubComponent },
  { path: 'legislacaopub', component: LegislacaoPubComponent },
  { path: 'proponentepub', component: ProponentePubComponent },
  { path: 'saquesconsolidadospub', component: SaquesConsolidadosPubComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicoRoutingModule { }
