import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorService } from '../../services/error/error.service';

@Injectable()
export class GeneralErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {}

  handleError(error) {
    const errorService: ErrorService = this.injector.get(ErrorService);
    errorService.handleError('Ocorreu uma falha geral na aplicação', error);
  }

}
