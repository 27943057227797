import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Config } from '../../../../environments/config';
import { CurrentView } from '../../../../environments/currentview';
import { AlertService } from '../../../services/alert/alert.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../services/application/application.service';
import { LogService } from '../../../services/log/log.service';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';

@Component({
  selector: 'app-institucional-indicadorestrategico',
  templateUrl: './institucional-indicadorestrategico.component.html',
  styleUrls: ['./institucional-indicadorestrategico.component.css']
})
export class InstitucionalIndicadorEstrategicoComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
      private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
      private alertService: AlertService, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  native: NativeInterfaceService;
  usuario = null;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  lista = [];

  ngOnInit() {
    this.log.info('InstitucionalPlanegamentoEstrategicoComponent.init()');
    this.analytics.trackEvent('controller', 'InstitucionalIndicadorEstrategico');

    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({

      imagem: [ null , []],

      id: [null]
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.lista = [];
    this.listar();
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('InstitucionalController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('InstitucionalController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InstitucionalController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito InstitucionalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  getPlatforms(platforms) {
    return '';
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('InstitucionalIndicadorEstrategico', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InstitucionalIndicadorEstrategico', 'favoritos():error');
      let message = 'Falha ao consultar hist institucional como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listar() {
    const vm = this;
    this.webservice.read('ws/institucional/indEstrategicos', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('InstitucionalIndicadorEstrategico', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.lista = [];
      if (res && res.data && res.data.length > 0) {
        vm.lista = res.data;
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InstitucionalIndicadorEstrategico', 'listar():error');
      let message = 'Falha ao listar InstitucionalIndicadorEstrategico';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }
}
