import { Injectable } from '@angular/core';
import { WebServiceService } from '../web-service/web-service.service';

@Injectable()
export class FavoritoService {

  constructor(private webservice: WebServiceService) {
    //
  }

  caminho = '';
  idUsuario = null;

  /**
   * Inicializa o indicador de favorito para a funcionalidade.
   */
  initFavorite(caminho, idUsuario) {
    return this.webservice.getObservable('ws/favoritos', {'idItemFavorito': caminho, 'idUsuario': idUsuario});
  }
}
