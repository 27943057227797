import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.css']
})
export class ModalContainerComponent {

  message;

  title;

  nomeBotaoCancelar = 'Cancelar';

  nomeBotaoConfirmar = 'OK';

  constructor(private dialogRef: MatDialogRef<ModalContainerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = data.title;
    this.message = data.message;
    if (data.nomeBotaoCancelar) {
      this.nomeBotaoCancelar = data.nomeBotaoCancelar;
    }
    if (data.nomeBotaoConfirmar) {
      this.nomeBotaoConfirmar = data.nomeBotaoConfirmar;
    }
  }

  @HostListener('document:keyup.escape') onClose() {
    this.onCancel();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close('OK');
  }
}
