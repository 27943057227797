import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { TermoConfidencialidadeComponent } from '../../components/termoconfidencialidade/termoconfidencialidade.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PipeModule } from '../../pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { TermoconfidencialidadeRoutingModule } from './termoconfidencialidade-routing.module';
@NgModule({
  declarations: [
    TermoConfidencialidadeComponent
  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, TermoconfidencialidadeRoutingModule,
    AngularMultiSelectModule, NgxPaginationModule, PipeModule, CKEditorModule
  ]
})
export class TermoconfidencialidadeModule { }
