import { Component, OnInit, Injectable, ɵConsole } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { ApplicationService } from '../../services/application/application.service';
import { NativeInterfaceService } from '../../services/native-interface/native-interface.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AlertService } from '../../services/alert/alert.service';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-alterarsenhadesafio',
  templateUrl: './alterarsenhadesafio.component.html',
  styleUrls: ['./alterarsenhadesafio.component.css']
})
export class AlterarSenhaDesafioComponent implements OnInit {

  constructor(private log: LogService,  private webservice: WebServiceService, private appService: ApplicationService,
    private alertService: AlertService, private nativeInterface: NativeInterfaceService, private formBuilder: FormBuilder) {
      //
  }

  myForm: FormGroup;
  email = null;
  bEmail = false;

  ngOnInit() {
    this.nativeInterface.clearLocalStorage();
    this.log.info('AlterarSenhaDesafioController()');
    this.myForm = this.formBuilder.group({
      usuario: null,
      email:   null
    });
  }

  getPlatforms(platforms) {
    return '';
  }

  doConfirm($event) {

    const vm = this;

    const cpf = $('input[name=usuario]').val();
    const email = $('input[name=email]').val();

    const cpfReg = /^\d{11}$/;
    const emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if (! cpfReg.test(cpf) ) {
      vm.alertService.showMessage('Atenção', 'Informe um usuário válido.');
      return;
    }
    if (vm.bEmail) {
      if (! emailReg.test(email) ) {
        vm.alertService.showMessage('Atenção', 'Informe um e-mail válido.');
        return;
      }
    }

    function success(res) {

      if (res.msgsErro.length > 0) {
        vm.alertService.showMessage('Atenção', res.msgsErro[0]);
        vm.appService.openPage('/login');
        return;
      }
      vm.email = res.email;
      vm.bEmail = true;
    }

    function fail(xhr, status, err) {

      let message = 'Falha ao obter informações.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }

      if (status === 400) {
        message = xhr;
      }

      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }

      vm.alertService.showMessage('Erro', message);
    }

    vm.webservice.create('ws/auth/dsf', vm.myForm.value, success, fail);
  }

}
