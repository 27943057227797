import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsultaVideoComponent } from './consulta/consultavideo.component';
import { PesquisaTrilhaCapacitacaoComponent } from './pesquisa/pesquisatrilhacapacitacao.component';

const routes: Routes = [
  { path: '', component: PesquisaTrilhaCapacitacaoComponent },
  { path: 'consulta', component: ConsultaVideoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrilhacapacitacaoRoutingModule { }
