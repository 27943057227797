import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { VgAPI } from 'videogular2/compiled/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router} from '@angular/router';

@Component({
  selector: 'app-consultavideo',
  templateUrl: './consultavideo.component.html',
  styleUrls: ['./consultavideo.component.css']
})
export class ConsultaVideoComponent implements OnInit {

  myForm: FormGroup;
  idVideo: number;
  currentview = CurrentView;
  api: VgAPI;
  tempoDecorrido = 0;
  video = null;
  duracao: number;
  videoLoaded = false;
  segAtual = 0;
  idVideoUsuario: number;
  nomeArquivo = '';

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    public appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private http: HttpClient,
    private translate: TranslateService, private domSanitizer: DomSanitizer) {
    translate.setDefaultLang('pt');
    this.idVideo = this.router.getCurrentNavigation().extras.state.id !== undefined ? this.router.getCurrentNavigation().extras.state.id : 0;
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      idVideo: null
    });
    this.tempoDecorrido = 0;
    this.duracao = 0;
    this.segAtual = 0;
    this.baixarVideo();
  }

  baixarVideo() {
    const vm = this;
    // chamar endpoint para obter o tempo decorrido do video assistido pelo usuario pela última vez.
    vm.tempoDecorrido = 0;
    vm.webservice.read('ws/videos/' + vm.idVideo, null, success, fail);
    function success(res) {
      if ( res && res.data ) {
        res.data.forEach((e) => {
          vm.idVideoUsuario = e.id;
          vm.video = vm.domSanitizer.bypassSecurityTrustResourceUrl('data:video/mp4;base64,' + e.video.base64);
          vm.tempoDecorrido = e.tempoDecorrido;
          vm.videoLoaded = true;
          vm.nomeArquivo = e.nomeArquivo;
        });
      }
    }
    function fail(xhr, status, err) {
      let message = 'Falha obter video.';
      if (status === 403 || status === 500) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
      vm.appService.openViewDelayed('trilhacapacitacao', 1, null, '');
    }
  }

  onPlayerReady(api: VgAPI) {
    const vm = this;
    this.api = api;

    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
      ($event) => {
        // executado apos o carregamento do video.
        // vm.api.duration -> mostra a duração total do video.
        vm.duracao = vm.api.duration;
      });

    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // reproducao do video encerrada.
        // reiniciar o tempo decorrido de reproducao do video.
        vm.atualizarTempoDecorrido(vm.api.getDefaultMedia().currentTime);
        vm.api.getDefaultMedia().currentTime = 0;
      });

    this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(
      ($event) => {
        // vm.api.currentTime -> mostra o tempo decorrido em segundos.
        // chamar endpoint a cada 8 segundos.
        const seg = Math.trunc(vm.api.currentTime);
        if (seg > 0 && (seg % 8) === 0 && seg !== this.segAtual) {
          this.segAtual = seg;
          vm.atualizarTempoDecorrido(seg);
        }
      });

    // avancar o video com o numero de segundos ja assistidos pelo usuario.
    this.api.getDefaultMedia().subscriptions.loadStart.subscribe(
      e => {
        if (vm.api.getDefaultMedia() && vm.api.getDefaultMedia() != null && vm.api.getDefaultMedia() !== undefined) {
          if (vm.api.getDefaultMedia().currentTime !== undefined && vm.api.getDefaultMedia().currentTime !== null && vm.tempoDecorrido) {
            vm.api.getDefaultMedia().currentTime = vm.tempoDecorrido;
          }
        }
      });
  }

  atualizarTempoDecorrido(seg: number) {
    const vm = this;
    const material = {idVideo: vm.idVideo, tempoDecorrido: seg};
    vm.webservice.update('ws/videos', material, null, success, fail);

    function success(res) {
      // console.log('tempo decorrido atualizado no bd.')
    }

    function fail(res) {
      // console.log('erro ao atualizar o tempo decorrido no bd.')
    }
  }
}
