import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { LogService } from '../../services/log/log.service';

const VERSION = '2.0.0';
const ENDPOINT = 'http://localhost:4200/endpoint';

@Injectable()
export class EndpointService {

  constructor(private http: HttpClient, private log: LogService) { }

  version(): string {
    return VERSION;
  }

  request(request, data, successCallback, errorCallback, token, endPoint, silent) {

    if (endPoint == null) {
      endPoint = ENDPOINT;
    }
    if (token) {
      endPoint += ';jsessionid=' + token;
    }

    const body = { request: request, data: JSON.stringify(data), token: token };

    this.http.post(endPoint, body)
      .subscribe( dataAux => {
        if (successCallback) {
          successCallback(dataAux);
        }
      },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.log.error(err.error.message, 'Client-side error occured.');
            if (errorCallback) {
              errorCallback(err.error.message);
            }
          } else {
            this.log.error(err.message, 'Server-side error occured.');
            if (errorCallback) {
              errorCallback(err.message, err.status);
            }
          }
        });
  }

}
