import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { Config } from '../../../environments/config';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CurrentView } from '../../../environments/currentview';
import { ApplicationService } from '../../services/application/application.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import * as numeral from 'numeral';
import 'numeral/locales/pt-br';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { UtilsService } from './../../services/utils/utils.service';

declare const $: any;

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css', './material.component.css']
})
export class PrincipalComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
      private appService: ApplicationService, private webservice: WebServiceService, private alertService: AlertService,
      private domSanitizer: DomSanitizer, private router: Router, private nativeInterface: NativeInterfaceService,
      private mScrollbarService: MalihuScrollbarService, private dialog: MatDialog, private utilservice: UtilsService ) {
    this.currentview.locked = false;
    this.currentview.menu = true;
  }

  idUsuario = null;
  idTermo = null;
  assinaturaTermo = null;
  showFavoritos = false;
  favoritos = [];
  currentview = CurrentView;
  myForm: FormGroup;

  anoRefArrecadLiquid = '';
  anoRefContrat = '';
  anoRefExecOrc = '';
  anoRefSaque = '';
  apfPosicao = '';
  sfgPosicao = '';
  tbArrecadLiquid = [];
  tbContrat = [];
  tbExecOrc = [];
  tbSaque = [];
  graphLoaded = false;
  videoLoaded = false;
  imagem01 = null;
  imagem01Width = null;
  imagem01Height = null;
  imagem02 = null;
  imagem02Width = null;
  imagem02Height = null;
  imagem03 = null;
  imagem03Width = null;
  imagem03Height = null;
  srcTimeline01 = null;

  hasCentral = Config.CENTRAL_ID !== '[null]';

  timer = null;

  // GRÁFICO DE EXECUÇÃO ORÇAMENTARIA
  rExecOrcChartOptions = {
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Áreas'
        },
        ticks: {
          fontFamily: 'Calibri,Candara,Segoe,\'Segoe UI\',Optima,Arial,sans-serif',
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Valores'
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        },
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return 'R$ ' + numeral(value).format('0,0');
          }
        }
      }]
    }
  };
  rExecOrcChartColors = this.utilservice.chartColors;
  rExecOrcChartType = 'bar';
  rExecOrcChartLegend = true;
  rExecOrcChartPlugins = [];
  rExecOrcChartLabels = [];
  rExecOrcChartData = [];
  rExecOrcChartDataC1 = [];
  rExecOrcChartDataC2 = [];

  // GRÁFICO DE CONTRATAÇÕES ORÇAMENTO
  rContrOrcChartOptions = {
    responsive: true,
    scaleShowVerticalLines: true,
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
        }
      }
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Áreas'
        },
        ticks: {
          fontFamily: 'Calibri,Candara,Segoe,\'Segoe UI\',Optima,Arial,sans-serif',
        },
          gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }],
      yAxes: [
        {
          id: 'yAxis1',
          position: 'left',
          display: true,
          beginAtZero: true,
          scaleLabel: {
            display: true,
            labelString: 'Operações',
          },
          ticks: {
            callback: function (value, index, values) {
              return numeral(value).format('0,0');
            }
          },
          gridLines: {
            color: 'rgba(51,177,174,0.3)',
          }
        },
        {
          id: 'yAxis2',
          position: 'right',
          display: true,
          scaleLabel: {
          display: true,
            labelString: 'Valores',
          },
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return 'R$ ' + numeral(value).format('0,0');
            }
          },
          gridLines: {
            color: 'rgba(255,255,255,0)',
          }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  rContrOrcChartColors = this.utilservice.chartColors;
  rContrOrcChartType = 'bar';
  rContrOrcChartLegend = true;
  rContrOrcChartPlugins = [];
  rContrOrcChartLabels = [];
  rContrOrcChartData = [];
  rContrOrcChartDataC1 = [];
  rContrOrcChartDataC2 = [];

  // GRÁFICO DE ARRECADAÇÃO LÍQUIDA
  rArrecLiqChartOptions = {
    scaleShowVerticalLines: true,
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Anos'
        },
        ticks: {
          fontFamily: 'Calibri,Candara,Segoe,\'Segoe UI\',Optima,Arial,sans-serif',
        },
          gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }],
      yAxes: [{
        display: true,
        beginAtZero: true,
        scaleLabel: {
          display: true,
          labelString: 'Valores'
        },
        ticks: {
          callback: function (value, index, values) {
            return 'R$ ' + numeral(value).format('0,0');
          }
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }]
    }
  };
  rArrecLiqChartColors = this.utilservice.chartColors;
  rArrecLiqChartType = 'bar';
  rArrecLiqChartLegend = true;
  rArrecLiqChartLabels = [];
  rArrecLiqChartData = [];
  rArrecLiqChartDataC1 = [];
  rArrecLiqChartDataC2 = [];
  rArrecLiqChartDataC3 = [];

  // GRÁFICO DE SAQUES
  rSaqueChartOptions = {
  responsive: true,
  scaleShowVerticalLines: true,
  hover: {
    mode: 'nearest',
    intersect: true
  },
  tooltips: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: function(tooltipItem, data) {
        return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
      }
    }
  },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'Modalidades'
      },
      ticks: {
        fontFamily: 'Calibri,Candara,Segoe,\'Segoe UI\',Optima,Arial,sans-serif',
      },
      gridLines: {
        color: 'rgba(51,177,174,0.3)',
      }
    }],
    yAxes: [
      {
        id: 'yAxis1',
        position: 'left',
        display: true,
        beginAtZero: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantidade',
        },
        ticks: {
          callback: function (value, index, values) {
            return numeral(value).format('0,0');
          }
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      },
      {
        id: 'yAxis2',
        position: 'right',
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Valores',
        },
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return 'R$ ' + numeral(value).format('0,0');
          }
        },
        gridLines: {
          color: 'rgba(255,255,255,0)',
        }
    }]
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    }
  }
  };
  rSaqueChartColors = this.utilservice.chartColors;
  rSaqueChartType = 'bar';
  rSaqueChartLegend = true;
  rSaqueChartPlugins = [];
  rSaqueChartLabels = [];
  rSaqueChartData = [];
  rSaqueChartDataC1 = [];
  rSaqueChartDataC2 = [];


  showNotificacoes = false;
  notificacoes = [];
  showMensagens = false;
  mensagens = [];

  scrollbarOptions;

  ngOnInit() {
    const vm = this;
    this.analytics.trackEvent('controller', 'PrincipalController');

    this.myForm = this.formBuilder.group({
      imagem: [ null , []],
      id: [null]
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.currentview.menu = true;
    this.currentview.searchbox = true;
    numeral.locale('pt-br');
    vm.scrollbarOptions = { axis: 'y', theme: 'sicnl', scrollEasing: 'linear', scrollInertia: 3000, alwaysShowScrollbar: 0, autoExpandScrollbar: false, scrollButtons: { enable: false, scrollType: 'stepped' } };

    // Verificar se o Termo atual está assinado ('A').
    // Senao, exibir modal com o termo confidencialidade.
    this.assinaturaTermo = this.appService.isTermoAssinado();
    if (this.assinaturaTermo) {
      vm.retornarDados();
      vm.listarImagens();
      vm.retornarVideos();
    } else {
      vm.preTermoConfidencialidade();
    }
  }

  registrarJS() {
    $.extend(true, $.fancybox.defaults, {
      width: 640,
      height: 360,
      smallBtn: true,
      toolbar: false,
      animationEffect: 'zoom-in-out',
      animationDuration: 400,
      closeExisting: true,
      spinnerTpl: '<div class="fancybox-loading"></div>',
      video: {
        autoStart: true
      },
      lang: 'ptBR',
      i18n: {
        ptBR: {
          CLOSE: 'Fechar',
          NEXT: 'Próximo',
          PREV: 'Anterior',
          ERROR: 'O conteúdo solicitado não pode ser carregado. <br/> Tente novamente mais tarde.',
          PLAY_START: 'Iniciar slideshow',
          PLAY_STOP: 'Pausar slideshow',
          FULL_SCREEN: 'Tela cheia',
          THUMBS: 'Thumbnails',
          DOWNLOAD: 'Download',
          SHARE: 'Compartilhar',
          ZOOM: 'Aumentar'
        }
      }
    });

    $(document).ready(function() {

      $('.abrirFecharDivTexto a').click(function(evt) {
        evt.preventDefault();
        const o = $(this).attr('href');
        if ($('#' + o).is(':hidden')) {
            $('#' + o).fadeIn('slow');
            $(this).text('Ocultar tabela');
          } else {
            $('#' + o).fadeOut('slow');
            $(this).text('Exibir tabela');
        }
      });
      $('body').on('mouseover', '.note-pessoal-container', function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('.note-pessoal-container').stop();
      });
      $('body').on('mouseout', '.note-pessoal-container', function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('.note-pessoal-container').animate({scrollTop: $('.note-pessoal-container')[0].scrollHeight}, 30000);
      });
    });
  }

  getPlatforms(platforms) {
    return '';
  }

  confirmDelete() {
    const vr = this;
    function confirm() {
      vr.alertService.showMessage('Atenção', 'Exclusão confirmada pelo usuário');
    }
    function deny() {
      vr.alertService.showMessage('Atenção', 'Exclusão cancelada pelo usuário');
    }
    this.alertService.showConfirm('Atenção', 'Confirma a exclusão deste item?', confirm, deny);
  }

  populaDadosForm(dados) {
    this.myForm.patchValue({
      imagem: dados.imagem,
      id: [null]
    });
  }

  retornarDados() {
    const vm = this;
    this.analytics.trackEvent('PrincipalController', 'retornarDados()');
    const params = new HttpParams().append('idUsuario', this.idUsuario);
    this.webservice.read('ws/principal', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'retornarDados():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }

      vm.anoRefArrecadLiquid = res.anoRefArrecadLiquid;
      vm.anoRefContrat = res.anoRefContrat;
      vm.anoRefExecOrc = res.anoRefExecOrc;
      vm.anoRefSaque = res.anoRefSaque;
      vm.apfPosicao = res.apfPosicao;
      vm.sfgPosicao = res.sfgPosicao;

      // EXECUÇÃO ORÇAMENTÁRIA
      vm.tbExecOrc = [];
      res.lstGNExecOrcam.forEach((e) => {
        vm.tbExecOrc.push({
          'colA': e.colA,
          'colB': e.colB,
          'colC': e.colC
        });
        vm.rExecOrcChartLabels.push(e.colA);
        vm.rExecOrcChartDataC1.push(e.colB.replace(/\./g, ''));
        vm.rExecOrcChartDataC2.push(e.colC.replace(/\./g, ''));
      });
      vm.rExecOrcChartData = [ { data: vm.rExecOrcChartDataC1, label: 'Repassado' }, { data: vm.rExecOrcChartDataC2, label: 'Realizado' } ];

      // CONTRATAÇÕES
      vm.tbContrat = [];
      res.lstGNContrat.forEach((e) => {
        vm.tbContrat.push({
          'colA': e.colA,
          'colB': e.colB,
          'colC': e.colC
        });
        vm.rContrOrcChartLabels.push(e.colA);
        vm.rContrOrcChartDataC1.push(e.colB.replace(/\./g, ''));
        vm.rContrOrcChartDataC2.push(e.colC.replace(/\./g, ''));
      });
      vm.rContrOrcChartData = [ { data: vm.rContrOrcChartDataC1, label: 'Operações', yAxisID: 'yAxis1' }, { data: vm.rContrOrcChartDataC2, label: 'Empréstimo', type: 'line', fill: false, yAxisID: 'yAxis2' } ];

      // ARRECADAÇÃO LÍQUIDA
      vm.tbArrecadLiquid = [];
      res.lstGNArrecadLiquid.forEach((e) => {
        vm.tbArrecadLiquid.push({
          'colA': e.colA,
          'colB': e.colB,
          'colC': e.colC,
          'colD': e.colD
        });
        vm.rArrecLiqChartLabels.push(e.colD);
        vm.rArrecLiqChartDataC1.push(e.colA.replace(/\./g, ''));
        vm.rArrecLiqChartDataC2.push(e.colB.replace(/\./g, ''));
        vm.rArrecLiqChartDataC3.push(e.colC.replace(/\./g, ''));
      });
      vm.rArrecLiqChartData = [
        { data: vm.rArrecLiqChartDataC1, fill: true, label: 'Arrecadação Líquida' },
        { data: vm.rArrecLiqChartDataC2, fill: true, label: 'Arrecadação' },
        { data: vm.rArrecLiqChartDataC3, fill: true, label: 'Saques' }
      ];

      // SAQUES
      vm.tbSaque = [];
      res.lstGNSaque.forEach((e) => {
        vm.tbSaque.push({
          'colA': e.colA,
          'colB': e.colB,
          'colC': e.colC
        });
        vm.rSaqueChartLabels.push(e.colA);
        vm.rSaqueChartDataC1.push(e.colB.replace(/\./g, ''));
        vm.rSaqueChartDataC2.push(e.colC.replace(/\./g, ''));
      });
      vm.rSaqueChartData = [
        { data: vm.rSaqueChartDataC1, label: 'Quantidade', yAxisID: 'yAxis1' },
        { data: vm.rSaqueChartDataC2, label: 'Valor', type: 'line', fill: false, yAxisID: 'yAxis2' }
      ];

      vm.graphLoaded = true;

      vm.registrarJS();

      // Favoritos (quadro de acesso rapido)
      vm.showFavoritos = res.listaItemFavorito && res.listaItemFavorito.length > 0;
      vm.favoritos = res.listaItemFavorito;

      vm.showNotificacoes = res.listaNotificacaoPessoal && res.listaNotificacaoPessoal.length > 0;
      vm.notificacoes = res.listaNotificacaoPessoal;

      vm.showMensagens = res.listaMensagemGestor && res.listaMensagemGestor.length > 0;
      vm.mensagens = res.listaMensagemGestor;

    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'retornarDados():error');
      let message = 'Falha ao popular campos PrincipalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  retornarVideos() {
    const vm = this;
    this.analytics.trackEvent('PrincipalController', 'retornarVideos()');
    vm.srcTimeline01 = null;
    vm.videoLoaded = false;
    this.webservice.read('ws/principal/videos', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'retornarVideos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (res.video01 !== null) {
        vm.srcTimeline01 = vm.domSanitizer.bypassSecurityTrustResourceUrl('data:video/mp4;base64,' + res.video01.base64);
      }
      vm.videoLoaded = true;
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'retornarVideos():error');
      vm.videoLoaded = true;
      let message = 'Falha ao buscar vídeos PrincipalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  execute(tipo, link, nome) {
    if (tipo === 'P') {
      this.router.navigate(['/arquivos', {'pasta': link}]);
      return;
    }
    window.open('#/' + link, '_self');
  }

  doExcluirFavorito(id, tipo) {
    const vm = this;
    vm.webservice.delete('ws/favoritos/' + id + '/' + tipo, null, success, fail);
    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'doExcluirFavorito():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
        return;
      }
      vm.favoritos.forEach((e, i) => {
        if (e.id === id) {
          vm.favoritos.splice(i, 1);
        }
      });
      vm.showFavoritos = vm.favoritos.length > 0;
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'doExcluirFavorito():error');
      let message = 'Falha ao excluir favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  doExcluirNotificacao(id, pasta, arquivo) {
    const vm = this;
    const params = new HttpParams().append('arquivo', pasta + '/' + arquivo);
    vm.webservice.delete('ws/notificacoes/' + id, params, success, fail);
    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'doExcluirNotificacao():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
        return;
      }
      vm.notificacoes.forEach((e, i) => {
        if (e.id === id) {
          vm.notificacoes.splice(i, 1);
        }
      });
      vm.showNotificacoes = vm.notificacoes.length > 0;
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'doExcluirNotificacao():error');
      let message = 'Falha ao excluir notificacao.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  doMarcarLido(idMensagem) {
    const vm = this;
    this.analytics.trackEvent('PrincipalController', 'doMarcarLido()');
    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'doMarcarLido():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.mensagens.forEach((e, i) => {
          if (e.id === idMensagem) {
            vm.mensagens.splice(i, 1);
          }
      });
      vm.showMensagens = vm.mensagens.length > 0;
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'doMarcarLido():fail');
      let message = 'Falha ao remover a mensagem.';
      message = (status === 404) && err ? err : message;
      if (status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }
    const params = {
      'idUsuario': vm.idUsuario,
      'idMensagem': idMensagem
    };
    this.webservice.put('ws/mensagemGestor', JSON.stringify( params ), null, success, fail);
  }

  termoConfidencialidade(conteudoHTML: String) {
    const vm = this;
    vm.analytics.trackEvent('PrincipalController', 'termoConfidencialidade()');
    let msgTermoHTML = '';
    msgTermoHTML += '<div id="modalTermoConfidencialidade">';
    msgTermoHTML += '  <div malihu-scrollbar [scrollbarOptions]="scrollbarOptions" class="termo-content-scroll" id="divTermoBody">' + conteudoHTML + '</div>';
    msgTermoHTML += '  <div class="termo-content-footer" id="divTermoFooter">';
    msgTermoHTML += '     <button class="btn btn-secondary" id="btnTermoNaoConcordo">NÃO CONCORDO</button>&nbsp;&nbsp;&nbsp;';
    msgTermoHTML += '     <button class="btn btn-primary" id="btnTermoConcordo" disabled="disabled">CONCORDO</button>';
    msgTermoHTML += '  </div>';
    msgTermoHTML += '</div>';

    setTimeout(() => {
      $.fancybox.open({
        closeExisting : true,
        src           : msgTermoHTML,
        type          : 'inline',
        modal         : true,
        width         : 1030,
        height        : 500
      });
      $('#btnTermoNaoConcordo').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        let timeLeft = 6;
        let msgCountdownTermoHTML = '';
        msgCountdownTermoHTML += '<div id="modalTermoConfidencialidade">';
        msgCountdownTermoHTML += ' <div class="termo-countdown">';
        msgCountdownTermoHTML += '   Não é possível o acesso ao Canal do FGTS sem a assinatura do Termo de Confidencialidade e sua sessão será, portanto, expirada.';
        msgCountdownTermoHTML += '   <br /><br /><p class="fontsize13">Efetuando o <i>log out</i> em <span id="termoCountdownLogout">' + timeLeft + '</span> segundos.</p></div>';
        msgCountdownTermoHTML += ' </div>';
        msgCountdownTermoHTML += '</div>';
        $.fancybox.open({
          closeExisting : true,
          src           : msgCountdownTermoHTML,
          type          : 'inline',
          modal         : true,
          width         : 935,
          height        : 150
        });
        const downloadTimer = setInterval(function() {
          if (timeLeft <= 0) {
            clearInterval(downloadTimer);
            $.fancybox.getInstance().close();
            vm.router.navigate(['login']);
          } else {
            $('#termoCountdownLogout').text( timeLeft );
          }
          timeLeft--;
        }, 1000);
      });
      $('#btnTermoConcordo').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        vm.salvarAssinaturaTermoConfidencialidade();
      });
      $('#divTermoBody').on('scroll', function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (Math.ceil($(this).scrollTop()) + Math.ceil($(this).innerHeight()) >= Math.ceil($(this)[0].scrollHeight)) {
          $('#btnTermoConcordo').removeAttr('disabled');
        } else {
          $('#btnTermoConcordo').attr('disabled', true);
        }
      });
    }, 300);
  }

  preTermoConfidencialidade() {
    const vm = this;
    this.analytics.trackEvent('PrincipalController', 'preTermoConfidencialidade()');
    const params = new HttpParams().append('idUsuario', this.idUsuario);
    vm.webservice.read('ws/termoConfidencialidade/lerTermo', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'preTermoConfidencialidade():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.idTermo = res.data.termo[0].id;
        let msgPreTermoHTML = '';
        const msgPreTermoPeriodoMeses = res.data.termo[0].validade;
        const msgPreTermoTop = '<div>Prezado Usuário,<br />A partir de agora é necessária e obrigatória a assinatura do Termo de Confidencialidade do Canal do FGTS, visando manter a segurança das informações aqui prestadas.<br />Solicitamos que o Termo seja assinado para que você possa continuar a navegar no Canal.<br />O referido documento tem validade indeterminada, devendo ser assinado periodicamente a cada <span id="divQtdMeses">' + msgPreTermoPeriodoMeses + '</span> meses.<br />Clique no botão abaixo para assinar.</div>';
        const msgTermoBody = res.data.termo[0].conteudoHTML;
        msgPreTermoHTML += '<div id="modalTermoConfidencialidade">';
        msgPreTermoHTML += '  <div  malihu-scrollbar [scrollbarOptions]="scrollbarOptions" class="termo-content-header" style="border-bottom: 0px !important;">' + msgPreTermoTop + '</div>';
        msgPreTermoHTML += '  <div class="termo-content-footer" id="divTermoFooter">';
        msgPreTermoHTML += '     <button class="btn btn-secondary" id="btnPreTermoCancelar">CANCELAR</button>&nbsp;&nbsp;&nbsp;';
        msgPreTermoHTML += '     <button class="btn btn-primary" id="btnPreTermoAssinar">ASSINAR</button>';
        msgPreTermoHTML += '  </div>';
        msgPreTermoHTML += '</div>';
        setTimeout(() => {
          $.fancybox.open({
            closeExisting : true,
            src           : msgPreTermoHTML,
            type          : 'inline',
            modal         : true,
            width         : 935,
            height        : 203
          });
          $('#btnPreTermoCancelar').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $.fancybox.getInstance().close();
            vm.router.navigate(['login']);
          });
          $('#btnPreTermoAssinar').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.termoConfidencialidade(msgTermoBody);
          });
        }, 100);
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'preTermoConfidencialidade():fail');
      let message = 'Falha ao exibir termo de Confidencialidade.';
      message = (status === 404) && err ? err : message;
      if (status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.retornarDados();
      vm.listarImagens();
      vm.retornarVideos();
    }
  }

  salvarAssinaturaTermoConfidencialidade() {
    const vm = this;
    this.analytics.trackEvent('PrincipalController', 'salvarAssinaturaTermoConfidencialidade()');
    const params = { 'idUsuario': vm.idUsuario, 'idTermo': vm.idTermo };
    this.webservice.post('ws/termoConfidencialidade/assinarTermo', JSON.stringify( params ), success, fail);

    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'salvarAssinaturaTermoConfidencialidade():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
        this.application.goAuth();
      }
      vm.retornarDados();
      vm.listarImagens();
      vm.retornarVideos();
      $.fancybox.getInstance().close();
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'salvarAssinaturaTermoConfidencialidade():fail');
      let message = 'Falha ao salvar Assinatura do Termo de Confidencialidade.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
      this.application.goAuth();
    }
  }

  listarImagens() {
    const vm = this;
    this.analytics.trackEvent('PrincipalController', 'listarImagens()');
    this.webservice.read('ws/principal/imagens', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PrincipalController', 'listarImagens():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (res.imagem01 !== null) {
        vm.imagem01 = vm.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + res.imagem01.base64);
        vm.imagem01Width = res.imagem01.width;
        vm.imagem01Height = res.imagem01.height;
      }
      if (res.imagem02 !== null) {
        vm.imagem02 = vm.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + res.imagem02.base64);
        vm.imagem02Width = res.imagem02.width;
        vm.imagem02Height = res.imagem02.height;
      }
      if (res.imagem03 !== null) {
        vm.imagem03 = vm.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + res.imagem03.base64);
        vm.imagem03Width = res.imagem03.width;
        vm.imagem03Height = res.imagem03.height;
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PrincipalController', 'listarImagens():error');
      let message = 'Falha ao buscar imagens PrincipalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }
}
