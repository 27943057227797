import { Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';

const VERSION = '2.0.0';

@Injectable()
export class ErrorService {
  private message: string = null;
  private cause: string = null;
  private stack: any = null;

  constructor(private log: LogService) {}

  version() {
    return VERSION;
  }

  throw(): ErrorService {
    return null;
  }

  showError() {
  }

  reportError(log) {
  }

  getMessage(): string {
    return this.message;
  }
  setMessage(message: string) {
    this.message = message;
  }
  setCause(cause: string) {
    this.cause = cause;
  }
  getCause(): string {
    return this.cause;
  }
  setError(message: string, cause: string) {
    this.message = message;
    this.cause = cause;
  }
  getStack() {
    return this.stack;
  }
  setStack(stack) {
    this.stack = stack;
  }

  handleError(message, error) {
    this.log.error(error);
    const errorService = new ErrorService(this.log);
    errorService.setMessage(error.message);
    if (error.cause) {
      errorService.setCause(error.cause);
    }
    if (error.stack) {
      errorService.setStack(error.stack);
    }
  }
}
