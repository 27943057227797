import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Config } from '../../../../environments/config';
import { CurrentView } from '../../../../environments/currentview';
import { FormValidations } from '../../../resources/form-validations';
import { AlertService } from '../../../services/alert/alert.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../services/application/application.service';
import { LogService } from '../../../services/log/log.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';

@Component({
  selector: 'app-proponentepub',
  templateUrl: './proponentepub.component.html',
  styleUrls: ['./proponentepub.component.css',
              '../../../../assets/css/publico/publico.css']
})
export class ProponentePubComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private translate: TranslateService) {
    translate.setDefaultLang('pt');
  }

  currentview = CurrentView;
  myForm: FormGroup;
  exibirTabela = false;
  existe = false;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  icOrigem = null;
  dtPosicao = null;
  nuCpf = null;
  tpCpf = null;
  dtContrato = null;
  nuContrato = null;
  deModalidade = null;
  icSituacao = null;
  hoje = null;
  ip = null;
  validador = null;

  ngOnInit() {
    this.log.info('ProponentePubComponent.init()');
    this.analytics.trackEvent('controller', 'ProponentePubController');

    const element = <HTMLElement> document.getElementsByClassName('theme-app')[0];
    element.style.backgroundColor = '#FFF';

    this.currentview.locked = false;
    this.currentview.menu = true;
    this.hoje = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');

    this.myForm = this.formBuilder.group({
      id: null,
      cpf: [ null , [ FormValidations.cpfValidator, Validators.required ]]
    });

    this.webservice.read('ws/publico/proponente/filtros', null, success, function(){});
    function success(res) {
      //
    }
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ProponentePubController', 'listar()');

    if (vm.myForm.get('cpf').value === '' || vm.myForm.get('cpf').value === null) {
      vm.alertService.showMessage('Atenção', 'Para realizar a consulta, informe o CPF.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('ProponentePubController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ip = res.proponente.ip;
      vm.validador = res.proponente.validador;
      vm.existe = res.proponente.existe;
      vm.icOrigem = res.proponente.icOrigem;
      vm.dtPosicao = res.proponente.dtPosicao;
      vm.nuCpf = res.proponente.nuCpf;
      vm.tpCpf = res.proponente.tpCpfFmt;
      vm.dtContrato = res.proponente.dtContrato;
      vm.nuContrato = res.proponente.nuContrato;
      vm.deModalidade = res.proponente.deModalidade;
      vm.icSituacao = res.proponente.icSituacao;
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ProponentePubController', 'listar():error');
      let message = 'Falha ao listar ProponentePubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    const params = new HttpParams().append('cpf', vm.myForm.get('cpf').value);
    this.webservice.read('ws/publico/proponente', params, success, fail);
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      cpf: null
    });
    this.exibirTabela = false;
    this.existe = false;
    this.icOrigem = null;
    this.dtPosicao = null;
    this.nuCpf = null;
    this.tpCpf = null;
    this.dtContrato = null;
    this.nuContrato = null;
    this.deModalidade = null;
    this.icSituacao = null;
    this.ip = null;
    this.validador = null;
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('ProponentePubController', 'imprimir()');
    if (vm.myForm.get('cpf').value === '' || vm.myForm.get('cpf').value === null) {
      vm.alertService.showMessage('Atenção', 'Para realizar a consulta, informe o CPF.');
      return;
    }
    let params = new HttpParams().append('nuCpf', this.nuCpf);
    params = params.append('tpCpf', this.tpCpf);
    params = params.append('dtContrato', this.dtContrato);
    params = params.append('deModalidade', this.deModalidade);
    params = params.append('icSituacao', this.icSituacao);
    params = params.append('ip', this.ip);
    params = params.append('hoje', this.hoje);
    params = params.append('validador', this.validador);
    this.webservice.read('ws/publico/imprimirproponente', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('ProponentePubController', 'imprimir():success');
      const filename = 'Proponente.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }
}
