import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

import { ModalAlertComponent } from '../../resources/modal-alert/modal-alert.component';
import { ModalConfirmComponent } from '../../resources/modal-confirm/modal-confirm.component';
import { ModalInputComponent } from '../../resources/modal-input/modal-input.component';
import { ModalInputMoveComponent } from '../../resources/modal-input-move/modal-input-move.component';
import { ModalWarningComponent } from '../../resources/modal-warning/modal-warning.component';
import { ModalErrorComponent } from '../../resources/modal-error/modal-error.component';

import { ErrorService } from '../../services/error/error.service';
import { LogService } from '../../services/log/log.service';
import { ModalAlertTextoComponent } from '../../resources/modal-alert-texto/modal-alert-texto.component';

const VERSION = '2.0.0';

@Injectable()
export class AlertService {
  private promise;
  private subscription: Subscription = null;

  constructor(private modalService: NgbModal, private modalConfig: NgbModalConfig, private log: LogService) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  version(): string {
    return VERSION;
  }

  showModal(template: any) {
    this.log.debug('showModal()');
    // const modalRef = this.modalService.open(template
  }

  showMessage(title: string, message: string) {
    this.log.debug('showMessage(' + title + '.' + message + ')');
    const modalRef = this.modalService.open(ModalAlertComponent);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    this.addInClassWindow();
  }

  showMessagePdf(title: string, message: string) {
    this.log.debug('showMessagePdf(' + title + '.' + message + ')');
    const modalRef = this.modalService.open(ModalAlertTextoComponent);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    this.addInClassWindow();
    setTimeout(() => {
      let box = <HTMLElement> document.getElementsByClassName('modal-dialog')[0];
      if (box != null) {
        box.style.width = '99%';
        box.style.margin = '6px auto';
      }
      box = <HTMLElement> document.getElementsByClassName('modal-header')[0];
      if (box != null) {
        box.style.border = '1px solid #aaa';
        box.style.backgroundColor = '#005DA8';
        box.style.color = '#fff';
      }
      box = <HTMLElement> document.getElementsByClassName('modal-content')[0];
      if (box != null) {
        box.style.border = '1px solid #000';
      }
      box = <HTMLElement> document.getElementsByClassName('close')[0];
      if (box != null) {
        box.style.opacity = '0.9';
        box.style.color = '#fff';
        box.style.marginTop = '0';
      }
      box = <HTMLElement> document.getElementsByClassName('ng2-pdf-viewer-container')[0];
      if (box != null) {
        box.style.height = '73%';
      }
    }, 600 );
  }

  showConfirm(title: string, message: string, confirm: any = null, deny: any = null) {
    this.log.debug('showConfirm(' + title + '.' + message + ')');
    const modalRef = this.modalService.open(ModalConfirmComponent);
    modalRef.result.then((result) => {
      if (confirm) {
        confirm(result);
      }
    }, (reason) => {
      if (deny) {
        deny(reason);
      }
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    this.addInClassWindow();
  }

  addInClassWindow() {
    setTimeout(() => {
      const mw = document.getElementsByTagName('ngb-modal-window')[0];
      mw.classList.add('in');
    }, 250 );
  }

  showInput(title: string, tipo: string, message: string, lblInput: string = null, inputValue: string = null, confirm: any = null, deny: any = null) {
    this.log.debug('showInput(' + title + ',' + message + ',' + lblInput + ',' + inputValue + ')');
    const modalRef = this.modalService.open(ModalInputComponent);
    modalRef.result.then((result) => {
      if (confirm) {
        confirm(result);
      }
    }, (reason) => {
      if (deny) {
        deny(reason);
      }
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.tipo = tipo;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.lblInput = lblInput;
    modalRef.componentInstance.inputValue = inputValue;
    this.addInClassWindow();
  }

  showInputMove(title: string, tipo: string, message: string, lblInput: string = null, lista: any[] = null, confirm: any = null, deny: any = null) {
    this.log.debug('showInputMove(' + title + ',' + message + ',' + lblInput + ',' + lista + ')');
    const modalRef = this.modalService.open(ModalInputMoveComponent);
    modalRef.result.then((result) => {
      if (confirm) {
        confirm(result);
      }
    }, (reason) => {
      if (deny) {
        deny(reason);
      }
    });
    modalRef.componentInstance.required = true;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.tipo = tipo;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.lblInput = lblInput;
    modalRef.componentInstance.lista = lista;
    this.addInClassWindow();
  }

  showInputRequired(title: string, message: string, inputValue: string = null, confirm: any = null, deny: any = null) {
    this.log.debug('showInputRequired(' + title + ',' + message + '.' + inputValue + ')');
    const modalRef = this.modalService.open(ModalInputComponent);
    modalRef.result.then((result) => {
      if (confirm) {
        confirm(result);
      }
    }, (reason) => {
      if (deny) {
        deny(reason);
      }
    });
    modalRef.componentInstance.required = true;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.inputValue = inputValue;
    this.addInClassWindow();
  }

  showWarning(title: string, message: string, delay: number = 2000, timeout: number = 5000) {
    this.log.debug('showWarning(' + title + '.' + message + ')');

    const timer = TimerObservable.create(delay, timeout);
    let modalRef = null;

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = timer.subscribe(t => {
      this.log.debug('showWarning.delay');
      if (modalRef == null) {
        modalRef = this.modalService.open(ModalWarningComponent);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
      } else {
        modalRef.close();
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
    });

  }
  hideWarning() {
    this.log.debug('hideWarning()');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showError(title: string, errorService: ErrorService = null) {
    this.log.debug('showError(' + title + ')');

    const modalRef = this.modalService.open(ModalErrorComponent);
    if (errorService != null) {
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = errorService.getMessage();
      modalRef.componentInstance.cause = errorService.getCause();
      modalRef.componentInstance.stack = errorService.getStack();
    } else {
      modalRef.componentInstance.title = 'Atenção';
      modalRef.componentInstance.message = title;
    }
  }

  showErrorMessage(title: string, message: string, cause: string = null, stack: any = null) {
    this.log.debug('showError(' + title + ')');

    const modalRef = this.modalService.open(ModalErrorComponent);
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.cause = cause;
      modalRef.componentInstance.stack = stack;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showMessageConfirm(title: string, message: string, confirm?: any) {
    this.log.debug('showMessage(' + title + '.' + message + ')');
    const modalRef = this.modalService.open(ModalAlertComponent);
    modalRef.result.then((result) => {
      if (confirm) {
        confirm(result);
      }
    }, () => {
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    this.addInClassWindow();
  }
}
