import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Observable } from 'rxjs/Observable';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { Download } from './../../download';

declare const $: any;

@Component({
  selector: 'app-arquivos',
  templateUrl: './arquivos.component.html',
  styleUrls: ['./arquivos.component.css']
})
export class ArquivosComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService,
    private http: HttpClient, private actroute: ActivatedRoute) {
    //
  }

  currentview = CurrentView;
  myForm: FormGroup;
  tblData = [];
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  filtrotbl = null;
  fulltbl2Data = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  exibirTabela = false;
  exibirTabelaFiltro = false;
  perfil = null;
  classificacoes = [];
  situacaoLegislacao = [];
  pastaCompleta = '/sicnl';
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  idUsuario = null;
  caminho = '';
  idFavoritoPath = null;
  idItemFavoritoPath = null;
  isLegislacao = false;
  minDate: moment.Moment;
  minDateEnd: moment.Moment;
  pageSelected = 0;
  hidePages = false;
  pastaRaiz = Config.PASTA_RAIZ;
  acessoExclusaoArq = false;
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;
  observacao = null;
  p: number;
  download$: Observable<Download>;

  ngOnInit() {
    this.log.info('ArquivosComponent.init()');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.p = 1;
    this.myForm = this.formBuilder.group({
      id: null,
      pasta: '/sicnl',
      palavra: null,
      nomeArq: null,
      descricao: null,
      chave: null,
      classificacao: null,
      dtInicial: null,
      dtFinal: null,
      sitLegislacao: null,
      dtInicialRevogacao: null,
      dtFinalRevogacao: null
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.perfil = this.appService.getPerfilUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.observacao = null;
    this.popularCampos();
    moment.locale('pt-br');
    this.minDate = moment(new Date());
    this.atualizarData();
    this.pastaRaiz = this.appService.getPerfilPastaRaiz();
    const prms = this.actroute.snapshot.params;
    if (prms.searchBoxTop) {
      this.pesquisar(prms.searchBoxTop);
    } else {
      if (prms.pasta) {
        this.myForm.get('pasta').setValue(prms.pasta);
      } else {
        this.myForm.get('pasta').setValue(this.pastaRaiz);
      }
      this.listar(this.myForm.get('pasta').value);
    }
    this.pageSelected = 0;
    this.situacaoLegislacao = [];
    this.situacaoLegislacao.push({'id': null, 'sit': 'Todas'});
    this.situacaoLegislacao.push({'id': 'V' , 'sit': 'Vigente'});
    this.situacaoLegislacao.push({'id': 'R' , 'sit': 'Revogada'});
    this.acessoInclusao = this.appService.temAutorizacao('arquivos', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('arquivos', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('arquivos', 'E');
  }

  atualizarData() {
    // this.minDateEnd = this.myForm.get('dtInicial') ? this.myForm.get('dtInicial').value == null ? this.minDate : moment(this.myForm.get('dtInicial').value) : this.minDate
    this.minDateEnd = this.minDate;
    if (this.myForm.get('dtInicial') && this.myForm.get('dtInicial').value !== null) {
      this.minDateEnd = moment(this.myForm.get('dtInicial').value);
    }
  }

  listar(pastaParam) {
    const vm = this;
    vm.myForm.get('pasta').setValue(pastaParam);
    vm.p = 1;
    vm.isLegislacao = pastaParam.indexOf(Config.PASTA_LEGISLACAO) === 0;
    const params = new HttpParams().append('pasta', vm.myForm.get('pasta').value);
    this.webservice.read('ws/arquivos', params, success, fail);

    function success(res) {
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.pastaCompleta = pastaParam;
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          let tam = '';
          if (element.tamanho > 999 && element.tamanho <= 999999) {
            tam = (Math.floor(element.tamanho / 1024)) + 'KB';
          } else if (element.tamanho > 999999 && element.tamanho <= 999999999) {
            tam = (Math.floor(element.tamanho / 1000000)) + 'MB';
          } else if (element.tamanho > 999999999) {
            tam = (Math.floor(element.tamanho / 1000000000)) + 'GB';
          }
          vm.tblData.push({
            'id': element.idPastaBd,
            'idPastaFavorita': element.idPastaFavoritaBd,
            'caminho': element.path,
            'tipo': element.tipoDocumento,
            'nome': element.nome,
            'tamanho': tam,
            'dataAtualiza': element.dataAtualizacaoFormatada,
            'dataCriacao': element.dataCriacaoFormatada,
            'dataPublicacao': element.dataPublicacaoFormatada,
            'numeroLegislacao': element.numeroLegislacao,
            'dataRevogacao': element.dataRevogacaoFormatada,
            'tipoBusca': element.tipoBusca,
            'favorito': element.favorito,
            'podeIncluir': element.podeIncluir,
            'podeExcluir': element.podeExcluir,
            'podeRenomear': element.podeRenomear,
            'podeMover': element.podeMover,
            'podeBaixar': element.podeBaixar,
            'notificacao': element.notificacao,
            'descricao': element.descricao
          });
        });
        vm.acessoExclusaoArq = vm.tblData.length > 0 && vm.tblData.filter(d => d.podeExcluir).length > 0;
      }
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.exibirTabela = true;
      vm.exibirTabelaFiltro = false;
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao listar Arquivos.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    const vm = this;
    vm.pastaCompleta = '/sicnl';
    vm.classificacoes = [];
    vm.myForm.patchValue({
      id: null,
      pasta: '/sicnl',
      palavra: null,
      nomeArq: null,
      descricao: null,
      chave: null,
      classificacao: null,
      dtInicial: null,
      dtFinal: null,
      sitLegislacao: null,
      dtInicialRevogacao: null,
      dtFinalRevogacao: null
    });
    vm.p = 1;
    vm.popularCampos();
    vm.myForm.get('pasta').setValue(this.pastaRaiz);
    vm.exibirTabela = false;
    vm.exibirTabelaFiltro = false;
    vm.acessoExclusaoArq = false;
    vm.listar(this.myForm.get('pasta').value);
  }

  incluir(row) {
    row.caminho = row.caminho + '/' + row.nome;
    this.appService.openPage('cadastrarArquivo', row);
  }

  alterar(row) {
    this.appService.openPage('cadastrarArquivo', row);
  }

  renomear(row) {
    const vm = this;
    const tipo = vm.getTipoDocumento(row);
    const arqOrigem = row.caminho + '/' + row.nome;
    vm.alertService.showInput('Renomear ' + tipo, tipo, arqOrigem, 'Mudar o Nome para:', '', confirm, denied );

    function confirm(res) {
      if ( res && res != null && res.indexOf('/') >= 0 ) {
        const artigo = row.tipo === 'DIRETORIO' ? 'a' : 'o';
        vm.alertService.showMessage('Aviso', 'Nome d' + artigo + ' ' + tipo + ' Inválido. Informe somente o Nome.');
        return;
      }
      res = res.trim();
      let params = new HttpParams().append('origem', row.caminho + '/' + row.nome);
      params = params.append('destino', row.caminho + '/' + res);
      params = params.append('operacao', '1');
      vm.webservice.update('ws/arquivos', null, params, success, fail);
    }

    function denied() {
      //
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao renomear pasta ou arquivo.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }

    function success(res) {
      if (res.temErro) {
        vm.alertService.showMessage('Atenção', res.msgsErro[0]);
        return;
      }
      const tp = vm.getTipoDocumento(row);
      const artg = row.tipo === 'DIRETORIO' ? 'a' : 'o';
      vm.alertService.showMessage('Atenção', 'Nome d' + artg + ' ' + tp + ' alterado com sucesso.');
      vm.listar(row.caminho);
    }
  }

  private getTipoDocumento(row: any) {
    let tipo = null;
    if (row.tipo === 'DIRETORIO') {
      tipo = 'Pasta';
    } else {
      tipo = 'Arquivo';
    }
    return tipo;
  }

  mover(row) {
    const vm = this;
    const tipo = vm.getTipoDocumento(row);
    const arqOrigem = row.caminho + '/' + row.nome;
    vm.webservice.read('ws/arquivos/pastas', null, successPastas, failPastas);

    function failPastas() {
      vm.log.info('erro ao tentar listar pastas.');
    }

    function successPastas(res) {
      if (res.temErro) {
        vm.alertService.showMessage('Atenção', res.msgsErro[0]);
        return;
      }
      const pastas = res.data;
      let dest = '';
      vm.alertService.showInputMove('Mover ' + tipo, tipo, arqOrigem, 'Selecione a Pasta de destino:', pastas, confirm, function(){} );

      function confirm(resAux) {
        dest = resAux;
        let params = new HttpParams().append('operacao', '2');
        params = params.append('origem', row.caminho + '/' + row.nome);
        params = params.append('destino', resAux);
        vm.webservice.update('ws/arquivos', null, params, success, fail);
      }

      function fail(xhr, status, err) {
        vm.log.info('mover arquivo/pasta com erro.');
        let message = 'Falha ao renomear pasta ou arquivo.';
        if (status === 403 || status === 400) {
          if (err) {
            message = err;
          } else if (xhr) {
            message = xhr;
          }
        } else if (xhr && xhr.responseText) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response && response.msgsErro && response.msgsErro.length > 0) {
              message = response.msgsErro[0];
            }
          } catch (ignore) {
          }
        }
        vm.log.error(message);
        vm.alertService.showMessage('Erro', message);
      }

      function success(resAux) {
        if (resAux.temErro) {
          vm.alertService.showMessage('Atenção', resAux.msgsErro[0]);
          return;
        }
        const tp = vm.getTipoDocumento(row);
        const artg = row.tipo === 'DIRETORIO' ? 'a' : 'o';
        let msg = tp + ' movid' + artg + ' para a Pasta "' + dest + '" com sucesso.';
        if (resAux.msgsErro && resAux.msgsErro.length > 0 && resAux.msgsErro[0] !== null && resAux.msgsErro[0] !== 'null') {
          msg += ' ' + resAux.msgsErro[0];
        }
        vm.alertService.showMessage('Atenção', msg);
        // vm.voltar(row.caminho, (row.pagina === 'Alt'))
        vm.listar(row.caminho);
      }
    }

  }

  excluir(row) {
    row.tipo = 'PESQUISA';
    this.excluirArquivo(row);
  }

  excluirArquivo(row) {
    const vm = this;
    // this.analytics.trackEvent('ArquivosController', 'excluirArquivo()')
    vm.alertService.showConfirm('Atenção', 'Confirma a exclusão deste item?', confirm, deny);

    function confirm() {
      vm.log.info('Exclusão confirmada. Executando exclusao no backend.');
      let params = new HttpParams().append('caminho', row.caminho);
      params = params.append('nome', row.nome);
      params = params.append('tipo', row.tipo);
      vm.webservice.delete('ws/arquivos/excluir', params, success, fail);
    }

    function deny() {
      vm.log.info('Exclusão cancelada pelo usuário.');
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArquivosController', 'favoritos():error');
      vm.log.info('Exclusão COM ERRO.');
      let message = 'Falha ao excluir arquivo.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 403 || status === 500) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }

    function success(res) {
      // vm.analytics.trackEvent('ArquivosController', 'excluirArquivo():success')
      if (res.temErro) {
        vm.alertService.showMessage('Atenção', res.msgsErro[0]);
        return;
      }
      const desc = row.tipo === 'DIRETORIO' ? 'Pasta' : 'Arquivo';
      const artigo = row.tipo === 'DIRETORIO' ? 'a' : 'o';
      vm.alertService.showMessage('Atenção', desc + ' excluíd' + artigo + ' com sucesso.');
      // vm.voltar(row.caminho, (row.pagina === 'Alt'))
      vm.listar(row.caminho);
    }
  }

  pesquisar(strSearchBoxTop) {
    const vm = this;
    // this.analytics.trackEvent('ArquivosController', 'pesquisar()')

    let params = new HttpParams();
    if (strSearchBoxTop) {
      params = params.append('nome', strSearchBoxTop);
      params = params.append('descricao', strSearchBoxTop);
      params = params.append('palavra', strSearchBoxTop);
      params = params.append('classif', null);
    } else {
      const dini = vm.myForm.get('dtInicial').value;
      // dini = !dini || dini == null ? null : moment(dini).format('DD/MM/YYYY')
      const dfim = vm.myForm.get('dtFinal').value;
      // dfim = !dfim || dfim == null ? null : moment(dfim).format('DD/MM/YYYY')
      const diniRevogacao = vm.myForm.get('dtInicialRevogacao').value;
      const dfimRevogacao = vm.myForm.get('dtFinalRevogacao').value;

      params = params.append('nome', vm.myForm.get('nomeArq').value);
      params = params.append('descricao', vm.myForm.get('descricao').value);
      params = params.append('palavra', vm.myForm.get('chave').value);
      params = params.append('classif', vm.myForm.get('classificacao').value);
      params = params.append('dtInicial', dini);
      params = params.append('dtFinal', dfim);
      params = params.append('dtInicialRevogacao', diniRevogacao);
      params = params.append('dtFinalRevogacao', dfimRevogacao);
      params = params.append('sitLegislacao', vm.myForm.get('sitLegislacao').value);
    }
    params = params.append('perfil', this.perfil);
    this.webservice.read('ws/arquivos/informacoes', params, success, fail);

    function success(res) {
      // vm.analytics.trackEvent('ArquivosController', 'listar():success')
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'id': element.ident,
            'nome': element.nome,
            'extensao': element.extensao,
            'descricao': element.descricao,
            'palavraChave': element.palavraChave,
            'caminho': element.caminho,
            'situacao': element.situacao,
            'dataInclusao': element.dataInclusao,
            'dataAlteracao': element.dataAlteracao,
            'tipoBusca': element.tipoBusca,
            'pagina': 'Alt',
            'podeAlterar': element.podeAlterar,
            'podeExcluir': element.podeExcluir,
            'podeRenomear': element.podeRenomear,
            'podeMover': element.podeMover,
            'podeBaixar': element.podeBaixar,
            'dataPublicacao': element.dataPublicacao,
            'numeroLegislacao': element.numeroLegislacao,
            'dataRevogacao': element.dataRevogacao,
            'isLegislacao': element.caminho.indexOf(Config.PASTA_LEGISLACAO) === 0,
            'autor': element.autor,
            'categoria': element.categoria
          });
        });
      }
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.exibirTabela = false;
      vm.exibirTabelaFiltro = true;
    }

    function fail(xhr, status, err) {
      // vm.analytics.trackEvent('ArquivosController', 'listar():error')

      let message = 'Falha ao Pesquisar ArquivosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  doExplorar(tipo, nome, caminho, podeBaixar) {
    this.pageSelected = 0;
    if (tipo === 'DIRETORIO') {
      this.listar(caminho + '/' + nome);
    } else if (podeBaixar) {
      this.doExportData(nome, caminho);
    }
  }

  voltar(caminho: any, isUpDir: boolean) {
    let caminhoNovo = caminho;
    if (isUpDir) {
      caminhoNovo = caminhoNovo.split('/', caminho.split('/').length - 1).join('/');
    }
    if (caminhoNovo !== '' && caminho !== this.pastaRaiz) {
      this.listar(caminhoNovo);
    }
  }

  doExportData(nome, caminho) {
    const vm = this;
    this.analytics.trackEvent('ArquivosController', 'doExportData()');
    // console.log( new Date(), 'Download de arquivo')
    let params = new HttpParams().append('path', caminho);
    params = params.append('name', nome);
    vm.webservice.postDownload('ws/arquivos/download', null, 'application/x-download', true, nome, params);
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('ArquivosController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('ArquivosController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArquivosController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito ArquivosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': vm.getCaminhoSemParams(this.caminho), 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  getCaminhoSemParams(path) {
    let res = path;
    if (res == null) {
      res = '';
    } else {
      res = path.split(';')[0];
    }
    return res;
  }

  popularCampos() {
    const vm = this;
    this.webservice.read('ws/arquivos/filtros', null, success, fail);

    function success(res) {
      // vm.analytics.trackEvent('ArquivosController', 'popularCampos():success')
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.classificacoes = [];
      if (res.data && res.data.length > 0) {
        vm.classificacoes.push({
          'sigla': null,
          'nome': 'Todos'
        });
        res.data.forEach((filtros) => {
            vm.classificacoes.push({
              'sigla': filtros.sigla,
              'nome': filtros.nome
            });
        });
      }
      if (res && res.observacao) {
        vm.observacao = res.observacao;
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
    }

    function fail(xhr, status, err) {
      // vm.analytics.trackEvent('ArquivosController', 'popularCampos():error')
      let message = 'Falha ao popular campos ArquivosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': vm.getCaminhoSemParams(this.caminho), 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('ArquivosController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        vm.isFavorito = (op === 'I');
        if (vm.isFavorito) {
          vm.idFavorito = res.idFavorito;
        } else {
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArquivosController', 'favoritos():error');
      let message = 'Falha ao atualizar o caminho como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  doFavoritePath(row) {
    const vm = this;
    if (row.idPastaFavorita == null) {
      const params = {'idItemFavorito': row.nome, 'idUsuario': vm.idUsuario, 'caminho': row.caminho + '/' + row.nome};
      vm.webservice.create('ws/arquivos/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/arquivos/favoritos/' + row.idPastaFavorita, null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('ArquivosController', 'doFavoritePath():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        if (res.operacao === 'I') {
          row.idPastaFavorita = res.idFavorito;
        } else {
          row.idPastaFavorita = null;
        }
        row.favorito = !row.favorito;
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArquivosController', 'doFavoritePath():error');
      let message = 'Falha ao atualizar o caminho como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  doNotification(row) {
    const vm = this;
    const itemNotificacao = {
      'id': row.id,
      'idPasta': row.id,
      'idUsuario': vm.idUsuario,
      'operacao': row.notificacao ? 'E' : 'I',
      'caminhoPasta': row.caminho,
      'nomePasta': row.nome
    };
    this.webservice.create('ws/arquivos/notificacoes', itemNotificacao, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArquivosController', 'doNotification():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        row.notificacao = !row. notificacao;
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArquivosController', 'doNotification():fail');
      let message = 'Falha ao marcar pasta para notificar Usuário.';
      if (status === 404) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  goToLink(link: string) {
    window.open(link, '_self');
  }

  getClassBaixarArquivo(tipo, podeBaixar) {
    let res = '';
    if (tipo === 'DIRETORIO' || podeBaixar) {
      res = 'doc-link';
    }
    return res;
  }
}
