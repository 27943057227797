import { Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';

const VERSION = '2.0.0';
declare const $: any;

@Injectable()
export class FeedbackService {

  button = null;
  messageIcon = '<i class=\'glyphicon glyphicon-cog glyphicon-spin\'></i>&nbsp;';
  messageDefault = 'Processando...';

  constructor(private log: LogService) { }

  version(): string {
    return VERSION;
  }

  loading(event: any, imageOnly: boolean) {
    this.log.debug('Feedback.loading()');
    let target = event;
    if (event == null) {
      return;
    }
    if (event.target) {
      target = event.target;
    }
    this.button = $(target);
    if (this.button.html() === '') {
      this.button = this.button.parent();
    }

    let message = this.button.attr('loading');
    if (message == null) {
        if (this.button.parent().attr('loading')) {
          this.button = this.button.parent();
            message = this.button.attr('loading');
        }

        message = this.messageDefault;
    }

    this.button.previous = this.button.html();
    if (this.button.css('max-width')) {
      this.button.previousmaxwidth = this.button.css('max-width');
    }
    if (this.button.css('min-width')) {
      this.button.previousminwidth = this.button.css('min-width');
    }
    if (this.button.css('overflow')) {
      this.button.previousoverflow = this.button.css('overflow');
    }
    if (this.button.css('text-overflow')) {
      this.button.previoustextoverflow = this.button.css('text-overflow');
    }

    this.button.css('overflow', 'hidden');
    this.button.css('text-overflow', 'ellipsis');
    this.button.css('max-width', this.button.outerWidth());
    this.button.css('min-width', this.button.outerWidth());

    let feedback = this.messageIcon + message;
    if (this.button.outerWidth() < 40) {
        imageOnly = true;
    }

    if (imageOnly) {
      feedback = this.messageIcon;
    }
    this.button.html(feedback);

    this.button.attr('disabled', true);
   }

  reset(enabled: boolean) {
    this.log.debug('Feedback.reset()');
    if (!this.button) {
      return;
    }
    if (this.button.previousoverflow) {
      this.button.css('overflow', this.button.previousoverflow);
    }
    if (this.button.previoustextoverflow) {
      this.button.css('text-overflow', this.button.previoustextoverflow);
    }
    if (this.button.previousmaxwidth) {
      this.button.css('max-width', this.button.previousmaxwidth);
    }
    if (this.button.previousminwidth) {
      this.button.css('min-width', this.button.previousminwidth);
    }
    if (this.button.previous == null) {
      return;
    }
    if (enabled == null) {
      enabled = true;
    }

    this.button.html(this.button.previous);
    this.button.attr('previous', null);
    if (enabled) {
      this.button.attr('disabled', false);
    }
  }

}
