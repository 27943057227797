import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from './../../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-perfilfuncionalidade',
  templateUrl: './perfilfuncionalidade.component.html',
  styleUrls: ['./perfilfuncionalidade.component.css']
})
export class PerfilfuncionalidadeComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl = 50;
  numberPagetbl = [];
  numberPagetblSelected = 0;
  perfilContent = [];
  perfilSelecionado = null;
  perfilSelecionadoID = 'null';
  perfilUsuario = null;
  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  p: number;

  ngOnInit() {
    this.log.info('PerfilFuncionalidadeComponent.init()');
    this.analytics.trackEvent('controller', 'PerfilFuncionalidadeController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      perfil: [null]
    });
    this.p = 1;
    this.idUsuario = this.appService.getIdUsuario();
    this.perfilUsuario = this.appService.getPerfilUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.listarPerfis();
  }

  datatblReset() {
    this.numberPagetblSelected = 0;
    this.numberPagetbl = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl));
  }

  getPlatforms(platforms) {
    return '';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('PerfilFuncionalidadeController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito PerfilFuncionalidadeController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'favoritos():error');
      let message = 'Falha ao atualizar como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listarPerfis() {
    const vm = this;
    vm.analytics.trackEvent('PerfilFuncionalidadeController', 'listarPerfis()');
    vm.p = 1;

    function success(res) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'listarPerfis():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.perfilContent = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.perfilContent.push({
            'sigla': e.sigla.trim(),
            'descricao': e.descricao.trim()
          });
        });
        vm.perfilContent.sort((a, b) => a.descricao.localeCompare(b.descricao) );
        if ( vm.perfilContent.length >= 1 ) {
          vm.myForm.get('perfil').patchValue( vm.perfilContent[0].sigla );
          setTimeout(function() { vm.trocarPerfil(); }, 250);
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'listarPerfis():success');
      let message = 'Falha ao listar os registros.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    vm.webservice.read('ws/perfis', null, success, fail);
  }

  trocarPerfil() {
    const vm = this;
    this.analytics.trackEvent('PerfilFuncionalidadeController', 'trocarPerfil()');
    vm.perfilSelecionadoID = this.appService.getSelectedIndexValue('perfil');
    vm.perfilSelecionado = this.appService.getSelectedIndexText('perfil');
    if ( vm.perfilSelecionadoID === 'null' ) {
      vm.limpar();
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'trocarPerfil():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }

      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.tblData.push({
            'coPerfilUsuario': vm.perfilSelecionadoID,
            'idFuncionalidade': e.idFuncionalidade,
            'funcionalidade': e.descricaoFuncionalidade !== null ? e.descricaoFuncionalidade.trim() : 'ERRO AO BUSCAR FUNCIONALIDADE',
            'funcionalidadeResumo': e.resumoFuncionalidade !== null ? e.resumoFuncionalidade.trim() : 'ERRO AO BUSCAR RESUMO DA FUNCIONALIDADE',
            'funcionalidadeCaminho': e.caminhoFuncionalidade !== null ? e.caminhoFuncionalidade.trim() : 'ERRO AO BUSCAR CAMINHO DA FUNCIONALIDADE',
            'consulta': ( e.id !== null ) ? 'checked' : '',
            'inclusao': ( e.icPerfilInclusao !== null && e.icPerfilInclusao !== 'N') ? 'checked' : '',
            'alteracao': ( e.icPerfilAlteracao !== null && e.icPerfilAlteracao !== 'N') ? 'checked' : '',
            'exclusao': ( e.icPerfilExclusao !== null && e.icPerfilExclusao !== 'N') ? 'checked' : ''
          });
        });
      }

      vm.numberPagetbl = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl));
      vm.numberPagetblSelected = 0;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'trocarPerfil():success');
      let message = 'Falha ao listar as Funcionalidades associadas ao Perfil selecionada.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    const params = new HttpParams().append('siglaPerfil', vm.perfilSelecionadoID);
    vm.webservice.read('ws/perfilfuncionalidades', params, success, fail);
  }

  manterAssociacao(mTipo: string, event: Event, row: any) {
    const vm = this;
    vm.analytics.trackEvent('PerfilFuncionalidadeController', 'manterAssociacao(\'' + mTipo + '\')');
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    const mStatusConsulta = row.consulta === 'checked';
    const mIdFuncionalidade = row.idFuncionalidade;
    const mFuncionalidadeNome = row.funcionalidade;
    const mValor = element.checked;
    let msg: any = '';
    if ( mTipo !== 'C' && !mStatusConsulta ) {
      msg = 'É preciso permitir ' + this.appService.getVerboCRUD('c', true) + ' o registro antes de habilitar a opção de ' + this.appService.getVerboCRUD(mTipo, true) + '.';
      vm.log.info(msg);
      vm.alertService.showMessage('Alerta', msg);
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'manterAssociacao(\'' + mTipo + '\'):success');
      if (res.temErro) {
        msg = 'Ocorreu um erro ao manter a associação da Perfil X Funcionalidade - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.tblData.forEach((e, i) => {
          if (e.idFuncionalidade === row.idFuncionalidade) {
            const BtC = vm.appService.booleanToChecked(mValor);
            switch ( mTipo ) {
              case 'C' :
                if ( mValor ) {
                  vm.tblData[i].consulta = BtC;
                } else {
                  const BtCFalse = vm.appService.booleanToChecked(false);
                  vm.tblData[i].consulta = BtCFalse;
                  vm.tblData[i].inclusao = BtCFalse;
                  vm.tblData[i].alteracao = BtCFalse;
                  vm.tblData[i].exclusao = BtCFalse;
                }
                break;
              case 'I' : vm.tblData[i].inclusao = BtC; break;
              case 'A' : vm.tblData[i].alteracao = BtC; break;
              case 'E' : vm.tblData[i].exclusao = BtC; break;
            }
          }
        });
        msg = 'A ' +  (!mValor ? 'des' : '') + 'associação da opção "' + vm.appService.getVerboCRUD(mTipo, true) + '" da funcionalidade "' + row.funcionalidade + '" com o perfil "' + vm.perfilSelecionado + '" foi realizada com sucesso.';
        vm.log.info(msg);
        vm.alertService.showMessage('Sucesso', msg);
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PerfilFuncionalidadeController', 'manterAssociacao(\'' + mTipo + '\'):fail');
      let message = 'Falha ao manter a associação da Perfil X Funcionalidade';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
    }

    const params = {
        'idPerfilUsuario': vm.perfilSelecionadoID,
        'perfilUsuarioNome': vm.perfilSelecionado,
        'idFuncionalidade': mIdFuncionalidade,
        'funcionalidadeNome': mFuncionalidadeNome,
        'tipo': mTipo,
        'valor': mValor};

    vm.webservice.create('ws/perfilfuncionalidades', JSON.stringify( params ), success, fail);
  }

  limpar() {
    const vm = this;
    vm.myForm.patchValue({
      perfil: null
    });
    vm.tblData = [];
    vm.p = 1;
  }
}
