import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.css']
})
export class AuditoriaComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  funcionalidade = [];
  operacao = [];
  perfis = [];
  horaInicial = [];
  horaFinal = [];
  exibirTabela = false;
  dataAtual = new Date();

  hasCentral = Config.CENTRAL_ID !== '[null]';

  timer = null;
  dtPosicao: '';

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;

  caminho = '';
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  p: number;

  ngOnInit() {
    this.log.info('AuditoriaComponent.init()');
    this.analytics.trackEvent('controller', 'AuditoriaController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      dataInicial: null,
      dataFinal: null,
      horaInicial: null,
      horaFinal: null,
      ip: null,
      cpf: null,
      nome: null,
      perfil: null,
      funcionalidade: null,
      operacao: null
    });
    this.p = 1;
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.popularCampos();
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  isListarParamsInvalidos(parUm, parDois, parTres, parQuatro) {
    return ((parUm == null || parUm === '')
      && (parDois == null || parDois === '')
      && (parTres == null || parTres === '')
      && (parQuatro == null || parQuatro === ''));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('AuditoriaController', 'listar()');
    const dtini = vm.myForm.get('dataInicial').value;
    const dtfim = vm.myForm.get('dataFinal').value;
    const nip = vm.myForm.get('ip').value;
    const ncpf = vm.myForm.get('cpf').value;
    const nome = vm.myForm.get('nome').value;
    const perfil = vm.myForm.get('perfil').value;
    const func = vm.myForm.get('funcionalidade').value;
    const oper = vm.myForm.get('operacao').value;
    if (this.isListarParamsInvalidos(dtini, dtfim, nip, ncpf) && this.isListarParamsInvalidos(nome, perfil, func, oper)) {
      vm.alertService.showMessage('Atenção', 'Favor informar pelo menos um conjunto de campos válido.');
      return;
    }
    vm.p = 1;
    let params = new HttpParams().append('dataInicial', vm.myForm.get('dataInicial').value);
    params = params.append('horaInicial', vm.myForm.get('horaInicial').value);
    params = params.append('dataFinal', vm.myForm.get('dataFinal').value);
    params = params.append('horaFinal', vm.myForm.get('horaFinal').value);
    params = params.append('ip', vm.myForm.get('ip').value);
    params = params.append('cpf', vm.myForm.get('cpf').value);
    params = params.append('nome', vm.myForm.get('nome').value);
    params = params.append('perfil', vm.myForm.get('perfil').value);
    params = params.append('funcionalidade', vm.myForm.get('funcionalidade').value);
    params = params.append('operacao', vm.myForm.get('operacao').value);
    this.webservice.read('ws/auditorias/auditoria', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('AuditoriaController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'id': element.id,
            'cpf': element.cpf,
            'nome': element.nome,
            'perfil': element.perfil,
            'dataHora': element.dataHora,
            'ip': element.ip,
            'funcionalidade': element.funcionalidade,
            'operacao': element.operacao,
            'detalhe': element.detalhe,
            'dadosAnt': element.dadosAnt,
            'dadosAtual': element.dadosAtual
          });
          vm.dataAtual = new Date();
        });
      }
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('AuditoriaController', 'listar():error');
      let message = 'Falha ao listar AuditoriaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  formataPerfil(perfil) {
    if (perfil === 'N') {
      return 'Usuário Comum';
    }
    if (perfil === 'M') {
      return 'Ministério do Desenvolvimento Regional';
    }
    if (perfil === 'S') {
      return 'Gestor';
    }
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      dataInicial: null,
      dataFinal: null,
      horaInicial: null,
      horaFinal: null,
      ip: null,
      cpf: null,
      nome: null,
      perfil: null,
      funcionalidade: null,
      operacao: null
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.p = 1;
  }

  popularCampos() {
    this.exibirTabela = false;
    const vm = this;
    this.analytics.trackEvent('AuditoriaController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('AuditoriaController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.funcionalidade = [];
      vm.operacao = [];
      vm.perfis = [];
      if (res.data && res.data.length > 0) {
        vm.funcionalidade.push({
          'funcionalidade': ''
        });
        vm.perfis.push({
          'id': '',
          'descricao': 'Todos'
        });
        vm.operacao.push({
          'operacao': ''
        });
        vm.horaInicial.push({
          'horaInicial': null
        });
        vm.horaFinal.push({
          'horaFinal': null
        });
        res.data.forEach((filtros) => {
          filtros.funcionalidade.forEach((dataFunc) => {
            vm.funcionalidade.push({
              'funcionalidade': dataFunc.funcionalidade
            });
          });
          filtros.perfis.forEach((filperfil) => {
              vm.perfis.push({
              'id': filperfil.sigla,
              'descricao': filperfil.descricao
            });
          });
          filtros.operacao.forEach((dataOper) => {
            vm.operacao.push({
              'operacao': dataOper.codigoOperacao
            });
          });
          filtros.horaInicial.forEach((dataHora) => {
            vm.horaInicial.push({
              'horaInicial': dataHora.horaInicial
            });
          });
          filtros.horaFinal.forEach((dataHoraF) => {
            vm.horaFinal.push({
              'horaFinal': dataHoraF.horaFinal
            });
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('AuditoriaController', 'popularCampos():error');
      let message = 'Falha ao popular campos AuditoriaController';
      if (status === 404) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }

    this.webservice.read('ws/auditorias/auditoria/filtros', null, success, fail);
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  carregaperiodo(per) {
    this.myForm.patchValue({
      periodoInicial: null,
      periodoFinal: null,
    });
    this.myForm.get('periodo').setValue(per);
    this.popularCampos();
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('AuditoriaController', 'imprimir()');
    let params = new HttpParams().append('dataInicial', vm.myForm.get('dataInicial').value);
    params = params.append('horaInicial', vm.myForm.get('horaInicial').value);
    params = params.append('dataFinal', vm.myForm.get('dataFinal').value);
    params = params.append('horaFinal', vm.myForm.get('horaFinal').value);
    params = params.append('ip', vm.myForm.get('ip').value);
    params = params.append('cpf', vm.myForm.get('cpf').value);
    params = params.append('nome', vm.myForm.get('nome').value);
    params = params.append('perfil', vm.myForm.get('perfil').value);
    params = params.append('funcionalidade', vm.myForm.get('funcionalidade').value);
    params = params.append('operacao', vm.myForm.get('operacao').value);

    this.webservice.read('ws/auditorias/imprimirauditoria', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('AuditoriaController', 'imprimir():success');
      const filename = 'Auditoria.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('AuditoriaController', 'exportar()');
    let params = new HttpParams().append('dataInicial', vm.myForm.get('dataInicial').value);
    params = params.append('horaInicial', vm.myForm.get('horaInicial').value);
    params = params.append('dataFinal', vm.myForm.get('dataFinal').value);
    params = params.append('horaFinal', vm.myForm.get('horaFinal').value);
    params = params.append('ip', vm.myForm.get('ip').value);
    params = params.append('cpf', vm.myForm.get('cpf').value);
    params = params.append('nome', vm.myForm.get('nome').value);
    params = params.append('perfil', vm.myForm.get('perfil').value);
    params = params.append('funcionalidade', vm.myForm.get('funcionalidade').value);
    params = params.append('operacao', vm.myForm.get('operacao').value);
    this.webservice.read('ws/auditorias/exportarauditoria', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('AuditoriaController', 'exportar():success');
      const filename = 'Auditoria.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('AuditoriaController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('AuditoriaController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('AuditoriaController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito AuditoriaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('AuditoriaController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        // let msg = null
        if (op === 'I') {
          // msg = 'Funcionalidade foi marcada como favorita.'
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          // msg = 'Funcionalidade foi desmarcada como favorita.'
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('AuditoriaController', 'favoritos():error');
      let message = 'Falha ao atualizar o auditoria como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }
}
