import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../../environments/config';
import { CurrentView } from '../../../../environments/currentview';
import { AlertService } from '../../../services/alert/alert.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../services/application/application.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { LogService } from '../../../services/log/log.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { NativeInterfaceService } from './../../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-inteligenciapub',
  templateUrl: './inteligenciapub.component.html',
  styleUrls: ['./inteligenciapub.component.css',
              '../../../../assets/css/publico/publico.css']
})
export class InteligenciaPubComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private nativeInterface: NativeInterfaceService, private translate: TranslateService) {
    translate.setDefaultLang('pt');
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tblData = [];
  tblData2 = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  categorias = [];
  series = [];
  exibirTabela = false;
  exibirTabela2 = false;
  dataAtual = new Date();
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  dtPosicao: '';
  descricao: '';
  contextualizacao: '';
  fonte: '';
  gestor: '';
  publicacao: '';
  rotulogrupo: '';
  rotulo1: '';
  rotulo2: '';
  rotulo3: '';
  rotulo4: '';
  rotulo5: '';
  unidademedida1: '';
  unidademedida2: '';
  unidademedida3: '';
  unidademedida4: '';
  unidademedida5: '';
  qtddecimais1: '';
  qtddecimais2: '';
  qtddecimais3: '';
  qtddecimais4: '';
  qtddecimais5: '';
  total1: '';
  total2: '';
  total3: '';
  total4: '';
  total5: '';

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  multSelDropdownSettingsGroupBy = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    groupBy: 'category',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  categoriasSelected = [];
  seriesSelected = [];

  ngOnInit() {
    this.log.info('InteligenciaPubComponent.init()');
    this.analytics.trackEvent('controller', 'InteligenciaPubController');

    const element = <HTMLElement> document.getElementsByClassName('theme-app')[0];
    element.style.backgroundColor = '#FFF';

    this.currentview.locked = false;
    this.currentview.menu = true;

    this.myForm = this.formBuilder.group({
      id: null,
      termo: null,
      mesinic: null,
      anoinic: null,
      mesfinal: null,
      anofinal: null,
      coCategoria: null,
      coSerie: null,
      categoria: [ null ],
      serie: [ null ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ]
    });
    this.popularCampos();
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  isParamsMesInicioInvalidos(mes, ano) {
    let res = null;
    if (mes != null) {
      if (mes < 1 || mes > 12) {
        res = 'Informe o mês inicial entre 1 e 12';
      } else if (ano == null) {
        res = 'Informe o ano inicial.';
      }
    }
    return res;
  }

  isParamsAnoInicioInvalidos(mes, ano) {
    let res = null;
    if (ano != null) {
      if (ano.length < 4) {
        res = 'Informe o ano inicial com 4 posições.';
      } else if (mes == null) {
        res = 'Informe o mês inicial, compreendido entre 1 e 12.';
      }
    }
    return res;
  }

  isParamsMesFinalInvalidos(mes, ano) {
    let res = null;
    if (mes != null) {
      if (mes < 1 || mes > 12) {
        res = 'Informe o mês final entre 1 e 12';
      } else if (ano == null) {
        res = 'Informe o ano final.';
      }
    }
    return res;
  }

  isParamsAnoFinalInvalidos(mes, ano) {
    let res = null;
    if (ano != null) {
      if (ano.length < 4) {
        res = 'Informe o ano final com 4 posições.';
      } else if (mes == null) {
        res = 'Informe o mês final, compreendido entre 1 e 12.';
      }
    }
    return res;
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('InteligenciaPubController', 'listar()');
    let msg = this.isParamsMesInicioInvalidos(vm.myForm.get('mesinic').value, vm.myForm.get('anoinic').value);
    if (msg == null) {
      msg = this.isParamsAnoInicioInvalidos(vm.myForm.get('mesinic').value, vm.myForm.get('anoinic').value);
    }
    if (msg == null) {
      msg = this.isParamsMesFinalInvalidos(vm.myForm.get('mesfinal').value, vm.myForm.get('anofinal').value);
    }
    if (msg == null) {
      msg = this.isParamsAnoFinalInvalidos(vm.myForm.get('mesfinal').value, vm.myForm.get('anofinal').value);
    }
    if (msg !== null) {
      vm.alertService.showMessage('Atenção', msg);
      return;
    }
    let params = new HttpParams().append('termo', vm.myForm.get('termo').value);
    params = params.append('mesinic', vm.myForm.get('mesinic').value);
    params = params.append('anoinic', vm.myForm.get('anoinic').value);
    params = params.append('mesfinal', vm.myForm.get('mesfinal').value);
    params = params.append('anofinal', vm.myForm.get('anofinal').value);
    const body = {
      'categoria': vm.appService.getLista(vm.categoriasSelected, 'id'),
      'serie': vm.appService.getLista(vm.seriesSelected, 'id')
    };
    vm.webservice.create('ws/publico/inteligencia', body, success, fail, params);

    function success(res) {
      vm.analytics.trackEvent('InteligenciaPubController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'id': element.id,
            'descricao': element.descricao,
            'data': element.data,
            'tipo': element.tipo,
            'serie': element.serie,
          });
          vm.dataAtual = new Date();
        });
      }

      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      vm.exibirTabela2 = false;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InteligenciaPubController', 'listar():error');

      let message = 'Falha ao listar InteligenciaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      termo: null,
      coCategoria: '',
      coSerie: '',
      categoria: null,
      serie: null,
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      mesinic: null,
      anoinic: null,
      mesfinal: null,
      anofinal: null
    });
    this.tblData = [];
    this.tblData2 = [];
    this.exibirTabela = false;
    this.exibirTabela2 = false;
  }

  popularCampos() {
    this.exibirTabela = false;
    this.exibirTabela2 = false;
    const vm = this;
    this.analytics.trackEvent('InteligenciaPubController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('InteligenciaPubController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.categorias = [];
      vm.categoriasSelected = [];
      vm.series = [];
      vm.seriesSelected = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((filtros) => {
          filtros.categorias.forEach((dataCat) => {
            vm.categorias.push({
              'id': dataCat.coCategoria,
              'itemName': dataCat.deCategoria
            });
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InteligenciaPubController', 'popularCampos():error');

      let message = 'Falha ao popular campos InteligenciaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    const params = new HttpParams().append('categoria', vm.myForm.get('categoria').value);
    this.webservice.read('ws/publico/inteligencia/filtros', params, success, fail);
  }

  limpaTabela() {
    this.exibirTabela = false;
    this.exibirTabela2 = false;
  }

  pesquisar(serie) {
    const vm = this;
    this.analytics.trackEvent('InteligenciaPubController', 'pesquisar()');

    function success(res) {
      vm.analytics.trackEvent('InteligenciaPubController', 'pesquisar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        vm.descricao = res.relatorio.descricao;
        vm.contextualizacao = res.relatorio.contextualizacao;
        vm.fonte = res.relatorio.fonte;
        vm.gestor = res.relatorio.gestor;
        vm.publicacao = res.relatorio.publicacao;
        vm.rotulogrupo = res.relatorio.rotulogrupo;
        vm.rotulo1 = res.relatorio.rotulo1;
        vm.rotulo2 = res.relatorio.rotulo2;
        vm.rotulo3 = res.relatorio.rotulo3;
        vm.rotulo4 = res.relatorio.rotulo4;
        vm.rotulo5 = res.relatorio.rotulo5;
        vm.unidademedida1 = res.relatorio.unidademedida1;
        vm.unidademedida2 = res.relatorio.unidademedida2;
        vm.unidademedida3 = res.relatorio.unidademedida3;
        vm.unidademedida4 = res.relatorio.unidademedida4;
        vm.unidademedida5 = res.relatorio.unidademedida5;
        vm.qtddecimais1 = res.relatorio.qtddecimais1;
        vm.qtddecimais2 = res.relatorio.qtddecimais2;
        vm.qtddecimais3 = res.relatorio.qtddecimais3;
        vm.qtddecimais4 = res.relatorio.qtddecimais4;
        vm.qtddecimais5 = res.relatorio.qtddecimais5;
        vm.total1 = res.total.total1;
        vm.total2 = res.total.total2;
        vm.total3 = res.total.total3;
        vm.total4 = res.total.total4;
        vm.total5 = res.total.total5;
        vm.dataAtual = new Date();
        res.data.forEach((element) => {
          vm.tblData2.push({
            'id': element.codigo,
            'ParamMes': element.anomes.substring(4, 6) + '/' + element.anomes.substring(0, 4),
            'grupo': element.grupo,
            'valor1': element.valor1,
            'valor2': element.valor2,
            'valor3': element.valor3,
            'valor4': element.valor4,
            'valor5': element.valor5,
            'valorbin': element.valorbin,
          });
        });
      }

      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData2.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = false;
      vm.exibirTabela2 = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InteligenciaPubController', 'pesquisar():error');

      let message = 'Falha ao listar InteligenciaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    const params = new HttpParams().append('serie', serie);
    this.webservice.read('ws/publico/inteligenciaresultado', params, success, fail);
  }

  onItemSelect(item: any) {
    const vm = this;
    const arrayList: Array<string> = vm.categoriasSelected.map(o => o.id);
    const params = new HttpParams().append('categoria', JSON.stringify(arrayList));
    this.webservice.read('ws/publico/inteligencia/series', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('InteligenciaController', 'onItemSelect():success');
      if (res == null) {
        vm.series = [];
        return;
      }
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.series = [];
      if (res.data && res.data.length > 0 && res.data[0].series  && res.data[0].series.length > 0) {
        res.data[0].series.forEach((ser) => {
          vm.series.push({
            'id': ser.coSerie,
            'itemName': ser.deSerie,
            'category': ser.categoria
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InteligenciaController', 'onItemSelect():fail');
      let message = 'Falha ao carregar as series de categoria.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }
  OnItemDeSelect(item: any) {
    const vm = this;
    const ms = [...vm.seriesSelected];
    vm.seriesSelected.forEach((ser, i) => {
      if (ser.category === item.itemName) {
        ms.splice(i, 1);
      }
    });
    vm.seriesSelected = [];
    ms.forEach((ser, i) => {
      vm.seriesSelected.push({
        'id': ser.id,
        'itemName': ser.itemName,
        'category': ser.category
      });
    });
    this.onItemSelect(null);
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
    this.seriesSelected = [];
    this.series = [];
  }

  onItemSelectSer(item: any) {
    //
  }
  OnItemDeSelectSer(item: any) {
    //
  }
  onSelectAllSer(items: any) {
    //
  }
  onDeSelectAllSer(items: any) {
    //
  }

  getLista(arraylist, atrib) {
    let res = null;
    let arrayStr: Array<string> = null;
    if (arraylist != null) {
      if (atrib === 'id') {
        arrayStr = arraylist.map(o => o.id.trim());
      } else {
        arrayStr = arraylist.map(o => o.itemName.trim());
      }
    }
    if (arrayStr !== null && arrayStr.length > 0) {
      res = JSON.stringify(arrayStr);
      res = res.replace('[', '').replace(']', '');
    }
    return res;
  }
}
