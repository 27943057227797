import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { FormValidations } from '../../resources/form-validations';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';

@Component({
  selector: 'app-cadastrarusuario',
  templateUrl: './cadastrarusuario.component.html',
  styleUrls: ['./cadastrarusuario.component.css']
})
export class CadastrarusuarioComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private actroute: ActivatedRoute) {
    //
  }

  cpf = null;
  perfil = null;
  currentview = CurrentView;
  myForm: FormGroup;
  myOldForm: FormGroup;
  hasChanged = false;
  idUsuario = null;
  inclusao = false;
  perfilContent = [];
  orgaoContent = [];
  listacadastroContent = [];
  status = 'A';
  trilha = 'S';
  hasCentral = Config.CENTRAL_ID !== '[null]';
  ufs = [
    {'nome': 'Acre', 'sigla': 'AC'},
    {'nome': 'Alagoas', 'sigla': 'AL'},
    {'nome': 'Amapá', 'sigla': 'AP'},
    {'nome': 'Amazonas', 'sigla': 'AM'},
    {'nome': 'Bahia', 'sigla': 'BA'},
    {'nome': 'Ceará', 'sigla': 'CE'},
    {'nome': 'Distrito Federal', 'sigla': 'DF'},
    {'nome': 'Espírito Santo', 'sigla': 'ES'},
    {'nome': 'Goiás', 'sigla': 'GO'},
    {'nome': 'Maranhão', 'sigla': 'MA'},
    {'nome': 'Mato Grosso', 'sigla': 'MT'},
    {'nome': 'Mato Grosso do Sul', 'sigla': 'MS'},
    {'nome': 'Minas Gerais', 'sigla': 'MG'},
    {'nome': 'Pará', 'sigla': 'PA'},
    {'nome': 'Paraíba', 'sigla': 'PB'},
    {'nome': 'Paraná', 'sigla': 'PR'},
    {'nome': 'Pernambuco', 'sigla': 'PE'},
    {'nome': 'Piauí', 'sigla': 'PI'},
    {'nome': 'Rio de Janeiro', 'sigla': 'RJ'},
    {'nome': 'Rio Grande do Norte', 'sigla': 'RN'},
    {'nome': 'Rio Grande do Sul', 'sigla': 'RS'},
    {'nome': 'Rondônia', 'sigla': 'RO'},
    {'nome': 'Roraima', 'sigla': 'RR'},
    {'nome': 'Santa Catarina', 'sigla': 'SC'},
    {'nome': 'São Paulo', 'sigla': 'SP'},
    {'nome': 'Sergipe', 'sigla': 'SE'},
    {'nome': 'Tocantins', 'sigla': 'TO'}
  ];
  timer = null;
  acessoCadastro = false;
  acessoPesquisa = false;
  acessoInclusao = false;
  acessoAlteracao = false;
  trocaSenha = false;
  tituloFormularioUsuario = '';
  editarCampo = false;

  ngOnInit() {
    this.log.info('CadastrarusuarioComponent.init()');
    this.analytics.trackEvent('controller', 'CadastrarusuarioController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      perfil: [ null , [ Validators.required ]],
      dePerfil: [ null , [ Validators.required ]],
      nome: [ null , [ Validators.required ]],
      orgao: [ null , [ Validators.required ]],
      telefone: [ null , [ Validators.required ]],
      senha: [ null , [ Validators.required ]],
      confirmeasenha: [ null , [ Validators.required ]],
      cpf: [ null , [ FormValidations.cpfValidator, Validators.required ]],
      uf: [ null , [ Validators.required ]],
      email: [ null , [ Validators.required ]],
      status: [ null , [ Validators.required ]],
      trilha: [ null , [ Validators.required ]],
      id: null,
      dtAssinaturaTermo: null
    });
    this.myOldForm = this.formBuilder.group({
      perfil: [ null , [ Validators.required ]],
      dePerfil: [ null , [ Validators.required ]],
      nome: [ null , [ Validators.required ]],
      orgao: [ null , [ Validators.required ]],
      telefone: [ null , [ Validators.required ]],
      senha: [ null , [ Validators.required ]],
      confirmeasenha: [ null , [ Validators.required ]],
      cpf: [ null , [ FormValidations.cpfValidator, Validators.required ]],
      uf: [ null , [ Validators.required ]],
      email: [ null , [ Validators.required ]],
      status: [ null , [ Validators.required ]],
      trilha: [ null , [ Validators.required ]],
      id: null,
      dtAssinaturaTermo: null
    });
    this.perfil = this.appService.getPerfilUsuario();
    this.cpf = this.appService.getCpfUsuario();
    this.acessoCadastro = this.appService.temAutorizacao('cadastrarUsuario');
    this.acessoPesquisa = this.appService.temAutorizacao('pesquisarUsuario');
    this.acessoInclusao = this.appService.temAutorizacao('pesquisarUsuario', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('pesquisarUsuario', 'A');

    // Obter o id do usuario selecionado na listagem da tela de consulta usuarios.
    this.idUsuario = this.actroute.snapshot.params['id'];
    let prms = this.actroute.snapshot.params;
    const acao = this.actroute.snapshot.params['acao'];
    if (!this.idUsuario && this.cpf && typeof acao === 'undefined') {
      prms = {cpf: this.cpf};
    }
    // Exemplos:
    // 1) Perfil Administrador tem acesso full à manutenção usuario. Título "Inclusão de Usuário" ou "Alteração de Usuário".
    // 2) Perfil Auditor tem acesso à manutenção de Usuário, porém, ele só pode consultar os dados. Título: "Consulta Usuário";
    // 3) Perfil Comum tem acesso somente consulta à tela de cadastro . Titulo "Troca de Senha";
    this.trocaSenha = false;
    if (this.acessoCadastro && typeof prms.id === 'undefined' && typeof acao === 'undefined') {
      this.tituloFormularioUsuario = 'Troca de Senha';
      this.trocaSenha = true;
    } else if (this.acessoInclusao && this.idUsuario == null) {
      this.tituloFormularioUsuario = 'Inclusão de Usuário';
    } else if (this.acessoAlteracao && this.idUsuario != null) {
      this.tituloFormularioUsuario = 'Alteração de Usuário';
    } else {
      this.tituloFormularioUsuario = 'Consulta Usuário';
    }
    this.editarCampo = !this.trocaSenha && ((this.acessoInclusao && acao === 'I') || (this.acessoAlteracao && typeof acao === 'undefined'));
    this.populaDadosForm(prms);
    this.onValueChanges();
    this.hasChanged = this.myForm.get('id').value == null;
  }

  limpar() {
    this.myForm.patchValue({
      perfil: null,
      dePerfil: null,
      nome: null,
      orgao: null,
      telefone: null,
      cpf: null,
      uf: null,
      email: null,
      status: 'A',
      trilha: 'S',
      senha: null,
      confirmeasenha: null,
      dtAssinaturaTermo: null
    });
    this.myOldForm.patchValue({
      perfil: null,
      dePerfil: null,
      nome: null,
      orgao: null,
      telefone: null,
      cpf: null,
      uf: null,
      email: null,
      status: 'A',
      trilha: 'S',
      senha: null,
      confirmeasenha: null,
      dtAssinaturaTermo: null
    });
  }

  salvar() {
    const vm = this;
    this.analytics.trackEvent('CadastrarusuarioController', 'inclusao()');

    function success(res) {
      vm.analytics.trackEvent('CadastrarusuarioController', 'inclusao():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        let oper = null;
        if (vm.inclusao) {
          oper = 'incluído';
        } else {
          oper = 'alterado';
        }
        vm.alertService.showMessage('Atenção', 'Usuário ' + oper + ' com sucesso.');
        if (vm.inclusao) {
          vm.limpar();
        } else {
          vm.hasChanged = false;
        }
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao ';
      if (vm.inclusao) {
        message = message + 'incluir ';
      } else {
        message = message + 'alterar ';
      }
      message = message + 'Usuário.';
      if (status === 403 || status === 500) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }

    if (!this.acessoCadastro && (this.acessoAlteracao || this.acessoInclusao)) {
      const cpfval = this.myForm.get('cpf').value;
      if (!cpfval || cpfval.trim() === '') {
        vm.alertService.showMessage('Atenção', 'O campo CPF deve ser informado.');
      return;
      }
      const perfilval = this.myForm.get('perfil').value;
      if (!perfilval || perfilval.trim() === '') {
        vm.alertService.showMessage('Atenção', 'O campo Perfil deve ser informado.');
        return;
      }
      const nomeval = this.myForm.get('nome').value;
      if (!nomeval || nomeval.trim() === '') {
        vm.alertService.showMessage('Atenção', 'O campo Nome deve ser informado.');
        return;
      }
      const ufval = this.myForm.get('uf').value;
      if (!ufval || ufval.trim() === '') {
        vm.alertService.showMessage('Atenção', 'O campo UF deve ser informado.');
        return;
      }
      const orgaoval = this.myForm.get('orgao').value;
      if (!orgaoval || orgaoval.trim() === '') {
        vm.alertService.showMessage('Atenção', 'O campo Órgão deve ser informado.');
        return;
      }
      const emailval = this.myForm.get('email').value;
      if (!emailval || emailval.trim() === '') {
        vm.alertService.showMessage('Atenção', 'O campo E-mail deve ser informado.');
        return;
      }
    }
    if (!this.validarSenha()) {
      vm.alertService.showMessage('Atenção', 'A senha deve ter no mínimo seis e no máximo doze posições.');
      return;
    }
    if (!this.confirmarSenha()) {
      vm.alertService.showMessage('Atenção', 'Os campos Senha e Confirme a Senha devem ser iguais.');
      return;
    }

    if (vm.inclusao) {
      vm.webservice.create('ws/usuarios', vm.myForm.value, success, fail);
    } else {
      let trocaSenhaParam = 'N';
      if (this.trocaSenha) {
        trocaSenhaParam = 'S';
      }
      const params = new HttpParams().append('trocaSenha', trocaSenhaParam);
      vm.webservice.update('ws/usuarios', vm.myForm.value, params, success, fail);
    }
  }

  populaDadosForm(dados) {
    const vm = this;
    vm.inclusao = dados && dados.id == null && !vm.trocaSenha;
    vm.popularCampos();
    if (vm.trocaSenha) {
      // usuario nao gestor deve popular dados do formulario e permitir editar
      // somente os campos senha e confirme senha.
      vm.getUsuarioNaoGestor();
      return;
    }
    let sit = null;
    if (vm.inclusao) {
      if (typeof dados.acao === 'undefined') {
        dados.cpf = null;
      }
      sit = 'A';
    } else {
      sit = dados.status === 'Ativo' ? 'A' : 'I';
    }
    vm.myForm.patchValue({
      perfil: dados.perfil,
      dePerfil: dados.dePerfil,
      nome: dados.nome,
      orgao: dados.orgao,
      telefone: dados.telefone == null ? null : dados.telefone.replace(/\s/g, ''),
      cpf: dados.cpf,
      uf: dados.uf,
      email: dados.email,
      status: sit,
      trilha: !dados || dados.trilha == null || dados.trilha === undefined ? 'S' : dados.idTrilha,
      id: dados.id,
      dtAssinaturaTermo: dados.dataAssinaturaTermo === '' ? 'Termo atual inexistente ou ainda não assinado' : dados.dataAssinaturaTermo,
    });
    vm.myOldForm.patchValue({
      perfil: dados.perfil,
      dePerfil: dados.dePerfil,
      nome: dados.nome,
      orgao: dados.orgao,
      telefone: dados.telefone == null ? null : dados.telefone.replace(/\s/g, ''),
      cpf: dados.cpf,
      uf: dados.uf,
      email: dados.email,
      status: sit,
      trilha: !dados || dados.trilha == null || dados.trilha === undefined ? 'S' : dados.idTrilha,
      id: dados.id,
      dtAssinaturaTermo: dados.dataAssinaturaTermo === '' ? 'Termo atual inexistente ou ainda não assinado' : dados.dataAssinaturaTermo,
    });
  }

  validarSenha(): boolean {
    const vm = this;
    let res = false;
    const senha = this.getSenha(vm.myForm.get('senha'));
    if (vm.inclusao) {
      res = senha.length >= 6 && senha.length <= 12;
    } else {
      res = (senha.length === 0 && vm.acessoInclusao) || (senha.length >= 6 && senha.length <= 12);
    }
    return res;
  }

  private getSenha(objSenha) {
    let res = '';
    if (objSenha && objSenha.value != null) {
      res = objSenha.value.trim();
    }
    return res;
  }

  confirmarSenha(): boolean {
    let res = false;
    // const senha = this.myForm.get('senha') ??? this.myForm.get('senha').value == null ? '' : this.myForm.get('senha').value : ''
    const senha = this.getSenha(this.myForm.get('senha'));
    // const confsenha = this.myForm.get('confirmeasenha') ??? this.myForm.get('confirmeasenha').value == null ? ''
    //                   : this.myForm.get('confirmeasenha').value.trim() : ''
    const confsenha = this.getSenha(this.myForm.get('confirmeasenha'));
    res = (senha === confsenha);
    return res;
  }

  getUsuarioNaoGestor() {
    const vm = this;
    const params = new HttpParams().append('cpf', vm.cpf);
    vm.webservice.read('ws/usuarios', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('CadastrarusuarioController', 'getUsuarioNaoGestor():success');
      if (res && res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          const st = element && element.status && element.status === 'Ativo' ? 'A' : 'I';
          vm.myForm.patchValue({
            perfil: element.perfil,
            dePerfil: element.dePerfil,
            nome: element.nome,
            orgao: element.orgao,
            telefone: element.telefone,
            cpf: element.cpf,
            uf: element.uf,
            email: element.email,
            status: st,
            trilha: !element || element.trilha == null || element.trilha === undefined ? 'S' : element.idTrilha,
            id: element.id,
            dtAssinaturaTermo: element.dataAssinaturaTermo === '' ? 'Termo atual inexistente ou ainda não assinado' : element.dataAssinaturaTermo,
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('CadastrarusuarioController', 'getUsuarioNaoGestor():error');
      let message = 'Falha ao consultar dados CadastrarusuarioController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400 && err) {
        message = err;
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('CadastrarusuarioController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('CadastrarusuarioController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.perfilContent = [];
      vm.orgaoContent = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((f) => {
          f.perfil.forEach((p) => {
            vm.perfilContent.push({
              'sigla': p.sigla,
              'descricao': p.descricao
            });
          });
          f.orgao.forEach((p) => {
            vm.orgaoContent.push({
              'codigo': p.codigo,
              'descricao': p.descricao
            });
          });

        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('CadastrarusuarioController', 'popularCampos():error');
      let message = 'Falha ao popular campos CadastrarusuarioController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    this.webservice.read('ws/usuarios/filtrar', null, success, fail);
  }

  editarCpf() {
    const res = this.acessoInclusao && (this.myForm.get('id').value == null || this.myForm.get('id').value === '');
    return res;
  }

  onValueChanges(): void {
    const vm = this;
    if (this.myForm.get('id').value !== null) {
      this.myForm.valueChanges.subscribe(val => {
        if (val.telefone && val.telefone !== null) {
          val.telefone = val.telefone.replace('(', '');
          val.telefone = val.telefone.replace(')', '');
          val.telefone = val.telefone.replace(' ', '');
          val.telefone = val.telefone.replace('-', '');
        }
        if (val.senha === '') {
          val.senha = null;
        }
        if (val.confirmeasenha === '') {
          val.confirmeasenha = null;
        }
        vm.hasChanged = !_.isEqual(val, vm.myOldForm.value);
      });
    }
  }
}
