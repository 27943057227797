import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatSort, Sort } from '@angular/material';

declare const $: any;

@Component({
  selector: 'app-execucaoorcamentariapub',
  templateUrl: './execucaoorcamentariapub.component.html',
  styleUrls: ['./execucaoorcamentariapub.component.css',
              '../../../../assets/css/publico/publico.css']
})
export class ExecucaoorcamentariaPubComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private translate: TranslateService) {
    translate.setDefaultLang('pt');
  }

  currentview = CurrentView;
  myForm: FormGroup;
  criterio = '7';
  tbl = ['Tipo', 'Nome', 'Número', 'Data', 'Assunto', 'Observação'];
  tblData = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 50;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  periodos = [];
  regioes = [];
  ufs = [];
  areasaplicacao = [];
  programas = [];
  exibirTabela = false;
  dataAtual = new Date();
  totOrcamentoOriginal = null;
  totOrcamentoFinal = null;
  totEmprestimoAgentesFinanceiros = null;
  totRealizado = null;
  totPercEmprestado = null;
  totSaldo = null;
  totPercRealizado = null;
  totSaldoContratar = null;
  totPercContratar = null;
  totPercRealizadoOrcFinal = null;
  ordemPeriodoResultado = 'C';
  colSpanRes = 0;
  dtPosicao = '';
  detalheUf = false;
  detalhePrograma = false;
  detalheArea = false;
  periodoRes = null;
  periodoInicialRes = null;
  periodoFinalRes = null;
  percEmprestado = null;
  saldo = null;
  orcamentoOriginal = null;
  orcamentoFinal = null;
  emprestimoAgentesFinanceiros = null;
  realizado = null;
  percRealizado = null;
  saldoContratar = null;
  percContratar = null;
  percRealizadoOrcamentoFinal = null;

  bolDetRegiao = false;
  bolDetUF = false;
  bolDetArea = false;
  bolDetPrograma = false;

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  multSelDropdownSettingsGroupBy = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    groupBy: 'category',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  regioesSelected = [];
  ufsSelected = [];
  areasSelected = [];
  programasSelected = [];
  observacao = null;
  public p: number;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.log.info('ExecucaoorcamentariaPubComponent.init()');
    this.analytics.trackEvent('controller', 'ExecucaoorcamentariaPubController');

    // const element = <HTMLElement> document.getElementsByClassName('theme-app')[0];
    // element.style.backgroundColor = '#FFF';

    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      regiao: [ null ],
      uf: [ null ],
      areaaplicacao: [ null ],
      programa: [ null ],
      compromisso: [ null ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      detalheRegiao: false,
      detalheUf: [ false ],
      detalhePrograma: [ false ],
      detalheArea: [ false ],
      ordemPeriodoResultado: 'C'
    });

    this.popularCampos();
    this.p = 1;
    this.ordemPeriodoResultado = 'asc';

    const vm = this;
    $(document).ready(function() {
      $('#regiao').on('change', function(){
        if ($(this).val().trim() === '0: null') {
          $('input[name=detalheRegiao]').removeAttr('disabled');
        } else {
          $('input[name=detalheRegiao]').prop('checked', false).removeAttr('checked');
          $('span .checkmark').prop('disabled', 'disabled');
        }
      });
      $('#uf').on('change', function(){
        if ($(this).val().trim() === '0: null') {
          $('input[name=detalheUf]').removeAttr('disabled');
        } else {
          $('input[name=detalheUf]').prop('checked', false).removeAttr('checked');
          $('span .checkmark').prop('disabled', 'disabled');
        }
      });
      $('#areaaplicacao').on('change', function(){
        if ($(this).val().trim() === '0: null') {
          $('input[name=detalheArea]').removeAttr('disabled');
        } else {
          $('input[name=detalheArea]').prop('checked', false).removeAttr('checked');
          $('span .checkmark').prop('disabled', 'disabled');
        }
      });
      $('#programa').on('change', function(){
        if ($(this).val().trim() === '0: null') {
          $('input[name=detalhePrograma]').removeAttr('disabled');
        } else {
          this.bolDetPrograma = false;
          $('input[name=detalhePrograma]').prop('checked', false).removeAttr('checked');
          $('span .checkmark').prop('disabled', 'disabled');
        }
      });
      $('#detalheUf').on('change', function(){
        if ($(this).is(':checked')) {
          $('#uf').prop('selectedIndex', 0);
        }
      });
      $('#detalheArea').on('change', function(){
        if ($(this).is(':checked')) {
          $('#areaaplicacao').prop('selectedIndex', 0);
        }
      });
      $('#detalhePrograma').on('change', function(){
        if ($(this).is(':checked')) {
          $('#programa').prop('selectedIndex', 0);
        }
      });
      $('#optDetMarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(true);
          vm.limpaTabela();
        });
      });
      $('#optDetDesmarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(false);
          vm.limpaTabela();
        });
      });

    });
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ExecucaoorcamentariaPubController', 'listar()');

    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if ( parseInt( vm.myForm.get('periodoInicial').value, 10) > parseInt( vm.myForm.get('periodoFinal').value, 10) ) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;

    vm.periodoRes = vm.appService.getSelectedIndexText('periodoInicial') + ' a ' + vm.appService.getSelectedIndexText('periodoFinal');
    vm.bolDetRegiao = vm.appService.getInputCheckboxValue('detalheRegiao');
    vm.bolDetUF = vm.appService.getInputCheckboxValue('detalheUf');
    vm.bolDetArea = vm.appService.getInputCheckboxValue('detalheArea');
    vm.bolDetPrograma = vm.appService.getInputCheckboxValue('detalhePrograma');

    let params = new HttpParams().append('periodoInicial', vm.appService.getSelectedIndexValue('periodoInicial'));
    params = params.append('periodoFinal', vm.appService.getSelectedIndexValue('periodoFinal'));
    params = params.append('detalheRegiao', String(vm.bolDetRegiao));
    params = params.append('detalheUf', String(vm.bolDetUF));
    params = params.append('detalheArea', String(vm.bolDetArea));
    params = params.append('detalhePrograma', String(vm.bolDetPrograma));
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/publico/execucaoorcamentaria', body, success, fail, params);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoorcamentariaPubController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }

      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.orcamentoOriginal = (e.orcamentoOriginal / 1000);
          vm.orcamentoFinal = (e.orcamentoFinal / 1000);
          vm.emprestimoAgentesFinanceiros = (e.emprestimoAgentesFinanceiros / 1000);
          vm.realizado = (e.realizado / 1000);
          vm.percEmprestado = (vm.emprestimoAgentesFinanceiros / vm.orcamentoFinal) * 100;
          vm.percEmprestado = Number.isNaN(vm.percEmprestado) ? 0 : vm.percEmprestado;
          vm.saldo = (vm.orcamentoFinal - vm.emprestimoAgentesFinanceiros);
          vm.saldo = Number.isNaN(vm.saldo) ? 0 : vm.saldo;
          vm.percRealizado = (vm.realizado / vm.emprestimoAgentesFinanceiros) * 100;
          vm.percRealizado = Number.isNaN(vm.percRealizado) ? 0 : vm.percRealizado;
          vm.saldoContratar = (vm.emprestimoAgentesFinanceiros - vm.realizado);
          vm.saldoContratar = Number.isNaN(vm.saldoContratar) ? 0 : vm.saldoContratar;
          vm.percContratar = (vm.saldoContratar / vm.emprestimoAgentesFinanceiros) * 100;
          vm.percContratar = Number.isNaN(vm.percContratar) ? 0 : vm.percContratar;
          vm.percRealizadoOrcamentoFinal = (vm.realizado / vm.orcamentoFinal) * 100;
          vm.percRealizadoOrcamentoFinal = Number.isNaN(vm.percRealizadoOrcamentoFinal) ? 0 : vm.percRealizadoOrcamentoFinal;
          vm.tblData.push({
            'ano': e.ano,
            'regiao': e.regiao,
            'uf': e.uf,
            'area': e.areaAplicacao,
            'programa': e.programa,
            'orcamentoOriginal': vm.orcamentoOriginal,
            'orcamentoFinal': vm.orcamentoFinal,
            'emprestimoAgentesFinanceiros': vm.emprestimoAgentesFinanceiros,
            'percEmprestado': vm.percEmprestado,
            'saldo': vm.saldo,
            'realizado': vm.realizado,
            'percRealizado': vm.percRealizado,
            'saldoContratar': vm.saldoContratar,
            'percContratar': vm.percContratar,
            'percRealizadoOrcamentoFinal': vm.percRealizadoOrcamentoFinal
          });
        });
      }
      vm.totOrcamentoOriginal = res.totOrcamentoOriginal / 1000;
      vm.totOrcamentoFinal = res.totOrcamentoFinal / 1000;
      vm.totEmprestimoAgentesFinanceiros = res.totEmprestimoAgentesFinanceiros / 1000;
      vm.totRealizado = res.totRealizado / 1000;
      vm.totPercEmprestado = (vm.totEmprestimoAgentesFinanceiros / vm.totOrcamentoFinal) * 100;
      vm.totPercEmprestado = Number.isNaN(vm.totPercEmprestado) ? 0 : vm.totPercEmprestado;
      vm.totSaldo = (vm.totOrcamentoFinal - vm.totEmprestimoAgentesFinanceiros);
      vm.totSaldo = Number.isNaN(vm.totSaldo) ? 0 : vm.totSaldo;
      vm.totPercRealizado = (vm.totRealizado / vm.totEmprestimoAgentesFinanceiros) * 100;
      vm.totPercRealizado = Number.isNaN(vm.totPercRealizado) ? 0 : vm.totPercRealizado;
      vm.totSaldoContratar = (vm.totEmprestimoAgentesFinanceiros - vm.totRealizado);
      vm.totSaldoContratar = Number.isNaN(vm.totSaldoContratar) ? 0 : vm.totSaldoContratar;
      vm.totPercContratar = (vm.totSaldoContratar / vm.totEmprestimoAgentesFinanceiros) * 100;
      vm.totPercContratar = Number.isNaN(vm.totPercContratar) ? 0 : vm.totPercContratar;
      vm.totPercRealizadoOrcFinal = (vm.totRealizado / vm.totOrcamentoFinal) * 100;
      vm.totPercRealizadoOrcFinal = Number.isNaN(vm.totPercRealizadoOrcFinal) ? 0 : vm.totPercRealizadoOrcFinal;
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      vm.observacao = res.observacao;
      if (vm.observacao !== null) {
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
      vm.colSpanRes = 1;
      if (vm.appService.getInputCheckboxValue('detalheRegiao') || vm.appService.getInputCheckboxValue('detalheUf')) {
        vm.colSpanRes += 1;
      }
      if (vm.appService.getInputCheckboxValue('detalheUf')) {
        vm.colSpanRes += 1;
      }
      if (vm.appService.getInputCheckboxValue('detalheArea')) {
        vm.colSpanRes += 1;
      }
      if (vm.appService.getInputCheckboxValue('detalhePrograma')) {
        vm.colSpanRes += 1;
      }
      $(document).ready(function() {
        $('.colSpanRes').attr('colspan', vm.colSpanRes);
      });
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ExecucaoorcamentariaPubController', 'listar():error');
      let message = 'Falha ao listar ExecucaoorcamentariaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      uf: null,
      areaaplicacao: null,
      programa: null,
      compromisso: null,
      periodoInicial: '',
      periodoFinal: '',
      detalheRegiao: false,
      detalheUf: false,
      detalhePrograma: false,
      detalheArea: false,
      ordemPeriodoResultado: 'C'
    });
    this.tblData = [];
    this.popularCampos();
    this.exibirTabela = false;
    this.ordemPeriodoResultado = 'asc';
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ExecucaoorcamentariaPubController', 'popularCampos()');
    this.webservice.read('ws/publico/execucaoorcamentaria/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoorcamentariaPubController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      vm.regioes = [];
      vm.regioesSelected = [];
      vm.ufs = [];
      vm.ufsSelected = [];
      vm.programas = [];
      vm.programasSelected = [];
      vm.areasaplicacao = [];
      vm.areasSelected = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((filtros) => {
          vm.periodos.push({
            'periodo': '',
            'periodofmt': 'Escolha o Período'
          });
          filtros.regioes.forEach((dataRegiao) => {
            vm.regioes.push({
              'id': dataRegiao.codigo,
              'itemName': dataRegiao.nome
            });
          });
          filtros.periodosInicial.forEach((dataPer) => {
            vm.periodos.push({
              'periodo': dataPer.periodo,
              'periodofmt': dataPer.periodo
            });
          });
          filtros.areasAplicacao.forEach((dataArea) => {
            vm.areasaplicacao.push({
              'id': dataArea.id,
              'itemName': dataArea.nome
            });
          });
          filtros.programas.forEach((dataProg) => {
            vm.programas.push({
              'id': dataProg.id,
              'itemName': dataProg.nome
            });
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ExecucaoorcamentariaPubController', 'popularCampos():error');

      let message = 'Falha ao popular campos ExecucaoorcamentariaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  limpaTabela() {
    this.exibirTabela = false;
    this.totEmprestimoAgentesFinanceiros = 0;
    this.totOrcamentoFinal = 0;
    this.totOrcamentoOriginal = 0;
    this.totPercContratar = 0;
    this.totPercEmprestado = 0;
    this.totPercRealizado = 0;
    this.totPercRealizadoOrcFinal = 0;
    this.totRealizado = 0;
    this.totSaldo = 0;
    this.totSaldoContratar = 0;
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('ExecucaoorcamentariaPubController', 'imprimir()');

    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if ( parseInt( vm.myForm.get('periodoInicial').value, 10) > parseInt( vm.myForm.get('periodoFinal').value, 10) ) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    vm.bolDetRegiao = vm.appService.getInputCheckboxValue('detalheRegiao');
    vm.bolDetUF = vm.appService.getInputCheckboxValue('detalheUf');
    vm.bolDetArea = vm.appService.getInputCheckboxValue('detalheArea');
    vm.bolDetPrograma = vm.appService.getInputCheckboxValue('detalhePrograma');

    let params = new HttpParams().append('periodoInicial', this.appService.getSelectedIndexValue('periodoInicial'));
    params = params.append('periodoFinal', this.appService.getSelectedIndexValue('periodoFinal'));
    params = params.append('detalheRegiao', String(vm.bolDetRegiao));
    params = params.append('detalheUf', String(vm.bolDetUF));
    params = params.append('detalheArea', String(vm.bolDetArea));
    params = params.append('detalhePrograma', String(vm.bolDetPrograma));
    params = params.append('descArea', vm.getLista(vm.areasSelected, 'itemName'));
    params = params.append('descPrograma', vm.getLista(vm.programasSelected, 'itemName'));
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/publico/imprimirexecorcamentaria', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoorcamentariaPubController', 'imprimir():success');
      const filename = 'ExecucaoOrcamentaria.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      // IE compatibility...
      const b64Data = res.relat.replace(/\s/g, '');
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportarxls()  {
    const vm = this;
    this.analytics.trackEvent('ExecucaoOrcamentariaPubController', 'exportarxls()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalheRegiao').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    params = params.append('detalheArea', vm.myForm.get('detalheArea').value);
    params = params.append('detalhePrograma', vm.myForm.get('detalhePrograma').value);
    params = params.append('descArea', vm.getLista(vm.areasSelected, 'itemName'));
    params = params.append('descPrograma', vm.getLista(vm.programasSelected, 'itemName'));
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/publico/exportarexecorcamentaria', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaPubController', 'exportarxls():success');
      const filename = 'ExecucaoOrcamentaria.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  onItemSelect(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelect(item: any) {
    this.limpaTabela();
  }
  onSelectAll(items: any) {
    this.limpaTabela();
  }
  onDeSelectAll(items: any) {
    this.limpaTabela();
  }

  onItemSelectArea(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectArea(item: any) {
    this.limpaTabela();
  }
  onSelectAllArea(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllArea(items: any) {
    this.limpaTabela();
  }

  onItemSelectPrograma(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectPrograma(item: any) {
    this.limpaTabela();
  }
  onSelectAllPrograma(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllPrograma(items: any) {
    this.limpaTabela();
  }

  getLista(arraylist, atrib) {
    let res = null;

    let arrayStr: Array<string> = null;
    if (arraylist != null) {
      if (atrib === 'id') {
        arrayStr = arraylist.map(o => o.id.trim());
      } else {
        arrayStr = arraylist.map(o => o.itemName.trim());
      }
    }

    if (arrayStr !== null && arrayStr.length > 0) {
      res = JSON.stringify(arrayStr);
      res = res.replace('[', '').replace(']', '');
    }
    return res;
  }

  showLista(arraylist, atrib) {
    let res = '';
    if (arraylist != null && arraylist.length > 0) {
      res = this.getLista(arraylist, atrib).replace(/"/g, '').replace(/,/g, ', ');
    }
    if (res === '') {
      res = 'Todos';
    }
    return res;
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  onItemSelectReg(item: any) {
    const vm = this;
    this.limpaTabela();
    vm.popularUfs(vm.regioesSelected);
  }

  OnItemDeSelectReg(item: any) {
    const vm = this;
    vm.ufsSelected = vm.ufsSelected.filter(u => u.category !== item.itemName);
    vm.onItemSelectReg(null);
    vm.limpaTabela();
  }

  onSelectAllReg(items: any) {
    const vm = this;
    vm.limpaTabela();
    vm.popularUfs(vm.regioes);
  }
  onDeSelectAllReg(items: any) {
    this.ufsSelected = [];
    this.ufs = [];
    this.limpaTabela();
  }

  popularUfs(listaRegiao: any) {
    const vm = this;
    vm.limpaTabela();
    if (listaRegiao == null || listaRegiao.length === 0) {
      vm.ufs = [];
      return;
    }
    const arrayList: Array<string> = listaRegiao.map(o => o.id);
    this.webservice.create('ws/publico/ufs', {'regioes': arrayList}, success, fail);
    function success(res) {
      vm.analytics.trackEvent('DescontosPubController', 'onSelectAllReg():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ufs = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((uf) => {
          vm.ufs.push({
            'id': uf.sigla,
            'itemName': uf.nome,
            'category': uf.regiao
          });
        });
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DescontosPubController', 'onSelectAllReg():fail');
      let message = 'Falha ao carregar as UF`s.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('ExecucaoOrcamentariaPubController', 'exportarCsv()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalheRegiao').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    params = params.append('detalheArea', vm.myForm.get('detalheArea').value);
    params = params.append('detalhePrograma', vm.myForm.get('detalhePrograma').value);
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/publico/exportarexecucaoorcamentariacsv', body, 'text/plain', true, 'execucaoorcamentaria.csv', params);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
