import { Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { ErrorService } from '../../services/error/error.service';
import * as Base64 from 'base-64';
import { WebServiceService } from '../web-service/web-service.service';
import { Config } from '../../../environments/config';

const VERSION = '2.0.0';
const applicationServerPublicKey = 'BB173l8rJtk3Q2PUyoCNHszuZ7EHUZdSRFQFFUwuHQB9X0AmssVKloJZuu6+9L9MnCI6piKFCZuIsyeGz9nWfcc=';
const SENDER_ID = '698680658532';
const WORKER_SOURCE = 'centralcaixaworker.js';

const API_VERSAO = '01.00';
const PUSH_SUBSCRIBE = 'ws/subscribe/create/' + API_VERSAO;
const PUSH_UNSUBSCRIBE = 'ws/subscribe/delete/' + API_VERSAO;
const PUSH_CHECK = 'ws/subscribe/check/' + API_VERSAO;

const EMAIL_SUBSCRIBE = 'ws/email/subscribe/' + API_VERSAO;
const EMAIL_UNSUBSCRIBE = 'ws/email/unsubscribe/' + API_VERSAO;
const EMAIL_CHECK = 'ws/email/checksubscription/' + API_VERSAO;

@Injectable()
export class CentralCaixaService {

  constructor(private log: LogService, private errorService: ErrorService, private webservice: WebServiceService) { }

  swRegistration = null;
  pushService = null;
  supported = false;
  initialized = false;
  date = null;
  subscribed = false;
  initStarting = false;
  initError = false;
  userSubscription = null;
  channelId = null;
  userId = null;
  browserApp = null;
  eventCallback = null;
  eventResponse = null;
  restriction = null;
  mailSubscribed: boolean = null;

  private _cordova() {
    return window['cordova'];
  }
  private _isNative() {
    let result = this._cordova() != null;
    if (!result) {
      result = navigator.userAgent.match(/NativeApp/i) != null;
    }
    return result;
  }

  private isAndroid() {
    return navigator.userAgent.match(/Android/i) != null;
  }

  private isIOS() {
    let found = navigator.userAgent.match(/iPhone/i) != null;
    if (!found) {
      found = navigator.userAgent.match(/iPod/i) != null;
    }
    return found;
  }

  private isTablet() {
    return navigator.userAgent.match(/Tablet|iPad/i);
  }

  private isOtherMobile() {
    let found = navigator.userAgent.match(/webOS/i) != null;
    if (!found) {
      found = navigator.userAgent.match(/BlackBerry/i) != null;
    }
    return found;
  }

  private isWPhone() {
    const found = navigator.userAgent.match(/Windows Phone/i) != null;
    return found;
  }

  private isMobile() {
    return this.isAndroid() || this.isIOS() || this.isWPhone() || this.isOtherMobile();
  }

  private isWindows() {
    return navigator.userAgent.match(/Windows/i) != null;
  }

  private isLinux() {
    return navigator.userAgent.match(/Linux/i) != null;
  }

  private isMac() {
    return navigator.userAgent.match(/Mac/i) != null;
  }

  private isChrome() {
    return navigator.userAgent.match(/Chrome/i) != null;
  }

  private isIE() {
    return navigator.userAgent.match(/MSIE/i) != null;
  }

  private isEdge() {
    return navigator.userAgent.match(/Edge/i) != null;
  }

  private isFirefox() {
    return navigator.userAgent.match(/firefox/i) != null;
  }

  private isSafari() {
    return navigator.userAgent.match(/safari/i) != null;
  }

  private isOpera() {
    return navigator.userAgent.match(/Opera/i) != null;
  }

  private urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private getDevice() {
    if (this.isMobile()) {
      return 'DEVICE_MOBILE';
    } else if (this.isTablet()) {
      return 'DEVICE_TABLET';
    }
    return 'DEVICE_DESKTOP';
  }

  private getSO() {
    if (this.isAndroid()) {
      return 'ANDROID_OS';
    } else if (this.isIOS()) {
      return 'IOS_OS';
    } else if (this.isWPhone()) {
      return 'WINPHONE_OS';
    } else if (this.isOtherMobile()) {
      return 'OTHER_OS';
    } else if (this.isWindows()) {
      return 'WINDOWS_OS';
    } else if (this.isLinux()) {
      return 'LINUX_OS';
    } else if (this.isMac()) {
      return 'MAC_OS';
    } else {
      return 'UNKNOWN_OS';
    }
  }

  private getNavigator() {
    if (this.isChrome()) {
      return 'NAV_CHROME';
    } else if (this.isIE()) {
      return 'NAV_EXPLORER';
    } else if (this.isEdge()) {
      return 'NAV_EDGE';
    } else if (this.isFirefox()) {
      return 'NAV_FIREFOX';
    } else if (this.isSafari()) {
      return 'NAV_SAFARI';
    } else if (this.isOpera()) {
      return 'NAV_OPERA';
    } else {
      return 'NAV_UNKNOWN';
    }
  }

  private getPlatform() {
    if (this.isMobile() || this.isTablet()) {
      if (this._isNative()) {
        return 'NATIVE_PLATFORM';
      } else {
        return 'BROWSER_PLATFORM';
      }
    } else {
      return 'BROWSER_PLATFORM';
    }
  }

  private setRestriction() {
    let restriction = null;
    if (this.isMobile()) {
      restriction = 'DEVICE_MOBILE';
    } else if (this.isTablet()) {
      restriction = 'DEVICE_TABLET';
    } else {
      restriction = 'DEVICE_DESKTOP';
    }
    if (this.isAndroid()) {
      restriction += ' ANDROID_OS';
    } else if (this.isIOS()) {
      restriction += ' IOS_OS';
    } else if (this.isWPhone()) {
      restriction += ' WINPHONE_OS';
    } else if (this.isOtherMobile()) {
      restriction += ' OTHER_OS';
    } else if (this.isWindows()) {
      restriction += ' WINDOWS_OS';
    } else if (this.isLinux()) {
      restriction += ' LINUX_OS';
    } else if (this.isMac()) {
      restriction += ' MAC_OS';
    }

    if (this.isMobile() || this.isTablet()) {
      if (this._isNative()) {
        restriction += ' NATIVE_PLATFORM';
      } else {
        restriction += ' BROWSER_PLATFORM';
      }
    } else {
      restriction += ' BROWSER_PLATFORM';
    }

    if (this.isChrome()) {
      restriction += ' NAV_CHROME';
    } else if (this.isIE()) {
      restriction += ' NAV_EXPLORER';
    } else if (this.isEdge()) {
      restriction += ' NAV_EDGE';
    } else if (this.isFirefox()) {
      restriction += ' NAV_FIREFOX';
    } else if (this.isSafari()) {
      restriction += ' NAV_SAFARI';
    } else if (this.isOpera()) {
      restriction += ' NAV_OPERA';
    }
    return restriction;
  }

  private PushNotification() {
    return window['this.PushNotification()'];
  }

  private post(endpoint, data, onsuccess, onfail) {
    const vm = this;

    function success(res) {
      if (onsuccess) {
        onsuccess(res);
      }
    }
    function fail(xhr, message) {
      if (onfail) {
        onfail(xhr, message);
      }
    }

    vm.webservice.postAnalytics(endpoint, JSON.stringify(data), null, success, fail);
  }

  public isSupported() {
    const vm = this;
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      vm.supported = true;
      vm.browserApp = true;
    } else {
      try {
        if (vm.PushNotification() != null) {
          vm.supported = true;
          vm.browserApp = false;
        } else {
          vm.supported = false;
          vm.browserApp = null;
        }
      } catch (ignore) {
        vm.supported = false;
        vm.browserApp = null;
      }
    }
    if (vm.initError) {
      vm.supported = false;
    }
    return vm.supported;
  }

  public getRegistration(onDone) {
    this.log.debug('getRegistration(onDone)');
    const vm = this;
    if (vm.browserApp) {
      const applicationServerKey = this.urlB64ToUint8Array(applicationServerPublicKey);
      vm.swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      }).then(function (subscription) {
        console.log('User is subscribed:', subscription);
        const hasSubscription = !(subscription === null);
        vm.userSubscription = subscription;
        if (onDone) {
          onDone(hasSubscription, subscription);
        }
      }).catch(function (err) {
        console.log('Failed to subscribe the user: ', err);
        if (onDone) {
          onDone(false, null, err.name);
        }
      });
    } else {
      const hasSubscription = !(vm.userSubscription === null);
      if (onDone) {
        onDone(hasSubscription, vm.userSubscription);
      }
    }
  }

  public loadRegistration(onDone) {
    // this.log.debug('loadRegistration()');
    const vm = this;
    vm.swRegistration.pushManager.getSubscription()
      .then(function (subscription) {
        const hasSubscription = !(subscription === null);
        vm.userSubscription = subscription;
        // vm.log.debug('User has subscription.');
        if (onDone) {
          onDone(hasSubscription, subscription);
        }
      });
  }

  public initialize(channel, user, success, fail) {
    const vm = this;
    // vm.log.debug('initialize()');

    if (!vm.isSupported()) {
      vm.log.warning('Notifications not supported on this platform');
      return;
    }

    if (vm.initStarting) {
      vm.log.warning('Starting up on process...');
      return;
    }

    vm.initStarting = true;
    vm.initError = false;

    vm.channelId = channel;
    vm.userId = user;

    vm.initialized = false;
    vm.subscribed = false;
    vm.userSubscription = null;

    if (this.browserApp) {
      navigator.serviceWorker.register(WORKER_SOURCE)
        .then(function (swReg) {
          // vm.log.debug('initialize().serviceWorker.register.then');
          // vm.log.info('Service Worker is registered ' + swReg);
          vm.swRegistration = swReg;
          vm.initStarting = false;
          vm.initialized = true;

          function onSuccessCheck(data) {
            vm.log.debug('initialize().checkSubscription.onSuccessCheck');
            const response = data;

            if (response.temErro) {
              vm.errorService.handleError('Falha ao verificar assinatura: ' + response.code + ' - ' + response.message, response.stack);
              if (success) {
                success(false);
              }
              return;
            }

            if (response && response.data && response.data.length > 0) {
              vm.subscribed = true;
              data = response.data[0].dtSubscription;
            }

            if (success) {
              success(vm.subscribed);
            }
          }


          function onFailCheck(message) {
            vm.log.debug('initialize().checkSubscription.onFailCheck');
            if (fail) {
              fail(message);
            }
          }

          function onDone(hasSubscription, subscription) {
            if (hasSubscription) {
              vm.checkSubscription(subscription, onSuccessCheck, onFailCheck);
            } else {
              if (success) {
                success(vm.subscribed);
              }
            }
          }

          vm.loadRegistration(onDone);
        })
        .catch(function (error) {
          vm.initError = true;
          vm.initialized = false;
          vm.initStarting = false;
          vm.log.error('Service Worker Error', error);
          if (fail) {
            fail('Falha ao registrar serviço de notificações', error);
          }
        });
    } else if (vm.PushNotification() != null) {
      this.pushService = vm.PushNotification().init({
        android: {
          senderID: SENDER_ID,
          icon: 'icon',
          iconColor: 'white'
        },
        ios: {
          // senderID: SENDER_ID,
          // gcmSandbox:'true',
          alert: 'true',
          badge: 'true',
          sound: 'true'
        },
        windows: {}
      });

      vm.pushService.on('registration', function (data) {
        console.log('Token result:' + data.registrationId);

        vm.initialized = true;
        vm.subscribed = false;
        vm.userSubscription = data.registrationId;
        vm.initStarting = false;

        function onSuccessCheck(dataAux) {
          vm.log.debug('initialize().checkSubscription.onSuccessCheck');

          const response = dataAux;

          if (response.temErro) {
            vm.errorService.handleError('Falha ao verificar assinatura: ' + response.code + ' - ' + response.message, response.stack);
            if (success) {
              success(false);
            }
            return;
          }

          if (response && response.data && response.data.length > 0) {
            vm.subscribed = true;
            data = response.data[0].dtSubscription;
          }

          if (success) {
            success(vm.subscribed);
          }
        }

        function onFailCheck(message) {
          vm.log.debug('initialize().checkSubscription.onFailCheck');
          if (fail) {
            fail(message);
          }
        }

        vm.checkSubscription(vm.userSubscription, onSuccessCheck, onFailCheck);
      });


      vm.pushService.on('notification', function (data) {
        vm.log.debug('cloud:push:notification ' + data);

        try {
          if (!data) {
            vm.log.error('notificação não recebeu dados');
            return;
          }

          vm.log.debug('data: ' + JSON.stringify(data));

          const msg = data.message;

          const push = {
            title: data.title,
            content: data.message,
            image: data.image,
            actions: null,
            url: null
          };

          if (data && data.additionalData) {
            push.url = data.additionalData.url;
            if (data.additionalData.image) {
              push.image = data.additionalData.image;
            }
            if (data.additionalData.actions) {
              push.actions = data.additionalData.actions;
            }
          }

          if (push.image == null) {
            push.image = 'imgs/pin.png';
          }

          vm.log.debug('push: ' + JSON.stringify(push));

          if (vm.eventCallback) {
            vm.eventCallback(push);
          }

          vm.log.debug('cloud:push:notification:DONE');
        } catch (error) {
          vm.log.error('Falha ao processar notificação', error);
          vm.log.error('error ' + error);
        }
      });

      this.pushService.on('error', function (e) {
        vm.log.error('Falha ao processar notificação' + e.message, e);
      });
    } else {
      vm.initStarting = false;
    }

  }

  public updateSubscriptionOnServer(subscription, url, onSuccess, onError) {
    const vm = this;
    vm.log.debug('updateSubscriptionOnServer()');

    const endPoint = url;
    let user = '';

    const device = vm.getDevice();
    const navigator = vm.getNavigator();
    const so = vm.getSO();
    const platform = vm.getPlatform();

    if (vm.userId != null) {
      user = vm.userId;
    }

    const strSubscription = JSON.stringify(subscription);
    const encodeSubscription = Base64.encode(strSubscription);

    const data = {
      subscription: encodeSubscription,
      channel: vm.channelId,
      user: user,
      platform: platform,
      navigator: navigator,
      device: device,
      so: so
    };

    function success(dataAux) {
      vm.log.debug('updateSubscriptionOnServer().success');
      vm.log.debug('success:data: ' + dataAux);
      if (onSuccess) {
        onSuccess(dataAux);
      }
    }

    function error(dataAux, param, value) {
      vm.log.debug('updateSubscriptionOnServer().error');
      vm.log.debug('error:data: ' + dataAux);
      vm.log.debug('error:param: ' + param);
      vm.log.debug('error:value: ' + value);

      if (onError) {
        onError(dataAux, param, value);
      }
    }

    vm.post(endPoint, data, success, error);
  }

  public checkSubscription(subscription, onSuccess, onError) {
    const vm = this;
    this.log.debug('checkSubscription()');

    const strSubscrption = JSON.stringify(subscription);
    const encodeSubscription = Base64.encode(strSubscrption);

    const data = {
      subscription: encodeSubscription,
      channel: vm.channelId
    };

    function success(dataAux) {
      vm.log.debug('checkSubscription().success');
      vm.log.debug('success:data: ' + dataAux);
      if (onSuccess) {
        onSuccess(dataAux);
      }
    }

    function error(dataAux, param, value) {
      vm.log.debug('checkSubscription().error');
      vm.log.debug('error:data: ' + dataAux);
      vm.log.debug('error:param: ' + param);
      vm.log.debug('error:value: ' + value);
      if (onError) {
        onError(dataAux, param, value);
      }
    }

    vm.post(Config.URL_CAIXA_SICPU + PUSH_CHECK, data, success, error);
  }

  public unsubscribe(success, fail) {
    const vm = this;
    vm.log.debug('unsubscribe()');

    if (!vm.isSupported()) {
      vm.log.warning('Notifications not supported on this platform');
      return;
    }

    if (!vm.subscribed) {
      vm.log.warning('Notifications is not subscribed');
      if (success) {
        success();
      }
      return;
    }

    function onsuccess(data) {
      vm.log.debug('unsubscribeUser().success');

      const response = data;

      if (response.temErro) {
        vm.errorService.handleError('Falha ao cancelar assinatura: ' + response.code + ' - ' + response.message, response.stack);
        if (fail) {
          fail(response.code + ' - ' + response.message, response.cause, response.stack);
        }
        return;
      }

      vm.subscribed = false;

      if (success) {
        success();
      }
    }

    function onfail(message) {
      vm.log.debug('unsubscribeUser().onfail');

      if (fail) {
        fail(message);
      }
    }

    vm.updateSubscriptionOnServer(vm.userSubscription, Config.URL_CAIXA_SICPU + PUSH_UNSUBSCRIBE, onsuccess, onfail);
  }

  public testsubscribe(success, fail) {
    const vm = this;
    vm.log.debug('testsubscribe()');

    if (!vm.isSupported()) {
      vm.log.warning('Notifications not supported on this platform');
      return;
    }

    if (vm.subscribed) {
      vm.log.warning('Notifications subscribed already');
      if (success) {
        success(vm.userSubscription);
      }
      return;
    }

    function onDone(hasSubscription, subscription, errorname) {
      vm.log.debug('subscribeUser().getRegistration.onDone');

      if (!hasSubscription) {
        let message = 'Falha ao obter assinatura';
        if (errorname === 'NotAllowedError') {
          message = 'Assinatura bloqueada pelo usuário';
        }

        if (fail) {
          fail(message);
        }

        return;
      }

      if (success) {
        success(vm.userSubscription);
      }
    }

    vm.getRegistration(onDone);
  }

  public subscribe(success, fail) {
    const vm = this;
    vm.log.debug('subscribe()');

    if (!vm.isSupported()) {
      vm.log.warning('Notifications not supported on this platform');
      return;
    }

    if (vm.subscribed) {
      vm.log.warning('Notifications subscribed already');
      if (success) {
        success(vm.userSubscription);
      }
      return;
    }

    function onDone(hasSubscription, subscription, errorname) {
      vm.log.debug('subscribeUser().getRegistration.onDone');

      if (!hasSubscription) {
        let message = 'Falha ao obter assinatura';
        if (errorname === 'NotAllowedError') {
          message = 'Assinatura bloqueada pelo usuário';
        }

        if (fail) {
          fail(message);
        }
        return;
      }

      function onsuccess(data) {
        vm.log.debug('subscribeUser().updateSubscriptionOnServer.onsuccess');

        const response = data;

        if (response.temErro) {
          vm.errorService.handleError('Falha ao efetuar assinatura: ' + response.code + ' - ' + response.message, response.stack);
          if (fail) {
            fail(response.code + ' - ' + response.message, response.cause, response.stack);
          }
          return;
        }

        vm.subscribed = true;

        if (success) {
          success(vm.userSubscription);
        }
      }

      function onfail(message) {
        vm.log.debug('subscribeUser().updateSubscriptionOnServer.onfail');
        if (fail) {
          fail(message);
        }
      }

      vm.updateSubscriptionOnServer(vm.userSubscription, Config.URL_CAIXA_SICPU + PUSH_SUBSCRIBE, onsuccess, onfail);
    }

    vm.getRegistration(onDone);
  }

  public setEvent = function (event) {
    this.log.debug('setEvent()');
    const vm = this;
    vm.eventCallback = event;
  };

  public setResponse = function (response) {
    this.log.debug('setResponse()');
    const vm = this;
    vm.eventResponse = response;
  };

  public isDevMode = function () {
    const vm = this;
    return vm.dev;
  };

  public isDev = function () {
    const vm = this;
    return vm.dev;
  };

  public setDevMode = function (code) {
    const vm = this;
    vm.devCode = code;
    vm.dev = (vm.devCode > 0);
  };
  public isSubscribed = function () {
    const vm = this;
    return vm.subscribed;
  };
  public isInitialized = function () {
    const vm = this;
    return vm.initialized;
  };
  public getSubscription = function () {
    const vm = this;
    return vm.userSubscription;
  };
  public destroy = function () {
    // this.destroy();
  };

  public pushcallback = function (data, action) {
    const vm = this;

    vm.log.debug('pushcallback: ' + data);

    try {
      if (!data) {
        vm.log.error('notificação não recebeu dados');
        return;
      }

      vm.log.debug('data: ' + JSON.stringify(data));

      const msg = data.message;

      const push = {
        title: data.title,
        content: data.message,
        image: data.image,
        action: null,
        actions: null,
        url: null
      };

      if (data && data.additionalData) {
        push.url = data.additionalData.url;
        if (data.additionalData.image) {
          push.image = data.additionalData.image;
        }
        if (data.additionalData.actions) {
          push.actions = data.additionalData.actions;
          push.action = push.actions[action];
        }
      }

      if (push.image == null) {
        push.image = 'imgs/pin.png';
      }

      vm.log.debug('push: ' + JSON.stringify(push));

      if (vm.eventResponse) {
        vm.eventResponse(push);
      }

      vm.log.debug('cloud:push:notification:DONE');
    } catch (error) {
      vm.log.error('Falha ao processar notificação', error);
      vm.log.error('error ' + error);
    }
  };


  subscribeEmail(channel, mail, onsuccess, onfail) {
    const vm = this;
    const request = {
      app: channel,
      users: [{ email: mail }]
    };

    function success(data) {
      if (onsuccess) {
        onsuccess(data);
      }
    }
    function fail(xhr, status, err) {
      let message = 'Falha ao efetuar assinatura de email';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.errorService.handleError(message, err);
      if (onfail) {
        onfail(message, xhr);
      }
    }

    vm.post(Config.URL_CAIXA_SICPU + EMAIL_SUBSCRIBE, JSON.stringify(request), success, fail);
  }

  unsubscribeEmail(channel, mail, onsuccess, onfail) {
    const vm = this;
    const request = {
      app: channel,
      users: [{ email: mail }]
    };

    function success(data) {
      if (onsuccess) {
        onsuccess(data);
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao cancelar assinatura de email';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.errorService.handleError(message, err);
      if (onfail) {
        onfail(message, xhr);
      }
    }

    vm.post(Config.URL_CAIXA_SICPU + EMAIL_UNSUBSCRIBE, JSON.stringify(request), success, fail);

  }

  isSubscribedEmail() {
    return this.mailSubscribed;
  }

  checkSubscribeEmail(channel, mail, onsuccess, onfail) {
    const vm = this;
    const request = {
      app: channel,
      email: mail
    };

    vm.mailSubscribed = null;
    function success(data) {
      const response = data;

      if (response.temErro) {
        vm.errorService.handleError('Falha ao verificar assinatura: ' + response.code + ' - ' + response.message, response.stack);
        if (success) {
          success(false);
        }
        return;
      }

      if (response && response.data && response.data.length > 0) {
        vm.mailSubscribed = response.subscribed;
      }
      if (onsuccess) {
        onsuccess(vm.mailSubscribed);
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao cancelar assinatura de email';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.errorService.handleError(message, err);
      if (onfail) {
        onfail(message, xhr);
      }
    }
    vm.post(Config.URL_CAIXA_SICPU + EMAIL_CHECK, JSON.stringify(request), success, fail);
  }
}
