import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'FilterTablePipe',
    pure: false
})
export class FilterTablePipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return  items.filter(item =>
            Object.keys(item).some(k => item[k] != null &&
            item[k].toString().toLowerCase()
            .includes(filter.toString().toLowerCase()))
            );
    }
}
