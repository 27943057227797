import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CurrentView } from '../../../environments/currentview';
import { ApplicationService } from '../../services/application/application.service';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit {

  currentview = CurrentView;
  timer = null;

  constructor(private log: LogService, private analytics: AnalyticsService,
      public appService: ApplicationService, private alertService: AlertService) {
    this.currentview.menu = false;
    this.currentview.searchbox = false;
  }

  ngOnInit() {
    this.log.info('SplashComponent.init()');
    this.analytics.trackEvent('controller', 'SplashController');
    this.currentview.locked = false;
    this.currentview.menu = false;
    this.currentview.searchbox = false;
  }
}
