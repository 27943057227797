import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { FavoritoService } from '../../services/favorito/favorito.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { UiService } from '../../ui.service';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-pesquisarobservacaofuncionalidade',
  templateUrl: './pesquisarobservacaofuncionalidade.component.html',
  styleUrls: ['./pesquisarobservacaofuncionalidade.component.css'],

})
export class PesquisarobservacaofuncionalidadeComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService,
    private uiservice: UiService, private favoritoService: FavoritoService) {
    //
  }

  currentview = CurrentView;
  myForm: FormGroup;
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  exibirTabela = false;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };
  funcionalidades = [];
  funcionalidadesSelected = [];
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;
  p: number;

  ngOnInit() {
    const vm = this;
    this.log.info('PesquisarObservacaoFuncionalidadeComponent.init()');
    this.analytics.trackEvent('controller', 'PesquisarObservacaoFuncionalidadeComponent');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      funcionalidades: null,
      ambiente: 'T'
    });
    this.p = 1;
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.acessoInclusao = this.appService.temAutorizacao('pesquisarObservacaoFuncionalidade', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('pesquisarObservacaoFuncionalidade', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('pesquisarObservacaoFuncionalidade', 'E');
    this.favoritoService.initFavorite(this.caminho, this.idUsuario)
      .subscribe((res: any) => {
        vm.isFavorito = res.idFavorito != null;
        vm.idFavorito = res.idFavorito;
        vm.idItemFavorito = res.idItemFavorito;
      }, (err: HttpErrorResponse) => {
        console.log('Error response.');
        console.log(err);
      });
    this.popularCampos();
  }

  listar() {
    const vm = this;
    vm.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'listar()');
    vm.tblData = [];
    vm.p = 1;
    let params = new HttpParams().append('funcionalidades', vm.appService.getLista(vm.funcionalidadesSelected, 'id'));
    params = params.append('ambiente', vm.myForm.get('ambiente').value);
    vm.webservice.read('ws/observacoesfuncionalidades', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'listar():success');
      if (res && res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'id': element.id,
            'idFuncionalidade': element.funcionalidadeSistemaVo.id,
            'funcionalidade': element.funcionalidadeSistemaVo.descricao,
            'idAmbiente': element.idAmbiente,
            'ambiente': element.ambiente,
            'descricao': element.descricao
          });
        });
      }
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.uiservice.failApi( vm, 'PesquisarObservacaoFuncionalidadeComponent', 'listar():error', xhr, status, err);
    }
  }

  limpar() {
    this.myForm.patchValue({
      funcionalidades: null,
      ambiente: 'T'
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.p = 1;
  }

  alterar(row) {
    if (row == null) {
      // Se for inclusão, verificar se existe registro para funcionalidade(s) selecionada(s) e ambiente(s)
      this.verificarFuncionalidadeAmbiente();
    } else {
      this.appService.openPage('pesquisarObservacaoFuncionalidade/cadastrarObservacaoFuncionalidade', row);
    }
  }

  confirmDelete(id) {
    const vr = this;
    vr.alertService.showConfirm('Atenção', 'Confirma a exclusão deste item?',
      function() {
        vr.log.info('Exclusão confirmada. Executando exclusao no backend.');
        vr.webservice.deleta('ws/observacoesfuncionalidades/' + id, null, null, success, fail);
      },
      function() {
        vr.log.info('Exclusão cancelada pelo usuário.');
      });

    function success(res) {
      vr.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'confirmDelete():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao excluir Observação - ' + res.msgsErro[0];
        vr.log.error(msg);
        vr.alertService.showMessage('Erro', msg);
      } else {
        vr.alertService.showMessage('Atenção', 'Observação excluída com sucesso.');
        vr.listar();
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao excluir Observação.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vr.log.error(message);
      vr.alertService.showMessage('Erro', message);
    }
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'popularCampos()');
    this.webservice.read('ws/observacoesfuncionalidades/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.funcionalidades = [];
      vm.funcionalidadesSelected = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((f) => {
          vm.funcionalidades.push({
            'id': f.id,
            'itemName': f.descricao
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'popularCampos():error');
      let message = 'Falha ao popular campos PesquisarObservacaoFuncionalidadeComponent';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
    }
  }

  doFavorite() {
    const vm = this;
    const params = { 'idFavorito': vm.idFavorito, 'caminho': vm.caminho, 'idUsuario': vm.idUsuario };
    if (params !== null) {
      if (params.idFavorito == null && params.caminho !== null && params.idUsuario !== null) {
        vm.webservice.create('ws/favoritos', {'idItemFavorito': params.caminho, 'idUsuario': params.idUsuario}, success, fail);
      } else {
        vm.webservice.delete('ws/favoritos/' + params.idFavorito + '/F', null, success, fail);
      }
    }

    function success(res) {
      const op = res.operacao;
      vm.isFavorito = op === 'I';
      vm.idFavorito = res.idFavorito;
    }

    function fail(xhr, status, erro) {
      let message = 'Falha ao atualizar o Observacao Funcionalidade como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (erro) {
          message = erro;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, erro);
      if (xhr.status === 502 || erro === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  onItemSelect(item: any) {
    //
  }
  OnItemDeSelect(item: any) {
    //
  }
  onSelectAll(items: any) {
    //
  }
  onDeSelectAll(items: any) {
    //
  }
  limpaTabela() {
    this.exibirTabela = false;
  }

  verificarFuncionalidadeAmbiente() {
    const vm = this;
    this.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'verificarFuncionalidadeAmbiente()');
    const funcsel = vm.appService.getLista(vm.funcionalidadesSelected, 'id');
    let params = new HttpParams().append('funcionalidades', funcsel);
    params = params.append('ambiente', vm.myForm.get('ambiente').value);
    this.webservice.read('ws/observacoesfuncionalidades', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PesquisarObservacaoFuncionalidadeComponent', 'verificarFuncionalidadeAmbiente():success');
      if (res && res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (res.data && res.data.length > 0 && funcsel != null) {
        const msg = 'Já Existe Observação Cadastrada para Este Filtro.';
        vm.alertService.showMessage('Aviso', msg);
        return;
      }
      vm.appService.openPage('pesquisarObservacaoFuncionalidade/cadastrarObservacaoFuncionalidade', null);
    }

    function fail(xhr, status, err) {
      vm.uiservice.failApi( vm, 'PesquisarObservacaoFuncionalidadeComponent', 'verificarFuncionalidadeAmbiente():error', xhr, status, err);
    }
  }
}
