import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Config } from '../../../../../environments/config';
import { CurrentView } from '../../../../../environments/currentview';
import { AlertService } from '../../../../services/alert/alert.service';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../../services/application/application.service';
import { GeolocationService } from '../../../../services/geolocation/geolocation.service';
import { LogService } from '../../../../services/log/log.service';
import { NativeInterfaceService } from '../../../../services/native-interface/native-interface.service';
import { WebServiceService } from '../../../../services/web-service/web-service.service';

@Component({
  selector: 'app-distribuicaosaldoscontasinativas',
  templateUrl: './distribuicaosaldoscontasinativas.component.html',
  styleUrls: ['./distribuicaosaldoscontasinativas.component.css']
})
export class DistribuicaoSaldosContasInativasComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  criterio = '7';
  tbl = ['Tipo', 'Nome', 'Número', 'Data', 'Assunto', 'Observação'];
  tblData = [];
  pageOptions = [1, 2, 5, 10];
  itemsOnPagetbl2 = 5;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  periodos = [];
  periodoInic = null;
  periodoFim = null;
  periodoImp = null;
  cab = null;
  dataAtual = new Date();
  exibirTabela = false;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  p: number;
  ordemPeriodoResultado = 'asc';
  filtrotbl = null;

  ngOnInit() {
    this.log.info('DistribuicaoSaldosContasInativasComponent.init()');
    this.analytics.trackEvent('controller', 'DistribuicaoSaldosContasInativasController');
    this.p = 1;
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      ordemRelatorio: 'D'
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.popularCampos();
    this.ordemPeriodoResultado = 'asc';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('DistribuicaoSaldosContasInativasComponent', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasComponent', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasComponent', 'initFavorite():error');
      let message = 'Falha ao configurar favorito DistribuicaoSaldosContasInativasComponent';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasComponent', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasComponent', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  datatbl2Reset() {
    this.periodoImp = null;
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  exibir(obj) {
    if (obj !== this.periodoImp) {
      this.periodoImp = obj;
      return true;
    }
  }
  exibetot(obj) {
    if (obj === 'TOTAL') {
      return true;
    }
  }

  listar() {
    const vm = this;
    this.filtrotbl = null;
    this.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'listar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('ordemRelatorio', vm.myForm.get('ordemRelatorio').value);
    this.webservice.read('ws/informacoesconsolidadas/distribuicaosaldoscontasinativas', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'periodo': element.periodo,
            'listDistribuicaoSaldosContasInativas': element.listDistribuicaoSaldosContasInativas
          });

        });
      }
      vm.dataAtual = new Date();
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'listar():error');
      let message = 'Falha ao listar DistribuicaoSaldosContasInativasController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      periodoInicial: '',
      periodoFinal: '',
      ordemRelatorio: 'D'
    });
    this.periodoImp = null;
    this.popularCampos();
    this.tblData = [];
    this.exibirTabela = false;
    this.ordemPeriodoResultado = 'asc';
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'popularCampos()');
    this.webservice.read('ws/informacoesconsolidadas/distribuicaosaldoscontasinativas/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((dataPer) => {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
          });
        });
      }
      const periodoInic = res.data[0].minPeriodo;
      const periodoFim = res.data[0].maxPeriodo;
      vm.myForm.get('periodoInicial').setValue(periodoInic);
      vm.myForm.get('periodoFinal').setValue(periodoFim);
      vm.listar();
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'popularCampos():error');
      let message = 'Falha ao popular campos DistribuicaoSaldosContasInativasController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'imprimir()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('ordemRelatorio', vm.myForm.get('ordemRelatorio').value);
    this.webservice.read('ws/informacoesconsolidadas/imprimirdistribuicaosaldoscontasinativas', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'imprimir():success');
      const filename = 'DistribuicaoSaldosContasInativas.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
          || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('ordemRelatorio', vm.myForm.get('ordemRelatorio').value);
    this.webservice.read('ws/informacoesconsolidadas/exportardistribuicaosaldoscontasinativas', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'exportar():success');
      const filename = 'DistribuicaoSaldosContasInativas.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  public exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('DistribuicaoSaldosContasInativasController', 'exportarCsv()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
          || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('ordemRelatorio', vm.myForm.get('ordemRelatorio').value);
    vm.webservice.postDownload('ws/informacoesconsolidadas/exportardistribuicaosaldoscontasinativascsv', null, 'text/plain', true, 'distribuicaosaldoscontasinativas.csv', params);
  }
}
