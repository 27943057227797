import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material';
import { Router } from '@angular/router';
import * as numeral from 'numeral';
import { Config } from '../../../../../environments/config';
import { CurrentView } from '../../../../../environments/currentview';
import { AlertService } from '../../../../services/alert/alert.service';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../../services/application/application.service';
import { GeolocationService } from '../../../../services/geolocation/geolocation.service';
import { LogService } from '../../../../services/log/log.service';
import { NativeInterfaceService } from '../../../../services/native-interface/native-interface.service';
import { UtilsService } from '../../../../services/utils/utils.service';
import { WebServiceService } from '../../../../services/web-service/web-service.service';

@Component({
  selector: 'app-arrecadacaoliquidamensal',
  templateUrl: './arrecadacaoliquidamensal.component.html',
  styleUrls: ['./arrecadacaoliquidamensal.component.css']
})
export class ArrecadacaoLiquidaMensalComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService, private utilservice: UtilsService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tblData = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  anoMeses = [];
  dataAtual = new Date();
  anoMesI = null;
  anoMesF = null;
  exibirTabela = false;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  totArrecadBrut = null;
  totSaques = null;
  totArrecadLiq = null;
  cboAnoMesIFormatado = '';
  cboAnoMesFFormatado = '';
  timer = null;
  ordemPeriodoResultado = 'asc';

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  filtrotbl = null;

  chartData = [];
  chartAxis1 = [];
  chartAxis2 = [];
  chartAxis3 = [];
  chartLabels = [];
  chartType = 'bar';
  chartOptions = {
    scaleShowVerticalLines: true,
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + numeral(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).format('0,0');
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Meses'
        },
        ticks: {
          fontFamily: 'Calibri,Candara,Segoe,\'Segoe UI\',Optima,Arial,sans-serif',
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }],
      yAxes: [{
        display: true,
        beginAtZero: true,
        scaleLabel: {
          display: true,
          labelString: 'Valores'
        },
        ticks: {
          callback: function (value, index, values) {
            return 'R$ ' + numeral(value).format('0,0');
          }
        },
        gridLines: {
          color: 'rgba(51,177,174,0.3)',
        }
      }]
    }
  };

  observacao = null;
  public p: number;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.log.info('ArrecadacaoLiquidaMensalComponent.init()');
    this.analytics.trackEvent('controller', 'ArrecadacaoLiquidaMensalController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      cboAnoMesI: [''],
      cboAnoMesF: [''],
      ordemPeriodoResultado: 'C'
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.popularCampos();
    this.p = 1;
    this.ordemPeriodoResultado = 'asc';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('InfoconsolidArrecadLiqMensalController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('InfoconsolidArrecadLiqMensalController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InfoconsolidArrecadLiqMensalController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito InfoconsolidArrecadLiqMensalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = { 'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario };
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = { 'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario };
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('InfoconsolidArrecadLiqMensalController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        // let msg = null
        if (op === 'I') {
          // msg = 'Funcionalidade foi marcada como favorita.'
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          // msg = 'Funcionalidade foi desmarcada como favorita.'
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
        // vm.alertService.showMessage('Atenção', msg)
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('InfoconsolidArrecadLiqMensalController', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  datatbl2Reset() {
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'listar()');
    if (vm.myForm.get('cboAnoMesI').value === '' || vm.myForm.get('cboAnoMesF').value === ''
      || vm.myForm.get('cboAnoMesI').value === null || vm.myForm.get('cboAnoMesF').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('cboAnoMesI').value, 10) > parseInt(vm.myForm.get('cboAnoMesF').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;
    let params = new HttpParams().append('cboAnoMesI', vm.myForm.get('cboAnoMesI').value);
    params = params.append('cboAnoMesF', vm.myForm.get('cboAnoMesF').value);
    params = params.append('ordemPeriodoResultado', vm.myForm.get('ordemPeriodoResultado').value);

    this.webservice.read('ws/informacoesconsolidadas/arrecadacaoliquidamensal', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      if (res.data && res.data.length > 0) {
        let count = 0;
        vm.chartLabels = [];
        vm.chartAxis1 = [];
        vm.chartAxis2 = [];
        vm.chartAxis3 = [];
        res.data.forEach((element) => {
          vm.tblData.push({
            'periodo': element.periodo,
            'periodoOriginal': element.periodoOriginal,
            'arrecadbrut': element.arrecadbrut,
            'saques': element.saques,
            'arrecadliq': element.arrecadliq
          });
          if (++count <= 6) {
            vm.chartLabels.push(element.periodo);
            vm.chartAxis1.push(element.arrecadbrut / 1000);
            vm.chartAxis2.push(element.saques / 1000);
            vm.chartAxis3.push(element.arrecadliq / 1000);
          }
        });
        vm.exibirTabela = true;
      }
      vm.totArrecadBrut = res.totArrecadBrut;
      vm.totSaques = res.totSaques;
      vm.totArrecadLiq = res.totArrecadLiq;
      vm.cboAnoMesIFormatado = res.anoMesIFormatado;
      vm.cboAnoMesFFormatado = res.anoMesFFormatado;
      vm.dataAtual = new Date();
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      vm.observacao = res.observacao;
      setTimeout(() => {
        const divobs = document.getElementById('observacao');
        divobs.innerHTML = vm.observacao;
      }, 250);

      vm.chartData = [
        { data: vm.chartAxis1, fill: false, label: 'Arrecadação' },
        { data: vm.chartAxis2, fill: false, label: 'Saques' },
        { data: vm.chartAxis3, fill: false, label: 'Arrecadação Líquida' }
      ];
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'listar():error');
      let message = 'Falha ao listar ArrecadacaoLiquidaMensalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      cboAnoMesI: '',
      cboAnoMesF: '',
      ordemPeriodoResultado: 'C'
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.observacao = null;
    this.ordemPeriodoResultado = 'asc';
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'popularCampos()');
    this.webservice.read('ws/informacoesconsolidadas/arrecadacaoliquidamensal/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.anoMeses = [];
      if (res.data && res.data.length > 0) {
        vm.anoMeses.push({
          'valor': '',
          'texto': 'Escolha o Ano'
        });
        res.data.forEach((filtros) => {
          filtros.lstAnoMeses.forEach((a) => {
            vm.anoMeses.push({
              'valor': a.anoMes,
              'texto': a.anoMesFormatado
            });
          });
        });

        const anoMesI = res.data[0].anoMesInicialFiltro;
        const anoMesF = res.data[0].anoMesFinalFiltro;
        vm.myForm.get('cboAnoMesI').setValue(anoMesI.toString());
        vm.myForm.get('cboAnoMesF').setValue(anoMesF.toString());
        vm.listar();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'popularCampos():error');
      let message = 'Falha ao popular campos ArrecadacaoLiquidaMensalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

  }

  public imprimir(tipo) {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'imprimir()');
    if (vm.myForm.get('cboAnoMesI').value === '' || vm.myForm.get('cboAnoMesF').value === ''
      || vm.myForm.get('cboAnoMesI').value === null || vm.myForm.get('cboAnoMesF').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('cboAnoMesI').value, 10) > parseInt(vm.myForm.get('cboAnoMesF').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    const canvas = document.getElementById('graficolinha') as HTMLCanvasElement;
    const base64Canvas = canvas.toDataURL();
    const body = {
      'cboAnoMesI': vm.myForm.get('cboAnoMesI').value,
      'cboAnoMesF': vm.myForm.get('cboAnoMesF').value,
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value,
      'grafico': base64Canvas
    };
    vm.webservice.create('ws/informacoesconsolidadas/imprimirarrecadacaoliquidamensal?tipo=' + tipo, body, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'imprimir():success');
      let filename = 'arrecadacaoliquidamensal';
      let contenttype = 'application/';
      if (tipo === Config.REPORT_PDF) {
        filename += '.pdf';
        contenttype += 'pdf';
      } else {
        filename += '.xls';
        contenttype += 'xls';
      }
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contenttype });
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'imprimir():error');
      let message = 'Falha ao imprimir ArrecadacaoLiquidaMensalController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  public exportarCsv() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaMensalController', 'imprimir()');
    if (vm.myForm.get('cboAnoMesI').value === '' || vm.myForm.get('cboAnoMesF').value === ''
      || vm.myForm.get('cboAnoMesI').value === null || vm.myForm.get('cboAnoMesF').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('cboAnoMesI').value, 10) > parseInt(vm.myForm.get('cboAnoMesF').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    const body = {
      'cboAnoMesI': vm.myForm.get('cboAnoMesI').value,
      'cboAnoMesF': vm.myForm.get('cboAnoMesF').value,
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/informacoesconsolidadas/exportararrecadacaoliquidamensalcsv', body, 'text/plain', true, 'arrecadacaoliquidamensal.csv', null);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
