import { NativeInterfaceService } from './../../../services/native-interface/native-interface.service';
import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'app-perfilcadastro',
  templateUrl: './perfilcadastro.component.html',
  styleUrls: ['./perfilcadastro.component.css'],

})

export class PerfilcadastroComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService, private actroute: ActivatedRoute,
    private nativeInterface: NativeInterfaceService) {
    //
  }

  sigla = null;
  descricao = null;
  perfil = null;
  currentview = CurrentView;
  myForm: FormGroup;
  myOldForm: FormGroup;
  hasChanged = false;
  inclusao = false;
  listacadastroContent = [];
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  acessoCadastro = false;
  acessoPesquisa = false;
  acessoInclusao = false;
  acessoAlteracao = false;
  tituloFormulario = '';

  ngOnInit() {
    this.log.info('PerfilcadastroComponent.init()');
    this.analytics.trackEvent('controller', 'PerfilcadastroController');

    this.currentview.locked = false;
    this.currentview.menu = true;

    this.myForm = this.formBuilder.group({
      sigla: [ null , [ Validators.required ]],
      descricao: [ null , [ Validators.required ]]
    });
    this.myOldForm = this.formBuilder.group({
      sigla: [ null , [ Validators.required ]],
      descricao: [ null , [ Validators.required ]]
    });

    this.perfil = this.appService.getPerfilUsuario();
    this.acessoCadastro = this.appService.temAutorizacao('perfilcadastro');
    this.acessoPesquisa = this.appService.temAutorizacao('perfilpesquisar');
    this.acessoInclusao = this.appService.temAutorizacao('perfilpesquisar', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('perfilpesquisar', 'A');

    // Obter a sigla do perfil selecionado na listagem da tela de consulta perfis.
    this.sigla = this.actroute.snapshot.params['sigla'];
    let prms = this.actroute.snapshot.params;
    if (!this.sigla) {
      prms = {sigla: this.sigla};
    }

    // Exemplos:
    // 1) Perfil Administrador tem acesso full à manutenção usuario. Título "Inclusão de Usuário" ou "Alteração de Perfil".
    // 2) Perfil Auditor tem acesso à manutenção de Perfil, porém, ele só pode consultar os dados. Título: "Consulta Perfil de Usuário"
    // 3) Outros perfis NÃO têm acesso às telas de pesquisa e cadastro de Perfil;
    if (this.acessoInclusao && this.sigla == null) {
      this.tituloFormulario = 'Inclusão de Perfil de Usuário';
    } else if (this.acessoAlteracao && this.sigla != null) {
      this.tituloFormulario = 'Alteração de Perfil de Usuário';
    } else {
      this.tituloFormulario = 'Consulta Perfil de Usuário';
    }

    this.populaDadosForm(prms);
    this.onValueChanges();
    this.hasChanged = this.myForm.get('sigla').value == null;
  }

  limpar() {
    this.myForm.patchValue({
      sigla: null,
      descricao: null
    });
    this.myOldForm.patchValue({
      sigla: null,
      descricao: null
    });
  }

  salvar() {
    const vm = this;
    this.analytics.trackEvent('PerfilcadastroController', 'inclusao()');

    function success(res) {
      vm.analytics.trackEvent('PerfilcadastroController', 'inclusao():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        let oper = null;
        if (vm.inclusao) {
          oper = 'incluído';
        } else {
          oper = 'alterado';
        }
        vm.alertService.showMessage('Atenção', 'Perfil ' + oper + ' com sucesso.');
        if (vm.inclusao) {
          vm.limpar();
        } else {
          vm.hasChanged = false;
        }
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao ';
      if (vm.inclusao) {
        message = message + 'incluir ';
      } else {
        message = message + 'alterar ';
      }
      message = message + 'Usuário.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else {
        if (xhr && xhr.responseText) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response && response.msgsErro && response.msgsErro.length > 0) {
              message = response.msgsErro[0];
            }
          } catch (ignore) {
          }
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }

    const siglaval = this.myForm.get('sigla').value;
    if (!siglaval || siglaval.trim() === '') {
      vm.alertService.showMessage('Atenção', 'O campo Sigla deve ser informado.');
    return;
    }
    if (siglaval.length < 2) {
      vm.alertService.showMessage('Atenção', 'O campo Sigla deve conter duas letras.');
    return;
    }
    const descricaoval = this.myForm.get('descricao').value;
    if (!descricaoval || descricaoval.trim() === '') {
      vm.alertService.showMessage('Atenção', 'O campo Nome deve ser informado.');
      return;
    }

    if (vm.inclusao) {
      vm.webservice.create('ws/perfis', vm.myForm.value, success, fail);
    } else {
      vm.webservice.update('ws/perfis', vm.myForm.value, null, success, fail);
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  populaDadosForm(dados) {
    const vm = this;
    this.inclusao = dados && dados.sigla == null;

    if (vm.inclusao) {
      if (dados.sigla) {
        dados.sigla = null;
      }
    } else {
      this.myForm.patchValue({
        perfil: dados.perfil,
        sigla: dados.sigla.trim(),
        descricao: dados.descricao.trim()
      });
      this.myOldForm.patchValue({
        perfil: dados.perfil,
        sigla: dados.sigla.trim(),
        descricao: dados.descricao.trim()
      });
    }
  }

  onValueChanges(): void {
    const vm = this;
    if (this.myForm.get('sigla').value !== null) {
      this.myForm.valueChanges.subscribe(val => {
        vm.hasChanged = !_.isEqual(val, vm.myOldForm.value);
      });
    }
  }

  voltar() {
    this.appService.openPage('perfilpesquisar');
  }
}
