import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../../environments/config';
import { CurrentView } from '../../../../environments/currentview';
import { AlertService } from '../../../services/alert/alert.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ApplicationService } from '../../../services/application/application.service';
import { LogService } from '../../../services/log/log.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
declare const $: any;

@Component({
  selector: 'app-arrecadacaoconsolidadapub',
  templateUrl: './arrecadacaoconsolidadapub.component.html',
  styleUrls: ['./arrecadacaoconsolidadapub.component.css',
              '../../../../assets/css/publico/publico.css']
})

export class ArrecadacaoConsolidadaPubComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private translate: TranslateService) {
    translate.setDefaultLang('pt');
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 50;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  periodos = [];
  exibirTabela = false;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  ufs = [];
  tipoarrecadacaos = [];
  timer = null;
  dataAtual = new Date();
  totQtd = null;
  totVlr = null;
  dtPosicao: '';
  detalheUf = false;
  detalheTipo = false;
  colSpanRes = 0;
  periodoRes = null;
  ufRes = null;
  tipoRes = null;
  ordemPeriodoResultado = 'C';

  ufsSelected = [];
  tiposSelected = [];
  observacao = null;
  public p: number;

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  onDetalheUfItemChange(event) {
    this.detalheUf = event.target.value;
  }

  onDetalheTipoItemChange(event) {
    this.detalheTipo = event.target.value;
  }

  ngOnInit() {
    this.log.info('ArrecadacaoConsolidadaPubComponent.init()');
    this.analytics.trackEvent('controller', 'ArrecadacaoConsolidadaPubController');
    const element = <HTMLElement> document.getElementsByClassName('theme-app')[0];
    element.style.backgroundColor = '#FFF';
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      uf: [ 'Todas' ],
      tipoarrecadacao: [ 'Todos' ],
      periodo: [ 'M' ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      detalheUf: false,
      detalheTipo: false,
      ordemPeriodoResultado: 'C'
    });
    this.popularCampos();
    this.p = 1;
    this.ordemPeriodoResultado = 'asc';
    $(document).ready( function() {
      $('#uf').on('change', function(){
        if ($(this).val().trim() === '0: Todas') {
          $('input[name=detalheUf]').removeAttr('disabled');
        } else {
          $('input[name=detalheUf]').prop('checked', false);
          $('input[name=detalheUf]').prop('disabled', 'disabled');
          $('span .checkmark').prop('disabled', 'disabled');
        }
      });
      $('#tipoarrecadacao').on('change', function(){
        if ($(this).val().trim() === '0: Todos') {
          $('input[name=detalheTipo]').removeAttr('disabled');
        } else {
          $('input[name=detalheTipo]').prop('checked', false);
          $('input[name=detalheTipo]').prop('disabled', 'disabled');
          $('span .checkmark').prop('disabled', 'disabled');
        }
      });
    });
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'listar()');
    this.exibirTabela = false;
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;

    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheTp', vm.myForm.get('detalheTipo').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    this.periodoRes = this.appService.getSelectedIndexText('periodoInicial') + ' a ' + this.appService.getSelectedIndexText('periodoFinal');
    const body = {
      'uf': vm.appService.getLista(vm.ufsSelected, 'itemName'),
      'tpArrecadacao': vm.appService.getLista(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/publico/arrecadacaoconsolidada', body, success, fail, params);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'periodo': element.periodo,
            'uf': element.uf,
            'arrecadacao': element.arrecadacao,
            'quantidade': element.qtd,
            'valor': element.vlr
          });
        });
      }
      vm.totQtd = res.totQtd;
      vm.totVlr = res.totVlr;
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      vm.observacao = res.observacao;
      if (vm.observacao !== null) {
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
      vm.colSpanRes = 3;
      if (!vm.myForm.get('detalheUf').value) {
        vm.colSpanRes -= 1;
      }
      if (!vm.myForm.get('detalheTipo').value) {
        vm.colSpanRes -= 1;
      }
      $(document).ready(function() {
        $('.colSpanRes').attr('colspan', vm.colSpanRes);
      });
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'listar():error');
      let message = 'Falha ao listar ArrecadacaoConsolidadaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      uf: null,
      tipoarrecadacao: null,
      periodo: 'M',
      periodoInicial: '',
      periodoFinal: '',
      detalheTipo: false,
      detalheUf: false,
      ordemPeriodoResultado: 'C'
    });
    this.popularCampos();
    this.tblData = [];
    this.exibirTabela = false;
    this.observacao = null;
    const divobs = document.getElementById('observacao');
    divobs.innerHTML = this.observacao;
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'popularCampos()');
    const entrada = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    this.webservice.read('ws/publico/arrecadacaoconsolidada/filtros', entrada, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ufs = [];
      vm.ufsSelected = [];
      vm.tipoarrecadacaos = [];
      vm.tiposSelected = [];
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.dtPosicao = res.dtPosicao;
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.ufs.forEach((dataUf) => {
            vm.ufs.push({
              'id': dataUf.sigla,
              'itemName': dataUf.nome
            });
          });
          filtros.tipoArrecadacaos.forEach((dataArrecada) => {
            vm.tipoarrecadacaos.push({
              'id': dataArrecada.codigo,
              'itemName': dataArrecada.codigo
            });
          });
          filtros.periodosInicial.forEach((dataPer) => {
            if (vm.myForm.get('periodo').value === 'M') {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo
              });
            }
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'popularCampos():error');
      let message = 'Falha ao popular campos ArrecadacaoConsolidadaPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  carregaperiodo() {
    const vm = this;
    this.myForm.patchValue({
      periodoInicial: null,
      periodoFinal: null,
    });
    this.myForm.get('periodo').setValue(vm.myForm.get('periodo').value);
    this.popularPeriodo();
  }

  public imprimir()  {
      const vm = this;
      this.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'imprimir()');

      if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
        vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
        return;
      }
      if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
        vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
        return;
      }

      let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
      params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
      params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
      params = params.append('detalheTp', vm.myForm.get('detalheTipo').value);
      params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
      this.periodoRes = this.appService.getSelectedIndexText('periodoInicial') + ' a ' + this.appService.getSelectedIndexText('periodoFinal');
      const body = {
        'uf': vm.appService.getLista(vm.ufsSelected, 'itemName'),
        'tpArrecadacao': vm.appService.getLista(vm.tiposSelected, 'id'),
        'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
      };
      vm.webservice.create('ws/publico/imprimirarrecadacaoconsolidada', body, success, null, params);

      function success(res) {
        vm.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'imprimir():success');
        const filename = 'ArrecadaçãoConsolidada.pdf';
        const linkElement = document.createElement('a');
        const sliceSize = 512;
        const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', filename);
        const clickEvent = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
        });
        linkElement.dispatchEvent(clickEvent);
      }
  }

    public exportarxls()  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'exportarxls()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheTp', vm.myForm.get('detalheTipo').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    this.periodoRes = this.appService.getSelectedIndexText('periodoInicial') + ' a ' + this.appService.getSelectedIndexText('periodoFinal');
    const body = {
      'uf': vm.appService.getLista(vm.ufsSelected, 'itemName'),
      'tpArrecadacao': vm.appService.getLista(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/publico/exportararrecadacaoconsolidada', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoConsolidadaPubController', 'exportarxls():success');
      const filename = 'ArrecadaçãoConsolidada.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  onItemSelect(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelect(item: any) {
    this.limpaTabela();
  }
  onSelectAll(items: any) {
    this.limpaTabela();
  }
  onDeSelectAll(items: any) {
    this.limpaTabela();
  }

  onItemSelectTipo(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectTipo(item: any) {
    this.limpaTabela();
  }
  onSelectAllTipo(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllTipo(items: any) {
    this.limpaTabela();
  }

  getLista(arraylist, atrib) {
    let res = null;

    let arrayStr: Array<string> = null;
    if (arraylist != null) {
      if (atrib === 'id') {
        arrayStr = arraylist.map(o => o.id.trim());
      } else {
        arrayStr = arraylist.map(o => o.itemName.trim());
      }
    }

    if (arrayStr !== null && arrayStr.length > 0) {
      res = JSON.stringify(arrayStr);
      res = res.replace('[', '').replace(']', '');
    }
    return res;
  }

  showLista(arraylist) {
    let res = '';
    if (arraylist != null && arraylist.length > 0) {
      res = this.getLista(arraylist, 'itemName').replace(/"/g, '').replace(/,/g, ', ');
    }
    return res;
  }

  popularPeriodo() {
    this.exibirTabela = false;
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoConsolidada', 'popularPeriodo()');
    const params = new HttpParams().append('tipoPeriodo', vm.myForm.get('periodo').value);
    this.webservice.read('ws/publico/arrecadacaoconsolidada/periodos', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoConsolidada', 'popularPeriodo():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((data) => {
            if (vm.myForm.get('periodo').value === 'M') {
              vm.periodos.push({
                'periodo': data.periodo,
                'periodofmt': data.periodo.substring(4, 6) + '/' + data.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': data.periodo,
                'periodofmt': data.periodo
              });
            }
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoConsolidada', 'popularPeriodo():error');
      let message = 'Falha ao popular periodo ArrecadacaoConsolidada';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  public exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoConsolidadaController', 'exportarCsv()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheTp', vm.myForm.get('detalheTipo').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    const body = {
      'uf': vm.appService.getLista(vm.ufsSelected, 'itemName'),
      'tpArrecadacao': vm.appService.getLista(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/publico/exportararrecadacaoconsolidadacsv', body, 'text/plain', true, 'arrecadacaoconsolidada.csv', params);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
