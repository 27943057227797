import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { Config } from '../../../environments/config';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CurrentView } from '../../../environments/currentview';
import { ApplicationService } from '../../services/application/application.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';
import { UiService } from '../../ui.service';

@Component({
  selector: 'app-pesquisarusuario',
  templateUrl: './pesquisarusuario.component.html',
  styleUrls: ['./pesquisarusuario.component.css'],

})
export class PesquisarusuarioComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService,
    private uiservice: UiService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  perfilContent = [];
  tbl = ['CPF', 'Nome', 'Perfil Usuário', 'Status', 'Editar', 'Excluir'];
  tblData = [];
  statusContent = [{ 'id': '', 'descricao': '' },
                   { 'id': 'A', 'descricao': 'Ativo' },
                   { 'id': 'I', 'descricao': 'Inativo' }];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];

  hasCentral = Config.CENTRAL_ID !== '[null]';

  timer = null;

  filtrotbl = null;
  fulltbl2Data = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;

  exibirTabela = false;

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;
  p: number;

  ngOnInit() {
    this.log.info('PesquisarusuarioComponent.init()');
    this.analytics.trackEvent('controller', 'PesquisarusuarioController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      cpf: null,
      nome: null,
      perfil: null,
      status: null
    });
    this.p = 1;
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.acessoInclusao = this.appService.temAutorizacao('pesquisarUsuario', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('pesquisarUsuario', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('pesquisarUsuario', 'E');
    this.initFavorite();
    this.popularCampos();
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('PesquisarusuarioController', 'listar()');
    vm.tblData = [];
    let cpf = vm.myForm.get('cpf').value;
    if (cpf !== null) {
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace(/-/g, '');
      cpf = cpf.replace(/_/g, '');
    }
    vm.p = 1;
    let params = new HttpParams().append('cpf', cpf);
    params = params.append('nome', vm.myForm.get('nome').value);
    params = params.append('perfil', vm.myForm.get('perfil').value);
    params = params.append('status', vm.myForm.get('status').value);
    this.webservice.read('ws/usuarios', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'listar():success');
      if (res && res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'id': element.id,
            'cpf': element.cpf,
            'nome': element.nome,
            'dePerfil': element.dePerfil,
            'perfil': element.perfil,
            'idStatus': element.idStatus,
            'status': element.status,
            'email': element.email,
            'telefone': element.telefone,
            'orgao': element.orgao,
            'uf': element.uf,
            'dataInclusao': element.dataInclusao,
            'senha': element.senha,
            'dataAssinaturaTermo': element.dataAssinaturaTermo
          });
        });
      }
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.uiservice.failApi( vm, 'PesquisarusuarioController', 'listar():error', xhr, status, err);
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      cpf: null,
      nome: null,
      perfil: null,
      status: null
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.p = 1;
  }

  alterar(row) {
    if (row == null) {
      row = { 'acao': 'I' };
    }
    this.appService.openPage('cadastrarUsuario', row);
  }

  confirmDelete(idusu) {
    const vr = this;

    function success(res) {
      vr.analytics.trackEvent('PesquisarusuarioController', 'confirmDelete():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao excluir usuario - ' + res.msgsErro[0];
        vr.log.error(msg);
        vr.alertService.showMessage('Erro', msg);
      } else {
        // vm.appService.openPage(paginaDestino)
        vr.alertService.showMessage('Atenção', 'Usuário excluído com sucesso.');
        vr.listar();
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao excluir Usuario';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vr.log.error(message);
    }

    function confirm() {
      vr.log.info('Exclusão confirmada. Executando exclusao no backend.');
      vr.webservice.deleta('ws/usuarios/' + idusu, null, null, success, deny);
    }

    function deny() {
      vr.log.info('Exclusão cancelada pelo usuário.');
    }

    vr.alertService.showConfirm('Atenção', 'Confirma a exclusão deste item?', confirm, deny);
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('PesquisarusuarioController', 'imprimir()');
    let cpf = vm.myForm.get('cpf').value;
    if (cpf !== null) {
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace(/-/g, '');
    }
    let params = new HttpParams().append('cpf', cpf);
    params = params.append('nome', vm.myForm.get('nome').value);
    params = params.append('perfil', vm.myForm.get('perfil').value);
    params = params.append('status', vm.myForm.get('status').value);
    this.webservice.read('ws/usuarios/imprimir', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'imprimir():success');
      const filename = 'PesquisarUsuario.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, '');  // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('PesquisarusuarioController', 'exportar()');
    let cpf = vm.myForm.get('cpf').value;
      if (cpf !== null) {
        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace(/-/g, '');
      }
    let params = new HttpParams().append('cpf', cpf);
    params = params.append('nome', vm.myForm.get('nome').value);
    params = params.append('perfil', vm.myForm.get('perfil').value);
    params = params.append('status', vm.myForm.get('status').value);
    this.webservice.read('ws/usuarios/exportar', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'exportar():success');
      const filename = 'PesquisarUsuario.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('PesquisarusuarioController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.perfilContent = [];
      if (res.data && res.data.length > 0) {
        vm.perfilContent.push({
          'id': '',
          'descricao': ''
        });
        res.data.forEach((f) => {
          f.perfil.forEach((pe) => {
            vm.perfilContent.push({
              'id': pe.sigla,
              'descricao': pe.descricao
            });
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'popularCampos():error');
      let message = 'Falha ao popular campos PesquisarusuarioController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
    }

    this.webservice.read('ws/usuarios/filtrar', null, success, fail);
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('PesquisarusuarioController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito PesquisarusuarioController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('FaleconoscoController', 'favoritos():error');
      let message = 'Falha ao atualizar o pesquisa usuario como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }
}
