import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import { MatSort, Sort } from '@angular/material';

declare const $: any;

@Component({
  selector: 'app-execucaoorcamentaria',
  templateUrl: './execucaoorcamentaria.component.html',
  styleUrls: ['./execucaoorcamentaria.component.css']
})
export class ExecucaoorcamentariaComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tbl = ['Tipo', 'Nome', 'Número', 'Data', 'Assunto', 'Observação'];
  tblData = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];

  hasCentral = Config.CENTRAL_ID !== '[null]';

  timer = null;

  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  periodos = [];
  regiaoRes = null;
  regioes = [];
  ufs = [];
  areasaplicacao = [];
  programas = [];
  exibirTabela = false;
  dataAtual = new Date();
  totOrcamentoOriginal = null;
  totOrcamentoFinal = null;
  totEmprestimoAgentesFinanceiros = null;
  totRealizado = null;
  colSpanRes = 0;
  ordemPeriodoResultado = 'C';
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;

  native: NativeInterfaceService;
  usuario = null;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  multSelDropdownSettingsGroupBy = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    groupBy: 'category',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  regioesSelected = [];
  ufsSelected = [];
  areasSelected = [];
  programasSelected = [];
  pageSelected = 0;
  observacao = null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  p: number;

  ngOnInit() {
    this.log.info('ExecucaoorcamentariaComponent.init()');
    this.analytics.trackEvent('controller', 'ExecucaoorcamentariaController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      regiao: [ null ],
      uf: [ null ],
      areaaplicacao: [ null ],
      programa: [ null ],
      compromisso: [ null ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      detalheRegiao: [ false ],
      detalheUf: [ false ],
      detalhePrograma: [ false ],
      detalheArea: [ false ],
      ordemPeriodoResultado: 'C'
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.pageSelected = 0;
    this.popularCampos();
    this.ordemPeriodoResultado = 'asc';
    const vm = this;
    jQuery(function() {
      $('#optDetMarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(true);
          vm.limpaTabela();
        });
      });
      $('#optDetDesmarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(false);
          vm.limpaTabela();
        });
      });
    });
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('RelatorioexecorcamentariaController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('RelatorioexecorcamentariaController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('RelatorioexecorcamentariaController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito RelatorioexecorcamentariaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('RelatorioexecorcamentariaController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('RelatorioexecorcamentariaController', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ExecucaoorcamentariaController', 'listar()');
    this.filtrotbl = null;
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalheRegiao').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    params = params.append('detalheArea', vm.myForm.get('detalheArea').value);
    params = params.append('detalhePrograma', vm.myForm.get('detalhePrograma').value);
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/relatorios/execucaoorcamentaria', body, success, fail, params);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoorcamentariaController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.tblData.push({
            'ano': e.ano,
            'regiao': e.regiao,
            'uf': e.uf,
            'area': e.areaAplicacao,
            'programa': e.programa,
            'orcamentoOriginal': e.orcamentoOriginal / 1000,
            'orcamentoFinal': e.orcamentoFinal / 1000,
            'emprestimoAgentesFinanceiros': e.emprestimoAgentesFinanceiros / 1000,
            'realizado': e.realizado / 1000,
            'percEmprestado': (e.emprestimoAgentesFinanceiros / e.orcamentoFinal * 100),
            'saldo': (e.orcamentoFinal - e.emprestimoAgentesFinanceiros),
            'percRealizado': (e.realizado / e.emprestimoAgentesFinanceiros * 100),
            'saldoContratar': (e.emprestimoAgentesFinanceiros - e.realizado),
            'percContratar': ((e.emprestimoAgentesFinanceiros - e.realizado) / e.emprestimoAgentesFinanceiros * 100 ),
            'percRealizadoOrcamentoFinal': (e.realizado / e.orcamentoFinal * 100)
          });
        });
      }
      vm.totOrcamentoOriginal = res.totOrcamentoOriginal / 1000;
      vm.totOrcamentoFinal = res.totOrcamentoFinal / 1000;
      vm.totEmprestimoAgentesFinanceiros = res.totEmprestimoAgentesFinanceiros / 1000;
      vm.totRealizado = res.totRealizado / 1000;
      vm.exibirTabela = true;
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.observacao = res.observacao;
      if (vm.observacao !== null) {
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
      vm.colSpanRes = 1;
      if (vm.appService.getInputCheckboxValue('detalheArea')) {
        vm.colSpanRes += 1;
      }
      if (vm.appService.getInputCheckboxValue('detalhePrograma')) {
        vm.colSpanRes += 1;
      }
      if (vm.appService.getInputCheckboxValue('detalheUf') || vm.appService.getInputCheckboxValue('detalheRegiao')) {
        vm.colSpanRes += 1;
      }
      if (vm.appService.getInputCheckboxValue('detalheUf')) {
        vm.colSpanRes += 1;
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ExecucaoorcamentariaController', 'listar():error');
      let message = 'Falha ao listar ExecucaoorcamentariaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      regiao: null,
      uf: null,
      areaaplicacao: null,
      programa: null,
      compromisso: null,
      periodoInicial: '',
      periodoFinal: '',
      detalheRegiao: false,
      detalheUf: false,
      detalhePrograma: false,
      detalheArea: false,
      ordemPeriodoResultado: 'C'
    });
    this.tblData = [];
    this.popularCampos();
    this.exibirTabela = false;
    this.ordemPeriodoResultado = 'asc';
    this.p = 1;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ExecucaoOrcamentariaController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      vm.regioes = [];
      vm.regioesSelected = [];
      vm.ufs = [];
      vm.ufsSelected = [];
      vm.programas = [];
      vm.programasSelected = [];
      vm.areasaplicacao = [];
      vm.areasSelected = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((filtros) => {
          vm.periodos.push({
            'periodo': '',
            'periodofmt': 'Escolha o Período'
          });
          filtros.regioes.forEach((dataRegiao) => {
            vm.regioes.push({
              'id': dataRegiao.codigo,
              'itemName': dataRegiao.nome
            });
          });
          filtros.periodosInicial.forEach((dataPer) => {
            vm.periodos.push({
              'periodo': dataPer.periodo,
              'periodofmt': dataPer.periodo
            });
          });
          filtros.areasAplicacao.forEach((dataArea) => {
            vm.areasaplicacao.push({
              'id': dataArea.id,
              'itemName': dataArea.nome
            });
          });
          filtros.programas.forEach((dataProg) => {
            vm.programas.push({
              'id': dataProg.id,
              'itemName': dataProg.nome
            });
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaController', 'popularCampos():error');

      let message = 'Falha ao popular campos ExecucaoOrcamentariaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    this.webservice.read('ws/relatorios/execucaoorcamentaria/filtros', null, success, fail);
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('ExecucaoOrcamentariaController', 'imprimir()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalheRegiao').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    params = params.append('detalheArea', vm.myForm.get('detalheArea').value);
    params = params.append('detalhePrograma', vm.myForm.get('detalhePrograma').value);
    params = params.append('descArea', vm.getLista(vm.areasSelected, 'itemName'));
    params = params.append('descPrograma', vm.getLista(vm.programasSelected, 'itemName'));
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/relatorios/imprimirexecorcamentaria', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaController', 'imprimir():success');
      const filename = 'ExecucaoOrcamentaria.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('ExecucaoOrcamentariaController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalheRegiao').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    params = params.append('detalheArea', vm.myForm.get('detalheArea').value);
    params = params.append('detalhePrograma', vm.myForm.get('detalhePrograma').value);
    params = params.append('descArea', vm.getLista(vm.areasSelected, 'itemName'));
    params = params.append('descPrograma', vm.getLista(vm.programasSelected, 'itemName'));
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/relatorios/exportarexecorcamentaria', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaController', 'exportar():success');
      const filename = 'ExecucaoOrcamentaria.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  onItemSelect(item: any) {
    //
  }
  OnItemDeSelect(item: any) {
    //
  }
  onSelectAll(items: any) {
    //
  }
  onDeSelectAll(items: any) {
    //
  }

  onItemSelectArea(item: any) {
    //
  }
  OnItemDeSelectArea(item: any) {
    //
  }
  onSelectAllArea(items: any) {
    //
  }
  onDeSelectAllArea(items: any) {
    //
  }

  onItemSelectPrograma(item: any) {
    //
  }
  OnItemDeSelectPrograma(item: any) {
    //
  }
  onSelectAllPrograma(items: any) {
    //
  }
  onDeSelectAllPrograma(items: any) {
    //
  }

  getLista(arraylist, atrib) {
    let res = null;
    let arrayStr: Array<string> = null;
    if (arraylist != null) {
      if (atrib === 'id') {
        arrayStr = arraylist.map(o => o.id.trim());
      } else {
        arrayStr = arraylist.map(o => o.itemName.trim());
      }
    }
    if (arrayStr !== null && arrayStr.length > 0) {
      res = JSON.stringify(arrayStr);
      res = res.replace('[', '').replace(']', '');
    }
    return res;
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  showLista(arraylist, atrib) {
    let res = '';
    if (arraylist != null && arraylist.length > 0) {
      res = this.getLista(arraylist, atrib).replace(/"/g, '').replace(/,/g, ', ');
    }
    if (res === '') {
      res = 'Todos';
    }
    return res;
  }

  popularUfs(listaRegiao: any) {
    const vm = this;
    vm.limpaTabela();
    if (listaRegiao == null || listaRegiao.length === 0) {
      vm.ufs = [];
      return;
    }
    const arrayList: Array<string> = listaRegiao.map(o => o.id);
    this.webservice.create('ws/publico/ufs', {'regioes': arrayList}, success, fail);
    function success(res) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaController', 'popularUfs():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ufs = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((uf) => {
          if (uf.sigla !== 'MZ' ) {
            vm.ufs.push({
              'id': uf.sigla,
              'itemName': uf.nome,
              'category': uf.regiao
            });
          }
        });
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ExecucaoOrcamentariaController', 'popularUfs():fail');
      let message = 'Falha ao carregar as UF`s.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  onItemSelectReg(item: any) {
    const vm = this;
    this.limpaTabela();
    vm.popularUfs(vm.regioesSelected);
  }

  OnItemDeSelectReg(item: any) {
    const vm = this;
    vm.ufsSelected = vm.ufsSelected.filter(u => u.category !== item.itemName);
    vm.onItemSelectReg(null);
    vm.limpaTabela();
  }

  onSelectAllReg(items: any) {
    const vm = this;
    vm.limpaTabela();
    vm.popularUfs(vm.regioes);
  }

  onDeSelectAllReg(items: any) {
    this.ufsSelected = [];
    this.ufs = [];
    this.limpaTabela();
  }

  exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('ExecucaoOrcamentariaController', 'exportarCsv()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalheRegiao').value);
    params = params.append('detalheUf', vm.myForm.get('detalheUf').value);
    params = params.append('detalheArea', vm.myForm.get('detalheArea').value);
    params = params.append('detalhePrograma', vm.myForm.get('detalhePrograma').value);
    params = params.append('descArea', vm.getLista(vm.areasSelected, 'itemName'));
    params = params.append('descPrograma', vm.getLista(vm.programasSelected, 'itemName'));
    const body = {
      'regiao': vm.appService.getLista(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getLista(vm.ufsSelected, 'id'),
      'areaAplicacao': vm.appService.getLista(vm.areasSelected, 'id'),
      'programa': vm.appService.getLista(vm.programasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/relatorios/exportarexecucaoorcamentariacsv', body, 'text/plain', true, 'execucaoorcamentaria.csv', params);
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
