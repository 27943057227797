import { Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';
// import {} from '@types/googlemaps';

const VERSION = '2.0.0';
declare const $: any;

@Injectable()
export class GeolocationService {

  constructor(private log: LogService) { }

  localmap: any = null;
  infowindow: any = null;
  markers: any[] = [];
  scope: any = null;
  userlocation: any = null;
  path: any = null;

  version(): string {
    return VERSION;
  }

  private _getTitle(location) {
    let title = '';
    if (location.nome) {
      title += '<strong>' + location.nome + '</strong>';
    }
    if (location.data) {
      title += '<br>Data: <strong>' + location.data + '</strong>';
    }
    if (location.perimetro) {
      title += '<br>Perímetro: <strong>' + location.perimetro + ' metros</strong>';
    }
    if (location.precisao) {
      title += '<br>Precisão: <strong>' + location.precisao + ' metros</strong>';
    }
    if (location.distancia) {
      title += '<br>Distância: <strong>' + location.distancia + ' km</strong>';
    }
    return title;
  }

  // private _getId(location) {
  //   let id = '';
  //   if (location.id != null) {
  //     id += location.id;
  //   }
  //   if (location.nome) {
  //     id += location.nome;
  //   }
  //   if (location.nomeobra) {
  //     id += location.nomeobra;
  //   }
  //   if (id == '') {
  //     this.log.warning('get(): id is null for this location');
  //   }
  //   return id;
  // }

  // private _getMarker(id) {
  //   for (var j = 0; j < this.markers.length; j++) {
  //     var marker = this.markers[j];
  //     if (marker.id == id) return marker;
  //   }
  //   return null;
  // }

  // directDistance(lat1, lon1, lat2, lon2) {
  //   var radlat1 = Math.PI * lat1 / 180
  //   var radlat2 = Math.PI * lat2 / 180
  //   var radlon1 = Math.PI * lon1 / 180
  //   var radlon2 = Math.PI * lon2 / 180
  //   var theta = lon1 - lon2
  //   var radtheta = Math.PI * theta / 180
  //   var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  //   dist = Math.acos(dist)
  //   dist = dist * 180 / Math.PI
  //   dist = dist * 60 * 1.1515
  //   dist = dist * 1.609344;
  //   return dist
  // }

  // setUserLocation(location) {
  //   this.userlocation = location;
  // }
  // getUserLocation() {
  //   return this.userlocation;
  // }
  // resetMap() {
  //   //this.clearMarkers();
  //   this.localmap = null;
  //   this.path = null;
  // }
  /*loadMarker (location, icon, show, hideRange, clickCallback) {
    var latitude = location.latitude;
    var longitude = location.longitude;
    if (!latitude || !longitude) {
      this.log.warning('Geolocation.loadMarker(): latitude or longitude are undefined');
      return;
    }
    if (!this.localmap) return null; //throw new Error('Geolocation.loadMarker(): map is undefined');

    var id = this._getId(location);
    if (id == null) {
      this.log.warning('Geolocation.loadMarker(): unable to get location id');
      return;
    };

    var coord = new google.maps.LatLng(latitude, longitude);
    var changed = true;

    var marker = this._getMarker(id);
    //if (icon) icon = new google.maps.MarkerImage(icon, null, new google.maps.Point(0, 0))
    if (!marker) {
      marker = new google.maps.Marker({
        position: coord,
        map: this.localmap,
        animation: google.maps.Animation.DROP,
        icon: icon
      });
      marker.title = this._getTitle(location);

      google.maps.event.addListener(marker, 'click', function (e) {
        this.log.info('userMarker(click)');
        if (this.infowindow != null) this.infowindow.close();
        this.infowindow = new google.maps.InfoWindow({
          content: this.title
          //,: marker.id
        });

        this.infowindow.open(this.localmap, this);

        if (clickCallback) clickCallback(marker);
      });

      if (location.perimetro && !hideRange) {
        var perimeterOptions = {
          strokeColor: '#0051ff',
          strokeOpacity: 0.2,
          strokeWeight: 2,
          fillColor: '#0051ff',
          fillOpacity: 0.1,
          map: this.localmap,
          clickable: false,
          center: coord,
          radius: location.perimetro
        };
        var perimeter = new google.maps.Circle(perimeterOptions);
        marker.perimeter = perimeter;
      }
      if (location.precisao && !hideRange) {
        var perimeterOptions = {
          strokeColor: '#FF0007',
          strokeOpacity: 0.2,
          strokeWeight: 2,
          fillColor: '#FF0007',
          fillOpacity: 0.1,
          map: this.localmap,
          clickable: false,
          center: coord,
          radius: location.precisao
        };
        var perimeter = new google.maps.Circle(perimeterOptions);
        marker.perimeter = perimeter;
      }
    } else {
      marker.title = this._getTitle(location);

      var position = marker.getPosition();
      if (position.lat() == coord.lat() && position.lng() == coord.lng()) {
        changed = false;
      } else {
        marker.setPosition(coord);
      }
    }

    if (location.perimetro && !hideRange) {
      var perimeter2 = marker.perimeter;
      if (perimeter2) {
        perimeter2.setCenter(coord);

        if (perimeter2.getRadius() != location.perimetro) {
          perimeter2.setRadius(location.perimetro);
        }

      } else {
        var perimeterOptions = {
          strokeColor: '#0051ff',
          strokeOpacity: 0.2,
          strokeWeight: 2,
          fillColor: '#0051ff',
          fillOpacity: 0.1,
          map: this.localmap,
          clickable: false,
          center: coord,
          radius: location.perimetro
        };
        perimeter2 = new google.maps.Circle(perimeterOptions);
        marker.perimeter = perimeter2;
      }
    }

    if (location.precisao && !hideRange) {
      var perimeter3 = marker.perimeter;
      if (perimeter3) {
        perimeter3.setCenter(coord);

        if (perimeter3.getRadius() != location.precisao) {
          perimeter3.setRadius(location.precisao);
        }

      } else {
        var perimeterOptions = {
          strokeColor: '#0051ff',
          strokeOpacity: 0.2,
          strokeWeight: 2,
          fillColor: '#0051ff',
          fillOpacity: 0.1,
          map: this.localmap,
          clickable: false,
          center: coord,
          radius: location.precisao
        };
        perimeter3 = new google.maps.Circle(perimeterOptions);
        marker.perimeter = perimeter3;
      }
    }



    marker.id = id;
    location.marker = id;

    if (this.markers.indexOf(marker) < 0) this.markers.push(marker);

    if (show && changed) {
      //localmap.setCenter(coord);
      this.localmap.panTo(coord);
      this.localmap.setZoom(12);
    }
    if (this.infowindow && this.infowindow.marker == marker.id && this.infowindow.getMap() && changed) {
      if (!this.localmap.getBounds().contains(marker.getPosition())) this.localmap.panTo(coord);
      this.showInfo(location);
    }
    return marker;
  }
  loadMarkers(locations, icon, fits, drawline, hideRange, clickCallback) {
    this.log.debug('Geolocation.loadMarkers()');
    if (locations == null) throw new Error('Lista é nula')
    if (!this.localmap) throw new Error('Geolocation.loadMarker(): map is undefined');
    var lines = [];

    for (var i = 0; i < locations.length; i++) {
      var location = locations[i];

      var marker = this.loadMarker(location, icon, false, hideRange, clickCallback);
      if (marker) {
        var coord = marker.getPosition();
        lines.push(coord);
        if (this.markers.indexOf(marker) < 0) this.markers.push(marker);
      }
    }

    if (drawline && lines.length > 0) {
      if (this.path != null) {
        this.path.setMap(null);
        this.path = null;
      }
      this.path = new google.maps.Polyline({
        path: lines,
        geodesic: true,
        strokeColor: '#FDBB0C',
        strokeWeight: 5
      });
      this.path.setMap(this.localmap);
    }
    if (fits) this.fitsMap();
  }
  toggleMarker(id, value) {
    var marker = this._getMarker(id);
    if (!marker) return;
    if (value) {
      if (!marker.getMap()) marker.setMap(this.localmap);
      if (marker.perimeter && !marker.perimeter.getMap()) marker.perimeter.setMap(this.localmap);
    } else {
      marker.setMap(null);
      if (marker.perimeter) marker.perimeter.setMap(null);
    }
  }
  showLocation(location) {
    this.log.debug('Geolocation.showLocation()');
    if (!this.localmap) return

    if (location && location.marker) {

      var marker = this.getMarker(location.marker);
      if (marker) {
        this.localmap.panTo(marker.getPosition());
        this.localmap.setZoom(16);
        this.showInfo(location);
      }
    }
  }
  showInfo(location, complemento = null) {
    this.log.debug('Geolocation.showInfo()');
    if (!this.localmap) return

    if (location && location.marker) {

      var marker = this._getMarker(location.marker);
      var title = this._getTitle(location);
      if (complemento) title += '<br/>' + complemento;
      if (this.infowindow != null) this.infowindow.close();
      this.infowindow = new google.maps.InfoWindow({
        content: title
        //,marker: marker.id
      });
      this.infowindow.open(this.localmap, marker);
      //$rootScope.goHome();
    }
  }
  showMapLocation (latitude, longitude, distance) {
    this.log.debug('Geolocation.showMapLocation()');
    if (!this.localmap) return
    if (distance == null) distance = 12;

    var location = new google.maps.LatLng(latitude, longitude)
    //localmap.setCenter(location);
    this.localmap.panTo(location);
    this.localmap.setZoom(distance);
  }
  getDistanceFromPoints(lat1, lng1, lat2, lng2) {
    return this.directDistance(lat1, lng1, lat2, lng2).toFixed(2);
  }
  getDistance(source, destination) {
    var p1 = source.getPosition();
    var p2 = destination.getPosition();
    if (!p1 || !p2) return null;
    return (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2)
  }
  getMarker(id) {
    this.log.debug('Geolocation.getMarker(' + id + ')');
    return this.getMarker(id);
  }
  fitsMap() {
    this.log.debug('Geolocation.fitsMap()');
    if (!this.localmap) return
    if (this.markers.length == 0) return;

    var bounds = new google.maps.LatLngBounds();
    var boundcounter = 0;
    for (var i = 0; i < this.markers.length; i++) {
      var marker = this.markers[i];
      if (!marker.getMap()) continue;
      var coord = marker.getPosition();
      bounds.extend(coord);
      boundcounter += 1;
    }
    if (boundcounter == 0) return;
    this.localmap.fitBounds(bounds);
    if (this.localmap.getZoom() > 12) this.localmap.setZoom(12);
  }
  fitsBounds(bounds) {
    this.localmap.fitBounds(bounds);
  }
  fitsLocations(locations) {
    this.log.debug('Geolocation.fitsLocations()');
    if (!this.localmap) return
    if (locations.length == 0) return;

    var bounds = new google.maps.LatLngBounds();
    var boundcounter = 0;
    for (var i = 0; i < locations.length; i++) {
      var location = locations[i];
      if (!location.marker) continue;

      var marker = this.getMarker(location.marker);
      if (!marker) continue;
      if (!marker.getMap()) continue;

      var coord = marker.getPosition();
      bounds.extend(coord);
      boundcounter += 1;
    }
    if (boundcounter == 0) return;
    this.localmap.fitBounds(bounds);
    //if (localmap.getZoom() > 12)  localmap.setZoom(12);
  }
  clearMarkers() {
    this.log.debug('Geolocation.clearMarkers()');
    for (var j = 0; j < this.markers.length; j++) {
      var marker = this.markers[j];
      marker.setMap(null);
      if (marker.perimeter) marker.perimeter.setMap(null);
      this.markers.slice(j, 1);
    }
    this.markers.length = 0;
    if (this.path) this.path.setMap(null);
  }
  */
  // loadMap(mapname) {
  //   this.log.debug('Geolocation.loadMap(' + mapname + ')');
  //   var perimeter = null;
  //   if (this.localmap) this.resetMap();

  //   //var center = new google.maps.LatLng(-15.799585, -47.882543); //Brasília

  //   /*this.localmap = new google.maps.Map($(mapname)[0], {
  //     zoom: 4,
  //     center: center,
  //     //mapTypeId: google.maps.MapTypeId.ROADMAP,
  //     disableDefaultUI: true,
  //     panControl: false,
  //     zoomControlOptions: {
  //       //style: google.maps.ZoomControlStyle.LARGE,
  //       //position: google.maps.ControlPosition.LEFT_TOP
  //     },
  //     zoomControl: true,
  //     scrollwheel: true,
  //     streetViewControl: false
  //   });*/

  //   /*google.maps.event.addListener(this.localmap, 'click', function (e) {
  //     this.log.debug('Geolocation.mapclick');
  //     //$rootScope.$broadcast('Geolocation.mapclick', e);
  //   });*/



  //   var userLocation = this.getUserLocation();
  //   if (userLocation) {
  //     var lat = userLocation.latitude;
  //     var lng = userLocation.longitude;
  //     //var location = new google.maps.LatLng(lat, lng);
  //     //localmap.setCenter(location);
  //     this.localmap.panTo(location);
  //     this.localmap.setZoom(12);
  //   }
  //   return this.localmap;
  // }
}
