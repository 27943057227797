import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';

@Component({
  selector: 'app-palavrachavecadastro',
  templateUrl: './palavrachavecadastro.component.html',
  styleUrls: ['./palavrachavecadastro.component.css'],

})
export class PalavraChaveCadastroComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService,  private actroute: ActivatedRoute) {  }

  currentview = CurrentView;
  myForm: FormGroup;
  myFormOld: FormGroup;
  hasCentral = Config.CENTRAL_ID !== '[null]';
  timer = null;
  palavraChave = null;
  palavraChaveID = null;
  bIncluir = true;

  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;

  ngOnInit() {
    this.log.info('PalavraChaveCadastroComponent.init()');
    this.analytics.trackEvent('controller', 'PalavraChaveCadastroController');

    this.currentview.locked = false;
    this.currentview.menu = true;

    this.myForm = this.formBuilder.group({
      palavraChaveID: null,
      palavraChave: null
    });
    this.myFormOld = this.formBuilder.group({
      palavraChaveID: null,
      palavraChave: null
    });

    this.acessoInclusao = this.appService.temAutorizacao('palavrachave', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('palavrachave', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('palavrachave', 'E');

    const palavraChaveID = this.actroute.snapshot.params.id;
    const palavraChave = this.actroute.snapshot.params.palavraChave;

    if (palavraChave) {
      this.bIncluir = false;
      this.myForm.get('palavraChaveID').setValue(palavraChaveID);
      this.myForm.get('palavraChave').setValue(palavraChave);
      this.myFormOld.get('palavraChaveID').setValue(palavraChaveID);
      this.myFormOld.get('palavraChave').setValue(palavraChave);
    }
  }

  retornar() {
    this.appService.openPage('palavrachave');
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      palavraChave: null
    });
  }

  persistir() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveCadastroController', this.bIncluir ? 'incluir()' : 'alterar()');

    const palavraChaveID = this.myForm.get('palavraChaveID') ? this.myForm.get('palavraChaveID').value == null ? '' : this.myForm.get('palavraChaveID').value : '';
    const palavraChave = this.myForm.get('palavraChave') ? this.myForm.get('palavraChave').value == null ? '' : this.myForm.get('palavraChave').value : '';
    const palavraChaveOld = this.myFormOld.get('palavraChave') ? this.myFormOld.get('palavraChave').value == null ? '' : this.myFormOld.get('palavraChave').value : '';

    if ( palavraChave === palavraChaveOld ) {
      if (vm.bIncluir) {
        vm.alertService.showMessage('Atenção', 'Informe a Palavra Chave para incluir.');
      } else {
        vm.alertService.showMessage('Atenção', 'A Palavra Chave não foi alterada.');
      }
      return;
    }

    if ( palavraChave.length < 2 ) {
      vm.alertService.showMessage('Atenção', 'A Palavra Chave deve ter o mínimo de 2 caracteres.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveCadastroController', (vm.bIncluir ? 'incluir' : 'alterar' + '():success') );
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        if (vm.bIncluir) {
          vm.alertService.showMessage('Atenção', 'Inclusão realizada com sucesso.');
          vm.limpar();
        } else {
          vm.alertService.showMessage('Atenção', 'Alteração realizada com sucesso.');
          vm.limpar();
          setTimeout(() => vm.appService.openPage('palavrachave'), 500);
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveCadastroController', (vm.bIncluir ? 'incluir' : 'alterar') + '():fail');
      let message = 'Falha ao ' + (vm.bIncluir ? 'incluir' : 'alterar') + ' a Palavra Chave.';
      message = (status === 404) && err ? err : message;
      if (status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    const params = {
      'id': palavraChaveID,
      'palavraChave': palavraChave
    };
    vm.webservice.post('ws/palavrachave/persistir', JSON.stringify(params), success, fail);
  }
}
